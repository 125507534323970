import { createStore } from 'state-pool'


const store = createStore();

function initializeStore(){
    // Set user state
    store.setState(
        "user",
        {}
    );


    // Set global-search state
    store.setState(
        "global-search",
        {
            property: {
                category: null,
                type: null,
                available_for: null,
                location: {
                    lng: null,
                    lat: null,
                    address: null
                }
            },
            reservation: {
                startDate: null,
                endDate: null,
                guests: {
                    adultsCount: null,
                    childrenCount: null
                }
            }
        }
    );


    // Set page transition state
    store.setState(
        "animatePageTransition",
        false
    );


    // Set showLogInModal
    store.setState(
        "showLogInModal",
        false
    );


    // Set showSignUpModal
    store.setState(
        "showSignUpModal",
        false
    );


    // SignUp state
    store.setState(
        "signUp",
        {
          phone: "",
          iso_code: "",
          dial_code: "",
          full_name: "",
          email: "",
          pin: ""
        }
    );


    // My properties filter
    store.setState(
        "myProperties",
        {}
    );
}

export {store, initializeStore};
