import React, {useEffect} from 'react';
import './ResourcesGroups.scss';
import { Link } from 'react-router-dom';
import { PropertyOverview, BounceBlockLoader, BounceInlineLoader, Carousel } from '.'
import { PropertySliderOverview } from './PropertyOverview';
import { onScrollToBottom, onScrollToTop } from '../utils';
import { useRestoreScrollState, usePageTransition } from '../hooks';
import { store } from '../store';
import i18n from "i18next";
import { NoResultsFound } from './NoResultsFound';


function GenericResourcesGroup(props) {
    const animate = usePageTransition()
    useRestoreScrollState(props.restoreScrollOnMovingBackOnly);
    const [view, setView] = store.useState(
        props.viewKey || "unusedView", { default: props.defaultView || 'grid' }
    );

    let fetchMoreResources = () => {
        if (props.response.canFetchMore) {
            props.response.fetchMore()
        }
    }

    const getView = () => {
        if (view === 'list') {
            return "col-12"
        }
        return "col-6"
    }

    const showListView = (e) => {
        setView("list");
    }

    const showGridView = (e) => {
        setView("grid");
    }

    const beforeRendering = (results) => {
        if (props.beforeRendering){
            return props.beforeRendering(results)
        }
        return results
    }

    if (props.FetchMoreOnScrollToBottom) {
        window.onScrollActions.fetchMoreResources = onScrollToBottom(fetchMoreResources, 200);
    }

    if (props.FetchMoreOnScrollToTop) {
        window.onScrollActions.fetchMoreResources = onScrollToTop(fetchMoreResources, 5);
    }

    useEffect(()=>{
        if(props.FetchMoreOnScrollToTop){
            window.scrollTo(0,document.body.scrollHeight);
        }
    }, [])

    return (
        <div className={`resources-group row m-0 p-0 ${animate()}`}>
            <div className={`group-header col-9 p-0 m-0 ${props.headerClass||'px-1 px-sm-2'}`}>{i18n.t(props.header)}</div>

            <div className="col p-0 m-0 group-actions text-right">
                {props.actions}
                {!props.disableViewSwitch && props.viewKey && props.response.data[0].count !== 0 ?
                    <>
                        {view === "list" ?
                            <span className="view-icon d-sm-none icon icon-grid" onClick={showGridView}></span> :
                            <span className="view-icon d-sm-none icon icon-list" onClick={showListView}></span>
                        }
                    </> : null
                }
            </div>

            {props.FetchMoreOnScrollToTop && props.response.isFetchingMore ?
                <div className="col-12 m-0 p-0" style={{ position: 'relative', bottom: '10px', top: '10px' }}>
                    <BounceBlockLoader />
                </div> :
                null
            }

            <div className="resources row col-12 p-0 m-0">
                {props.response.data[0].count === 0 ?
                    <NoResultsFound message={props.notFoundMsg} image={props.notFoundImg} /> :
                    null
                }
                {beforeRendering(props.response.data).map((resourcesGroup, i) =>
                    <React.Fragment key={i}>
                        {beforeRendering(resourcesGroup.results).map((resource, key) =>
                            <div className={`resource ${getView()}
                            ${props.resourceClass || 'px-1 px-sm-2 my-2'}`} key={key}>
                                {props.children(resource)}
                            </div>
                        )}
                    </React.Fragment>
                )}
            </div>

            <div className="col-12 py-2" />

            {props.FetchMoreOnScrollToBottom && props.response.isFetchingMore ?
                <div className="col-12 m-0 p-0" style={{ position: 'relative', bottom: '25px' }}>
                    <BounceBlockLoader />
                </div> :
                null
            }
        </div>
    )
}


function PropertiesGroup(props) {
    return (
        <GenericResourcesGroup
            viewKey={props.viewKey}
            header={props.header}
            response={props.response}
            restoreScrollOnMovingBackOnly={props.restoreScrollOnMovingBackOnly}
            FetchMoreOnScrollToBottom={props.FetchMoreOnScrollToBottom}>
            {property =>
                <PropertyOverview property={property} />
            }
        </GenericResourcesGroup>
    )
}


function SliderPropertiesGroup(props) {
    const [initialSlide, setInitialSlide] = store.useState("initialSlides_" + props.selection, {default: 0})

    const settings = {
        afterChange: (index) => {
            if(props.selection){
                setInitialSlide(index);
            }
        },
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: initialSlide === 0? 1: initialSlide,
        centerMode: true,
        centerPadding: "50px 0 0 0",
        adaptiveHeight: false,
        swipeToSlide: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    initialSlide
                }
            }
        ]
    }

    let fetchMoreResources = () => {
        if (props.response.canFetchMore) {
            props.response.fetchMore()
        }
    }

    let count = props.response.data[0].count;
    if (count === 0) {
        return null;
    }

    let properties = [];

    props.response.data.forEach(group => {
        properties = [...properties, ...group.results]
    });

    return (
        <div className="resources-group slider-group row m-0 p-0">
            <div className={`group-header col-12 p-0 m-0 px-2 px-sm-4`}>
                <div className="row p-0 m-0">
                    <div className="col-8 p-0 m-0">{props.header}</div>
                    <div className="col p-0 m-0 mr-1 text-right">
                        <Link to={props.viewAllLink} className="view-all-link">
                            {props.viewAllTitle} <span className="icon icon-right-arrow"></span>
                        </Link>
                    </div>
                </div>
            </div>
            <Carousel className="col-12 p-0 m-0 mt-2 slider" {...settings}>
                {properties.map((property, index) =>
                    <div className={`resource m-0 p-0 pl-2 pl-sm-0 ml-sm-4 pr-sm-3`}  key={index}>
                        <PropertySliderOverview property={property} />
                    </div>
                )}

                {props.response.canFetchMore && !props.response.isFetchingMore ?
                    <div>
                        <div className="load-more-button" onClick={fetchMoreResources}>
                            <span className="icon icon-next"></span>
                        </div>
                    </div> :
                    null
                }

                {props.response.isFetchingMore ?
                    <div>
                        <div className="loading-more">
                            <BounceInlineLoader />
                        </div>
                    </div> :
                    null
                }
            </Carousel>
        </div>
    )
}


function TwoRowsPropertiesGroup(props) {
    let properties = props.properties.results;
    if (properties.length === 0) {
        return null;
    }
    return (
        <div className="resources-group row m-0 p-0 d-4 d-sm-4 d-md-6 d-lg-8">
            <div className="group-header col-12 m-0 p-0 px-1 px-sm-2">{props.header}</div>
            {properties.slice(0, 8).map((property, index) =>
                <div className={`resource col-6 col-md-4 col-lg-3 m-0 p-0 my-2 px-1 px-sm-2  d-none-${index + 1}`} key={index + 1}>
                    <PropertyOverview property={property} />
                </div>
            )}

            {props.footer ?
                <div className="group-footer col-12 m-0 p-0 px-1 px-sm-2 mt-2">
                    <div className="row p-0 m-0">
                        <Link to={props.footerLink} className="show-all-btn btn-ripple text-decoration-none col-12 text-center d-md-none py-2 bw-1">
                            {props.footer}
                        </Link>

                        <Link to={props.footerLink} className="show-all-btn-md text-decoration-none d-none d-md-inline bw-1 bw-md-0">
                            {props.footer}
                        </Link>
                    </div>
                </div> :
                null
            }
        </div>
    )
}


export { GenericResourcesGroup, PropertiesGroup, SliderPropertiesGroup, TwoRowsPropertiesGroup };
