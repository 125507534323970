import React from 'react';
import { parse } from 'query-string';
import { queryCache } from 'react-query';
import { useHistory } from 'react-router';
import { Redirect } from 'react-router-dom';
import { store } from '../store';
import { BASE_API_URL } from '../';
import {
    DataFetcher, BouncePageLoader, renderPageError, NotFoundError
} from './';


function SavedSearchDetails(props) {
    const savedSearch = props.savedSearch;
    const history = useHistory()
    const parsed = parse(history.location.search);

    const createURLParameters = (params) => {
        const parametersStr = []
        for (let key in params) {
            if (params.hasOwnProperty(key)) {
                parametersStr.push(`${key}=${params[key]}`)
            }
        }
        return parametersStr.join("&")
    }

    let paramsObj = savedSearch.parameters;
    paramsObj.address = savedSearch.label === "flexible" ?
                        "": savedSearch.label;  // Restore the label incase user
                        // wants to save a tweaked version of this search
    
    if(parsed.from && parsed.to){
        paramsObj = {
            ...paramsObj,
            "post_date__gt": parsed.from,
            "post_date__lt": parsed.to
        }
    }

    const path = `/global-search/?${createURLParameters(paramsObj)}`

    return (
        <Redirect to={path} />
    );
}

function SavedSearchDetailsFetcher(props) {
    const [user,] = store.useState("user");

    const headers = {
        Authorization: `Token ${user.auth_token}`,
        "Content-Type": "application/json",
    }

    let fetchReservation = () => {
        return fetch(`${BASE_API_URL}/saved-searches/${props.id}/`, { headers: headers })
            .then(res => res.json().then(data => ({ statusCode: res.status, data })))
    }

    return (
        <DataFetcher action={fetchReservation} selection={`saved-search/${props.id}`}
            placeholder={<BouncePageLoader />} onError={renderPageError}>{response => {
                if (response.data.statusCode === 404) {
                    return <NotFoundError />
                }
                return <SavedSearchDetails savedSearch={response.data.data} />
            }}</DataFetcher>
    )
}


export { SavedSearchDetailsFetcher as SavedSearchDetails }