import React, {useRef, useEffect, useState} from 'react';
import './Filters.scss';
import { parse } from 'query-string';
import { store } from '../store';
import {
    PaginatedDataFetcher, BouncePageLoader, renderPageError,
    PropertyOverview, GenericResourcesGroup, LogInToViewSaved,
    ReservationOverview, BounceInlineLoader, DataFetcher,
    renderInlineError, InfoModal
} from '.';
import { Button, Spinner } from 'react-bootstrap';
import { NotificationOverview } from './NotificationOverview';
import {BASE_API_URL, BASE_URL, BASE_WS_URL} from '../';
import {
    getPropertyRoute, capitalizeFirst,
    generateLinks, buildMessageFromResponse
} from '../utils';
import { useUserLocation, usePageTransition } from '../hooks';
import { ConversationOverview } from './ConversationOverview';
import { Chat } from './Chat';
import { queryCache } from 'react-query';
import { ReservationRequestOverview } from './ReservationRequestOverview';
import { useHistory } from 'react-router';
import { PublishRequestOverview } from './PublishRequestOverview';
import { TourOverview } from './TourOverview';
import { TourRequestOverview } from './TourRequestOverview';
import chatImage from '../images/undraw_chatting.svg';
import notificationImage from '../images/undraw_my_notifications.svg';
import i18n from "i18next";
import { PropertyLinkOverview } from './PropertyOverview';
import { VerificationRequestOverview } from './VerificationRequestOverview';
import { SavedSearchOverview } from './SavedSearchOverview';
import { toast } from 'react-toastify';
import { Transaction } from './PaymentSettings';
import {Helmet} from "react-helmet";


function GenericFilter(props) {
    let endpoint = props.endpoint.replace(/[\n ]/g, "");

    const [user,] = store.useState("user");

    const headers = {
        "Content-Type": "application/json",
    }

    if (user.isLoggedIn) {
        headers["Authorization"] = `Token ${user.auth_token}`
    }

    const reqHeaders = {
        headers: headers
    }

    let fetchResources = (key, cursor = `${BASE_API_URL}/${endpoint}`) => {
        return fetch(cursor, { ...reqHeaders, ...props.fetchConfig, }).then(res => res.json())
    }

    return (
        <PaginatedDataFetcher
            action={fetchResources}
            placeholder={props.placeholder}
            error={props.error}
            onError={props.onError}
            selection={props.selection}>
            {(response) => {
                return props.children(response);
            }}
        </PaginatedDataFetcher>
    );
}

function EndpointPropertiesFilter(props) {
    return (
        <GenericFilter placeholder={props.loader||<BouncePageLoader/>} onError={renderPageError} {...props}>
            {(response) => {
                let header = "";
                if (typeof(props.header) == "function"){
                    header = props.header(response.data[0]);
                }
                else{
                    header = props.header;
                }
                return (
                    <div className="p-0 m-0 px-1 px-sm-3 mt-2 mt-md-3">
                        <GenericResourcesGroup
                            defaultView={props.defaultView}
                            viewKey={props.viewKey}
                            disableViewSwitch={props.disableViewSwitch}
                            header={header}
                            response={response}
                            actions={props.actions}
                            notFoundMsg={props.notFoundMsg}
                            notFoundImg={props.notFoundImg}
                            restoreScrollOnMovingBackOnly={props.restoreScrollOnMovingBackOnly}
                            FetchMoreOnScrollToBottom>
                            {property =>
                                <PropertyOverview property={property} t={props.t}/>
                            }
                        </GenericResourcesGroup>
                    </div>
                );
            }}
        </GenericFilter>
    );
}

const PROPERTIES_QUERY_PARAM = `
query={
    id,
    available_for,
    pricing {
        amount,
        currency,
        rate_unit
    },
    type,
    pictures{
        src,
        is_main
    },
    location,
    total_rating_score,
    my_rating,
    is_my_favourite,
    parent_property,
    parent_property_type
}&format=json`


const RESERVATIONS_QUERY_PARAM = `
query={
    id,
    properties{
        location,
        pictures{
            src,
            is_main
        }
    },
    check_in_date,
    check_out_date,
    cost,
    currency,
    status
}&format=json`


function SearchProperties(props) {
    const history = useHistory();
    const parsed = parse(history.location.search);

    let endpoint = `properties/?${PROPERTIES_QUERY_PARAM}`
    if(parsed.q){
        endpoint = `properties/?${PROPERTIES_QUERY_PARAM}&search=${parsed.q}`;
    }
    if(parsed.lng && parsed.lat) {
        endpoint = `
        properties/?${PROPERTIES_QUERY_PARAM}
        &longitude=${parsed.lng}
        &latitude=${parsed.lat}
        &radius_to_scan=${parsed.radius_to_scan||5000}
        `;
    }
    let selection = history.location.pathname + history.location.search;
    let header = (properties) => `${props.t("Search results")}(${properties.count})`;

    let viewKey = "searchPropertiesView";

    return <EndpointPropertiesFilter restoreScrollOnMovingBackOnly viewKey={viewKey} selection={selection} endpoint={endpoint} header={header}/>;
}

function SaveSearchAction(props) {
    const [user, ] = store.useState("user");
    const history = useHistory();
    const parsed = parse(history.location.search);
    const [modalShow, setModalShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const modalHeader = "Save This Search"

    const saveSearch = (e) => {
        setIsLoading(true);
        const parameters = {}
        for(let key in parsed){
            if(key && parsed[key] !== undefined && parsed[key] !== null){
                parameters[key] = parsed[key]
            }
        }

        const formdata = {
            "label": parsed.address||"flexible",
            "parameters": parameters
        };

        const URL = `${BASE_API_URL}/saved-searches/`
        const headers = {
            'Authorization': `Token ${user.auth_token}`,
            'Content-Type': 'application/json'
        };

        fetch(URL, { method: 'POST', body: JSON.stringify(formdata), headers: headers })
            .then(res => res.json().then(data => ({ statusCode: res.status, data })))
            .then(res => {
                if (res.statusCode === 201) {
                    queryCache.invalidateQueries(`saved-searches`);
                    history.push("/saved?flag=searches")
                }
                else if (res.statusCode === 400) {
                    toast.error(buildMessageFromResponse(res.data))
                }
            })
            .catch(error => {
                toast.error("No network connection, please try again!.")
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <>
            <span className="icon icon-bookmark-plus" onClick={e => setModalShow(true)} />
            <InfoModal header={modalHeader} modalShow={modalShow} setModalShow={setModalShow}>
                <div className="save-search col-12 p-0 m-0 px-3 mt-2 floating">
                    <div className="header mt-3 mb-4">
                        Saving a search allows you to get notifications when a new property
                        matching your search is created.
                    </div>
                    <Button className="save-btn col-12 mt-3" variant="primary" disabled={isLoading} onClick={saveSearch}>
                        {isLoading ? <Spinner animation="border" size="sm" /> : "Save Search"}
                    </Button>
                </div>
            </InfoModal>
        </>
    );
}

function GlobalSearch(props) {
    const history = useHistory();
    const parsed = parse(history.location.search);

    const endpoint = `
    properties/?${PROPERTIES_QUERY_PARAM}
    ${parsed.longitude & parsed.latitude ?
        `
        &longitude=${parsed.longitude}
        &latitude=${parsed.latitude}
        &radius_to_scan=${parsed.radius_to_scan||5000}`: ""
        }
    ${parsed.type? `&type=${parsed.type}`: ""}
    ${parsed.available_for?
        `&available_for=${parsed.available_for}`: ""
        }
    ${parsed.pricing__amount__gt?
        `&pricing__amount__gt=${parsed.pricing__amount__gt}`: ""
        }
    ${parsed.pricing__amount__lt?
        `&pricing__amount__lt=${parsed.pricing__amount__lt}`: ""
        }
    ${parsed.pricing__currency?
        `&pricing__currency=${parsed.pricing__currency}`: ""
        }
    ${parsed.amenities__subset?
        `&amenities__subset=${parsed.amenities__subset}`: ""
        }`;

    const selection = endpoint;
    const header = (properties) => `${props.t("Search results")}(${properties.count})`;

    const viewKey = "GlobalSearchView";
    return <EndpointPropertiesFilter actions={<SaveSearchAction/>} restoreScrollOnMovingBackOnly viewKey={viewKey} selection={selection} endpoint={endpoint} header={header}/>;
}

function FilterPropertiesByCategory(props) {
    let endpoint = `properties/?${PROPERTIES_QUERY_PARAM}&available_for=${props.availableFor}`;
    let viewKey = `propertiesAvailableFor${capitalizeFirst(props.availableFor)}ViewKey`;
    return <EndpointPropertiesFilter viewKey={viewKey} endpoint={endpoint} {...props}/>;
}

function UserFavProperties(props) {
    const [user,] = store.useState("user");
    let selection = `my-fav-properties`;
    let header = (properties) => `${i18n.t("Saved properties")} (${properties.count})..`;
    let endpoint = `my-fav-properties/?${PROPERTIES_QUERY_PARAM}`;
    let viewKey = "myFavPropertiesView";
    const notFoundMsg = "You do not have any saved property at the moment."

    if(!user.isLoggedIn) {
        const message = "Login to your account to be able to save your favourite properties"
        return <LogInToViewSaved message={message}/>
    }
    return <EndpointPropertiesFilter notFoundMsg={notFoundMsg} viewKey={viewKey} selection={selection} endpoint={endpoint} header={header}/>;
}

function SavedSearches(props) {
    const selection = `saved-searches`;
    const header = `${i18n.t("Saved searches")}`;
    const endpoint = `saved-searches/`;
    const viewKey = "savedSearches";
    const notFoundMsg = `You haven't saved any search at the moment.`

    return (
        <>
            <GenericFilter placeholder={<BouncePageLoader />} onError={renderPageError}
                           selection={selection} endpoint={endpoint}>
                {(response) => {
                    return (
                        <div className="p-0 m-0 px-1 px-sm-3 mt-2 mt-md-3">
                            <GenericResourcesGroup
                                defaultView="list"
                                viewKey={viewKey}
                                header={header + ` (${response.data[0].count})..`}
                                response={response}
                                notFoundMsg={notFoundMsg}
                                disableViewSwitch
                                resourceclassName='col-sm-12 col-md-6 col-lg-6 px-1 px-sm-2 my-2'
                                restoreScrollOnMovingBackOnly
                                FetchMoreOnScrollToBottom>
                                {savedSearch =>
                                    <SavedSearchOverview savedSearch={savedSearch} />
                                }
                            </GenericResourcesGroup>
                        </div>
                    );
                }}
            </GenericFilter>
        </>
    );
}

function SavedItems(props) {
    const [animatePageTransition,] = store.useState("animatePageTransition");
    const [user,] = store.useState("user");
    const history = useHistory();
    const parsed = parse(history.location.search);
    const flag = parsed.flag || "properties";

    const setFlag = (newFlag) => {
        history.replace(`/saved?flag=${newFlag}`)
    }

    const flags = [
        {name: <><span className="tab-icon icon icon-house"/> Properties</>, value: "properties"},
        {name: <><span className="tab-icon icon icon-search"/> Searches</>, value: "searches"}
    ]

    if(!user.isLoggedIn) {
        const message = "Login to your account to be able to view your saved items."
        return <LogInToViewSaved message={message}/>
    }

    return (
        <>
            <span className={animatePageTransition? `animate-page`: ''}>
                <Tabs value={flag} options={flags} onChange={setFlag} />
            </span>
            {flag === "properties" ?
                <UserFavProperties /> : null
            }
            {flag === "searches" ?
                <SavedSearches /> : null
            }
        </>
    );
}

function UserNearByProperties(props) {
    const { location } = useUserLocation();
    if(location === null){
        return null;
    }

    const endpoint = `
    properties/?${PROPERTIES_QUERY_PARAM}
    &longitude=${location.coords.longitude}
    &latitude=${location.coords.latitude}
    &radius_to_scan=5000
    `
    let selection = `userNearbyProperties`;
    let header = (properties) => `${i18n.t("Nearby Properties")} (${properties.count})..`;
    let viewKey = "myNearByPropertiesView";

    return <EndpointPropertiesFilter restoreScrollOnMovingBackOnly viewKey={viewKey} selection={selection} endpoint={endpoint} header={header}/>;
}

function NearByProperties(props) {
    const parsed = parse(props.location.search);
    const lng = parsed.lng;
    const lat = parsed.lat;
    const radius = parsed.radius_to_scan || 5000;  // Use 5000 meters as a default
    const endpoint = `
    properties/?${PROPERTIES_QUERY_PARAM}
    &longitude=${lng}
    &latitude=${lat}
    &radius_to_scan=${radius}
    `
    const selection = `/nearby-properties/?lng=${lng}&lat=${lat}&radius_to_scan=${radius}`
    let header = (properties) => `${i18n.t("Nearby Properties")} (${properties.count})..`;
    let viewKey = "nearByPropertiesView";

    return <EndpointPropertiesFilter restoreScrollOnMovingBackOnly viewKey={viewKey} selection={selection} endpoint={endpoint} header={header}/>;
}

function UserProperties(props) {
    const [user, ] = store.useState("user");
    let selection = `myProperties.${getPropertyRoute(props.type)}`;
    let endpoint = `${getPropertyRoute(props.type)}/?${PROPERTIES_QUERY_PARAM}&owner=${user.id}`;
    let viewKey = "userPropertiesView";
    const notFoundMsg = "You do not have any property at the moment.";

    return <EndpointPropertiesFilter restoreScrollOnMovingBackOnly notFoundMsg={notFoundMsg} defaultView="grid" viewKey={viewKey} disableViewSwitch selection={selection} endpoint={endpoint}/>;
}


function Tabs(props){
    const isChecked = (option) => {
        return option === props.value;
    }

    const onChange = (e) => {
        const value = e.target.value;
        props.onChange(value);
    }
    return (
        <div className="tab-filter row m-0 p-0 mt-2 px-3">
            <div className="card-select row p-0 m-0 w-100 mb-2">

                {props.options.map((option, i) => (
                    <div className="col p-0 m-0 text-center">
                        <label className="card-input-label">
                            <input type="radio" name="status" value={option.value} onChange={onChange}
                                   checked={isChecked(option.value)} className="card-input-element" />
                            <div className="card-input">
                                {option.name}
                            </div>
                        </label>
                    </div>
                ))}

            </div>
        </div>
    );
}


function UserReservations(props) {
    const history = useHistory();
    const parsed = parse(history.location.search);
    const status = parsed.status || "pending";

    const setStatus = (newStatus) => {
        history.replace(`/reservations/?status=${newStatus}`)
    }

    let selection = `my-reservations/${status}`;
    let header = `${capitalizeFirst(status)} reservations`;
    let endpoint = `reservations/?${RESERVATIONS_QUERY_PARAM}&status=${status}`;
    let viewKey = "userReservationsView";

    let statuses = [
        {name: "Pending", value: "pending"},
        {name: "Paid", value: "paid"},
        {name: "Canceled", value: "canceled"}
    ]
    const notFoundMsg = `You do not have any ${status} reservation.`

    return (
        <>
            <Tabs value={status} options={statuses} onChange={setStatus} />
            <GenericFilter placeholder={<BouncePageLoader />} onError={renderPageError}
                           selection={selection} endpoint={endpoint}>
                {(response) => {
                    return (
                        <div className="p-0 m-0 px-1 px-sm-3 mt-2 mt-md-3">
                            <GenericResourcesGroup
                                defaultView="list"
                                viewKey={viewKey}
                                header={header}
                                notFoundMsg={notFoundMsg}
                                response={response}
                                disableViewSwitch
                                resourceclassName='col-sm-12 col-md-6 col-lg-6 px-1 px-sm-2 my-2'
                                restoreScrollOnMovingBackOnly
                                FetchMoreOnScrollToBottom>
                                {reservation =>
                                    <ReservationOverview reservation={reservation} />
                                }
                            </GenericResourcesGroup>
                        </div>
                    );
                }}
            </GenericFilter>
        </>
    );
}


function UserTours(props) {
    const history = useHistory();
    const parsed = parse(history.location.search);
    const status = parsed.status || "pending";

    const setStatus = (newStatus) => {
        history.replace(`/tours/?status=${newStatus}`)
    }

    let selection = `my-tours/${status}`;
    let header = `${capitalizeFirst(status)} tours`;
    let endpoint = `tours/?status=${status}`;
    let viewKey = "userToursView";

    let statuses = [
        {name: "Pending", value: "pending"},
        {name: "Paid", value: "paid"},
        {name: "Canceled", value: "canceled"}
    ]

    const notFoundMsg = `You do not have any ${status} tour.`

    return (
        <>
            <Tabs value={status} options={statuses} onChange={setStatus} />
            <GenericFilter placeholder={<BouncePageLoader />} onError={renderPageError}
                           selection={selection} endpoint={endpoint}>
                {(response) => {
                    return (
                        <div className="p-0 m-0 px-1 px-sm-3 mt-2 mt-md-3">
                            <GenericResourcesGroup
                                defaultView="list"
                                viewKey={viewKey}
                                header={header}
                                notFoundMsg={notFoundMsg}
                                response={response}
                                disableViewSwitch
                                resourceclassName='col-sm-12 col-md-6 col-lg-6 px-1 px-sm-2 my-2'
                                restoreScrollOnMovingBackOnly
                                FetchMoreOnScrollToBottom>
                                {tour =>
                                    <TourOverview tour={tour} />
                                }
                            </GenericResourcesGroup>
                        </div>
                    );
                }}
            </GenericFilter>
        </>
    );
}


function PublishRequests(props) {
    const history = useHistory();
    const parsed = parse(history.location.search);
    const status = parsed.status || "pending";

    const setStatus = (newStatus) => {
        history.replace(`/publish-requests/?status=${newStatus}`)
    }

    let selection = `publish-requests/${status}`;
    let header = `${capitalizeFirst(status)} publish requests`;
    let endpoint = `publish-requests/?status=${status}`;
    let viewKey = "publishRequestsView";

    let statuses = [
        {name: "Pending", value: "pending"},
        {name: "Accepted", value: "accepted"},
        {name: "Declined", value: "declined"}
    ]

    const notFoundMsg = `There're no any ${status} publish requests.`

    return (
        <>
            <Tabs value={status} options={statuses} onChange={setStatus} />
            <GenericFilter placeholder={<BouncePageLoader />} onError={renderPageError}
                           selection={selection} endpoint={endpoint}>
                {(response) => {
                    return (
                        <div className="p-0 m-0 px-1 px-sm-3 mt-2 mt-md-3">
                            <GenericResourcesGroup
                                defaultView="list"
                                viewKey={viewKey}
                                header={header}
                                response={response}
                                notFoundMsg={notFoundMsg}
                                disableViewSwitch
                                resourceclassName='col-sm-12 col-md-6 col-lg-6 px-1 px-sm-2 my-2'
                                restoreScrollOnMovingBackOnly
                                FetchMoreOnScrollToBottom>
                                {publishRequest =>
                                    <PublishRequestOverview publishRequest={publishRequest} />
                                }
                            </GenericResourcesGroup>
                        </div>
                    );
                }}
            </GenericFilter>
        </>
    );
}


function ReservationRequests(props) {
    const history = useHistory();
    const parsed = parse(history.location.search);
    const status = parsed.status || "new";

    const setStatus = (newStatus) => {
        history.replace(`/reservation-requests/?status=${newStatus}`)
    }
    let selection = `reservation-requests/${status}`;
    let header = `Guest's ${status} reservations`;

    let filter = (value) => {
        if(value === "new"){
            return `status=pending&is_confirmed=false`
        }
        else if(value === "pending"){
            return `status=pending&is_confirmed=true`
        }
        else if(value === "paid"){
            return `status=paid`
        }
        else if(value === "canceled"){
            return `status=canceled`
        }
    }

    let endpoint = `reservation-requests/?${RESERVATIONS_QUERY_PARAM}&${filter(status)}`;
    let viewKey = "guestReservationsView";

    let statuses = [
        {name: "New", value: "new"},
        {name: "Pending", value: "pending"},
        {name: "Paid", value: "paid"},
        {name: "Canceled", value: "canceled"}
    ]

    const notFoundMsg = `You do not have any ${status} reservation.`

    return (
        <>
            <Tabs value={status} options={statuses} onChange={setStatus} />
            <GenericFilter placeholder={<BouncePageLoader />} onError={renderPageError}
                           selection={selection} endpoint={endpoint}>
                {(response) => {
                    return (
                        <div className="p-0 m-0 px-1 px-sm-3 mt-2 mt-md-3">
                            <GenericResourcesGroup
                                defaultView="list"
                                viewKey={viewKey}
                                header={header}
                                response={response}
                                notFoundMsg={notFoundMsg}
                                disableViewSwitch
                                resourceclassName='col-sm-12 col-md-6 col-lg-6 px-1 px-sm-2 my-2'
                                restoreScrollOnMovingBackOnly
                                FetchMoreOnScrollToBottom>
                                {reservation =>
                                    <ReservationRequestOverview reservation={reservation} />
                                }
                            </GenericResourcesGroup>
                        </div>
                    );
                }}
            </GenericFilter>
        </>
    );
}


function TourRequests(props) {
    const history = useHistory();
    const parsed = parse(history.location.search);
    const status = parsed.status || "new";

    const setStatus = (newStatus) => {
        history.replace(`/tour-requests/?status=${newStatus}`)
    }
    let selection = `tour-requests/${status}`;
    let header = `${capitalizeFirst(status)} tours requests`;

    let filter = (value) => {
        if(value === "new"){
            return `status=pending&is_confirmed=false`
        }
        else if(value === "pending"){
            return `status=pending&is_confirmed=true`
        }
        else if(value === "paid"){
            return `status=paid`
        }
        else if(value === "canceled"){
            return `status=canceled`
        }
    }

    let endpoint = `tour-requests/?${filter(status)}`;
    let viewKey = "tourRequestsView";

    let statuses = [
        {name: "New", value: "new"},
        {name: "Pending", value: "pending"},
        {name: "Paid", value: "paid"},
        {name: "Canceled", value: "canceled"}
    ]
    const notFoundMsg = `You do not have any ${status} tour.`

    return (
        <>
            <Tabs value={status} options={statuses} onChange={setStatus} />
            <GenericFilter placeholder={<BouncePageLoader />} onError={renderPageError}
                           selection={selection} endpoint={endpoint}>
                {(response) => {
                    return (
                        <div className="p-0 m-0 px-1 px-sm-3 mt-2 mt-md-3">
                            <GenericResourcesGroup
                                defaultView="list"
                                viewKey={viewKey}
                                header={header}
                                response={response}
                                notFoundMsg={notFoundMsg}
                                disableViewSwitch
                                resourceclassName='col-sm-12 col-md-6 col-lg-6 px-1 px-sm-2 my-2'
                                restoreScrollOnMovingBackOnly
                                FetchMoreOnScrollToBottom>
                                {tour =>
                                    <TourRequestOverview tour={tour} />
                                }
                            </GenericResourcesGroup>
                        </div>
                    );
                }}
            </GenericFilter>
        </>
    );
}


function VerificationRequests(props) {
    const history = useHistory();
    const parsed = parse(history.location.search);
    const status = parsed.status || "pending";

    const setStatus = (newStatus) => {
        history.replace(`/user-verifications/?status=${newStatus}`)
    }
    const selection = `user-verifications/${status}`;
    const header = `${capitalizeFirst(status)} verification requests`;

    const filter = (value) => {
        if(value === "pending"){
            return `status=pending`
        }
        else if(value === "accepted"){
            return `status=accepted`
        }
        else if(value === "declined"){
            return `status=declined`
        }
    }

    let endpoint = `user-verifications/?${filter(status)}`;
    let viewKey = "verificationRequestsView";

    let statuses = [
        {name: "Pending", value: "pending"},
        {name: "Accepted", value: "accepted"},
        {name: "Declined", value: "declined"}
    ]
    const notFoundMsg = `There's no any ${status} verification.`

    return (
        <>
            <Tabs value={status} options={statuses} onChange={setStatus} />
            <GenericFilter placeholder={<BouncePageLoader />} onError={renderPageError}
                           selection={selection} endpoint={endpoint}>
                {(response) => {
                    return (
                        <div className="p-0 m-0 px-1 px-sm-3 mt-2 mt-md-3">
                            <GenericResourcesGroup
                                defaultView="list"
                                viewKey={viewKey}
                                header={header}
                                response={response}
                                notFoundMsg={notFoundMsg}
                                disableViewSwitch
                                resourceclassName='col-sm-12 col-md-6 col-lg-6 px-1 px-sm-2 my-2'
                                restoreScrollOnMovingBackOnly
                                FetchMoreOnScrollToBottom>
                                {verificationRequest =>
                                    <VerificationRequestOverview verificationRequest={verificationRequest} />
                                }
                            </GenericResourcesGroup>
                        </div>
                    );
                }}
            </GenericFilter>
        </>
    );
}


function ShowRentProperties(props){
    let header = "Properties available for rent";
    let selection = "explore/rent-properties";
    let endpoint = `properties/?${PROPERTIES_QUERY_PARAM}&available_for=rent`;
    let viewKey = "explore/rent-propertiesView";
    let property_url = `${BASE_URL}/${selection}`;
    return <>
        <Helmet>
            <title>{"Seto - " + header}</title>
            <link rel="canonical" href={property_url}/>
            <meta name="description" content={"Seto - " + header}/>
            <meta property="og:url" content={property_url}/>
            <meta property="og:site_name" content="Seto"/>
            <meta property="og:description" content={header}/>
        </Helmet>
        <EndpointPropertiesFilter restoreScrollOnMovingBackOnly viewKey={viewKey} selection={selection} endpoint={endpoint} header={header}/>
    </>;
}

function ShowBuyProperties(props){
    let header = "Properties available for sale";
    let selection = "explore/buy-properties";
    let endpoint = `properties/?${PROPERTIES_QUERY_PARAM}&available_for=sale`;
    let viewKey = "explore/buy-propertiesView";
    let property_url = `${BASE_URL}/${selection}`;
    return <>
        <Helmet>
            <title>{"Seto - " + header}</title>
            <link rel="canonical" href={property_url}/>
            <meta name="description" content={"Seto - " + header}/>
            <meta property="og:url" content={property_url}/>
            <meta property="og:site_name" content="Seto"/>
            <meta property="og:description" content={header}/>
        </Helmet>
        <EndpointPropertiesFilter restoreScrollOnMovingBackOnly viewKey={viewKey} selection={selection} endpoint={endpoint} header={header} t={props.t}/>;
    </>
}

function UserTransactions(props) {
    let selection = `transactions`;
    let header = `Transaction History`;
    let endpoint = `transactions/`;
    let viewKey = "userTransactionsView";

    const notFoundMsg = `You do not have any transaction.`

    return (
        <>
            <GenericFilter placeholder={<BouncePageLoader />} onError={renderPageError}
                           selection={selection} endpoint={endpoint}>
                {(response) => {
                    return (
                        <div className="p-0 m-0 px-1 px-sm-3 mt-2 mt-md-3">
                            <GenericResourcesGroup
                                defaultView="list"
                                viewKey={viewKey}
                                header={header}
                                notFoundMsg={notFoundMsg}
                                response={response}
                                disableViewSwitch
                                resourceclassName='col-sm-12 col-md-6 col-lg-6 px-1 px-sm-2 my-2'
                                restoreScrollOnMovingBackOnly
                                FetchMoreOnScrollToBottom>
                                {transaction =>
                                    <Transaction transaction={transaction} />
                                }
                            </GenericResourcesGroup>
                        </div>
                    );
                }}
            </GenericFilter>
        </>
    );
}

function NotificationsFilter(props) {
    const animate = usePageTransition();
    const [user,] = store.useState("user");
    let header = i18n.t("Notifications");
    let selection = "user-notifications";
    let endpoint = `notifications/?format=json`;

    if(!user.isLoggedIn) {
        const message = "Login to your account to be able to view your notifications."
        return <LogInToViewSaved message={message} image={notificationImage}/>
    }

    const notFoundMsg = `You do not have any notification at the moment.`

    return (
        <GenericFilter
            selection={selection}
            endpoint={endpoint}
            placeholder={<BouncePageLoader/>}
            onError={renderPageError}>
            {(response) => {
                return (
                    <div className={`p-0 m-0 mt-2 mt-md-3 notifications ${animate()}`}>
                        <GenericResourcesGroup
                            defaultView="list"
                            header={header}
                            response={response}
                            notFoundMsg={notFoundMsg}
                            headerclassName='px-2 px-sm-3'
                            disableViewSwitch
                            resourceclassName='col-md-12 col-lg-12 px-0 px-sm-0 my-0'
                            restoreScrollOnMovingBackOnly={props.restoreScrollOnMovingBackOnly}
                            FetchMoreOnScrollToBottom>
                            {notification =>
                                <NotificationOverview notification={notification} />
                            }
                        </GenericResourcesGroup>
                    </div>
                );
            }}
        </GenericFilter>
    );
}

function ConversationsFilter(props) {
    const animate = usePageTransition();
    const [user,] = store.useState("user");
    let header = i18n.t("Chats");
    let selection = "user-conversations";
    let endpoint = `conversations/?format=json`;

    if(!user.isLoggedIn) {
        const message = i18n.t("Login to your account to be able to view your chats.")
        return <LogInToViewSaved message={message} image={chatImage}/>
    }

    const notFoundMsg = `You do not have any conversation at the moment`

    return (
        <GenericFilter
            selection={selection}
            endpoint={endpoint}
            placeholder={<BouncePageLoader/>}
            onError={renderPageError}>
            {(response) => {
                return (
                    <div className={`p-0 m-0 mt-2 mt-md-3 conversations ${animate()}`}>
                        <GenericResourcesGroup
                            defaultView="list"
                            header={header}
                            response={response}
                            notFoundMsg={notFoundMsg}
                            headerclassName='px-2 px-sm-3'
                            disableViewSwitch
                            resourceclassName='col-sm-12 col-md-12 col-lg-12 px-0 px-sm-0 my-0'
                            restoreScrollOnMovingBackOnly={props.restoreScrollOnMovingBackOnly}
                            FetchMoreOnScrollToBottom>
                            {conversation =>
                                <ConversationOverview conversation={conversation} />
                            }
                        </GenericResourcesGroup>
                    </div>
                );
            }}
        </GenericFilter>
    );
}

function MessagePropertyLinkOverview(props) {
    const [user,] = store.useState("user");
    const endPoint = `${BASE_API_URL}/properties/${props.id}/?${PROPERTIES_QUERY_PARAM}`

    const headers = {
        Authorization: `Token ${user.auth_token}`,
        "Content-Type": "application/json",
    }

    let fetchProperty = () => {
        return fetch(endPoint, {headers: headers})
            .then(res => res.json().then(data => ({statusCode: res.status, data})))
    }

    return (
        <DataFetcher action={fetchProperty} selection={`property-overview/${props.id}`}
                     placeholder={<BounceInlineLoader />} onError={renderInlineError}>{response => {
            if (response.data.statusCode === 404) {
                return null
            }
            return (
                <div className="link-preview">
                    <PropertyLinkOverview property={response.data.data} />
                </div>
            );
        }}</DataFetcher>
    )
}

function ChatFilter(props) {
    const history = useHistory();
    const [user,] = store.useState("user");
    const messageInput = useRef(null);
    const [message, setMessage] = useState("");
    let header = i18n.t("Messages");
    let selection = `conversation/${props.id}`
    let endpoint = `conversation-messages/${props.id}/?format=json`;
    const notFoundMsg = `Start conversation`

    const beforeRendering = (messages) => {
        return messages.slice().reverse()
    }

    const ws = useRef(null);

    useEffect(() => {
        ws.current = new WebSocket(
            `${BASE_WS_URL}/ws/conversations?token=${user.auth_token}&receiver=${props.id}`)
        ws.current.onopen = () => {
            // Mark all received messages as read
            const data = {
                action: "mark_all_messages_as_read"
            }
            ws.current.send(JSON.stringify(data));
            // End of marking all received messages as read
        }
        ws.current.onclose = () => console.log("ws closed");

        const wsCurrent = ws.current;

        return () => {
            wsCurrent.close();
        };
    }, []);

    useEffect(() => {
        if (!ws.current) return;

        ws.current.onmessage = e => {
            const message = JSON.parse(e.data);
            if (message.action === "message_exchanged"){
                const data = {
                    action: "mark_all_messages_as_read"
                }
                ws.current.send(JSON.stringify(data));

                queryCache.invalidateQueries(`conversation/${props.id}`);
                queryCache.invalidateQueries('user-conversations');
                window.scrollTo(0,document.body.scrollHeight);
            }
            if (message.action === "marked_all_messages_as_read"){
                queryCache.invalidateQueries(`conversation/${props.id}`);
                queryCache.invalidateQueries('user-conversations');
            }
        };
    }, []);

    const sendMessage = () => {
        if(message == ""){
            return
        }
        const data = {
            action: "send_message",
            receiver: Number(props.id),
            message: message
        }
        ws.current.send(JSON.stringify(data));
        const pendingMsg = {
            id: 173453425234523,
            sender: user.id,
            receiver: Number(props.id),
            content: message,
            is_read: null,
            timestamp: null
        }

        // Sending message
        queryCache.setQueryData(
            `conversation/${props.id}`,
            (old) => {
                old[0].results = [pendingMsg, ...old[0].results];
                return [...old];
            }
        )
        window.scrollTo(0, document.body.scrollHeight);
        setMessage("");
    }

    const onChangeMessage = (e) => {
        setMessage(e.target.value);
    }

    const onFocus = (e) => {
        const element = document.getElementsByClassName("chat-view")[0];
        element.style.paddingBottom = "8px";
    }

    const onBlur = (e) => {
        const element = document.getElementsByClassName("chat-view")[0];
        element.style.paddingBottom = "calc(0px + env(safe-area-inset-bottom))";
    }

    useEffect(() => {
        const messageTextArea = document.getElementById("message-input");
        if(messageTextArea){
            messageTextArea.style.height = "5px";
            messageTextArea.style.height = (messageTextArea.scrollHeight)+"px";
        }
    }, [message])

    useEffect(() => {
        const bottomNav = document.getElementsByClassName("bottom-nav-bar")[0];
        if(bottomNav){
            bottomNav.style.visibility = "hidden"
        }

        if(history.location.message){
            setMessage(history.location.message);
        }
        return () => {
            const bottomNav = document.getElementsByClassName("bottom-nav-bar")[0];
            if(bottomNav){
                bottomNav.style.visibility = "visible"
            }
        }
    }, [])

    if(!user.isLoggedIn) {
        const message = "Login to your account to be able to chat with other users."
        return <LogInToViewSaved message={message} image={chatImage}/>
    }

    const [, propertyID] = generateLinks(message);

    return (
        <GenericFilter
            selection={selection}
            endpoint={endpoint}
            placeholder={<BouncePageLoader/>}
            onError={renderPageError}>
            {(response) => {
                return (
                    <div className="p-0 m-0 mt-2 mt-md-3 chats">
                        <GenericResourcesGroup
                            defaultView="list"
                            header={header}
                            response={response}
                            notFoundMsg={notFoundMsg}
                            beforeRendering={beforeRendering}
                            headerclassName='px-2 px-sm-3'
                            disableViewSwitch
                            resourceclassName='col-sm-12 col-md-12 col-lg-12 px-2 my-1'
                            restoreScrollOnMovingBackOnly={props.restoreScrollOnMovingBackOnly}
                            FetchMoreOnScrollToTop>
                            {message =>
                                <Chat message={message} />
                            }
                        </GenericResourcesGroup>

                        <div className="chat-view">
                            {propertyID ?
                                <MessagePropertyLinkOverview id={propertyID} /> : null
                            }
                            <div className="row p-0 m-0 text-center">
                                <div className="text-input col-10 col-sm-11 p-0 m-0 pl-4">
                                    <textarea id="message-input" rows="1"
                                              value={message} onBlur={onBlur} ref={messageInput}
                                              onChange={onChangeMessage} onFocus={onFocus} />
                                </div>
                                <div className="send-text-btn btn col-2 col-sm-1 p-0 m-0 text-center">
                                    <div className="send-btn" onMouseDown={sendMessage}>
                                        <span className="icon icon-send" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }}
        </GenericFilter>
    );
}

export {
    GenericFilter, SearchProperties, EndpointPropertiesFilter,
    FilterPropertiesByCategory, UserProperties, ShowRentProperties,
    ShowBuyProperties, UserFavProperties, PROPERTIES_QUERY_PARAM,
    NearByProperties, UserNearByProperties, UserReservations,
    NotificationsFilter, ConversationsFilter, ChatFilter, ReservationRequests,
    PublishRequests, UserTours, TourRequests, GlobalSearch, VerificationRequests,
    SavedSearches, SavedItems, UserTransactions
}
