import React from 'react';
import { queryCache } from 'react-query';
import { store } from '../store';
import { useLocalState } from '../hooks';
import { BASE_API_URL } from '../';
import {
    DataFetcher, BouncePageLoader, renderPageError, NotFoundError
} from './';
import { Spinner } from 'react-bootstrap';
import './VerificationRequestDetails.scss';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';


function VerificationRequestDetails(props) {
    const verificationRequest = props.verificationRequest;
    const owner = verificationRequest.owner;
    const [user,] = store.useState("user");
    const [loading, , updateLoading] = useLocalState({
        accept: false, decline: false
    })

    const accept = (event) => {
        event.preventDefault();
        updateLoading(loading => {
            loading.accept = true
        });

        const formdata = {
            "user_verification": verificationRequest.id
        }

        const acceptUrl = `${BASE_API_URL}/accept-user-verification/`
        const headers = {
            'Authorization': `Token ${user.auth_token}`,
            'Content-Type': 'application/json'
        }

        fetch(acceptUrl, { method: 'POST', body: JSON.stringify(formdata), headers: headers })
            .then(res => res.json().then(data => ({ statusCode: res.status, data })))
            .then(res => {
                if (res.statusCode === 200) {
                    queryCache.invalidateQueries(`verification-request/${verificationRequest.id}`);
                }
                else if (res.statusCode === 400) {
                    toast.error(res.data.data.details)
                }
            })
            .catch(error => {
                toast.error("No network connection, please try again!.")
            })
            .finally(() => {
                updateLoading(loading => {
                    loading.accept = false
                });
            })
    }

    const decline = (event) => {
        event.preventDefault();
        updateLoading(loading => {
            loading.decline = true
        });

        const formdata = {
            "user_verification": verificationRequest.id,
            "reason": "--"
        }

        const declineUrl = `${BASE_API_URL}/decline-user-verification/`
        const headers = {
            'Authorization': `Token ${user.auth_token}`,
            'Content-Type': 'application/json'
        }

        fetch(declineUrl, { method: 'POST', body: JSON.stringify(formdata), headers: headers })
            .then(res => res.json().then(data => ({ statusCode: res.status, data })))
            .then(res => {
                if (res.statusCode === 200) {
                    queryCache.invalidateQueries(`verification-request/${verificationRequest.id}`);
                }
                else if (res.statusCode === 400) {
                    toast.error(res.data.data.details)
                }
            })
            .catch(error => {
                toast.error("No network connection, please try again!.")
            })
            .finally(() => {
                updateLoading(loading => {
                    loading.decline = false
                });
            })
    }

    return (
        <>
            <div className="row col-12 col-lg-7 p-0 m-0 verification-request-details mt-4">
                <div className="row w-100 p-0 m-0 justify-content-center">
                    <Link className="profile-picture" to={`/users/${owner.id}`}>
                        {owner.picture && owner.picture.src ?
                            <img src={owner.picture.src} alt="" /> :
                            <span className="icon icon-user" />
                        }
                    </Link>
                </div>

                <div className="row w-100 p-0 m-0 mt-2 justify-content-center">
                    <div className="full-name">
                        <span>@{owner.full_name} </span>
                        {owner.is_verified ?
                            <span className="icon icon-verified" /> : null
                        }
                    </div>
                </div>

                <div className="row submitted-info p-0 m-0 px-4 mt-4">
                    <div className="col-12 header m-0 p-0">Submitted Information</div>
            
                    <div className="col-12 m-0 p-0 mt-3">
                        <div className="col-12 info p-0 m-0 px-1">
                            <label>Full Name</label>
                            <div className="value">{verificationRequest.full_name}</div>
                        </div>
                        <div className="col-12 info p-0 m-0 px-1 mt-3">
                            <label>Document Type</label>
                            <div className="value">
                                {verificationRequest.document && verificationRequest.document.type ?
                                    verificationRequest.document.type: "No submitted document"
                                }
                            </div>
                        </div>
                        <div className="col-12 p-0 m-0 mt-4">
                            {verificationRequest.document && verificationRequest.document.src ?
                                <img src={verificationRequest.document.src} alt="Verification Document" /> :
                                null
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="verification-request-details row p-0 m-0 pt-4">
                <div className="verification-request-actions row p-0 m-0">
                    {verificationRequest.status === "pending" ?
                        <>
                            <div className="col text-center">
                                <div className="accept btn col-12 col-lg-6" onClick={accept} disabled={loading.accept}>
                                    {loading.accept ? <Spinner animation="border" size="sm" /> : 'Accept'}
                                </div>
                            </div>
                            <div className="col text-center">
                                <div className="decline btn col-12 col-lg-6" onClick={decline} disabled={loading.decline}>
                                    {loading.decline ? <Spinner animation="border" size="sm" /> : 'Decline'}
                                </div>
                            </div>
                        </> : null
                    }
                    {verificationRequest.status === "accepted" ?
                        <>
                            <div className="col-12 text-center">
                                <div className="unpublish btn col-12 col-lg-6" onClick={decline} disabled={loading.decline}>
                                    {loading.decline ? <Spinner animation="border" size="sm" /> : 'Revoke Verification'}
                                </div>
                            </div>
                        </> : null
                    }
                    {verificationRequest.status === "declined" ?
                        <>
                            <div className="col-12 text-center">
                                <div className="publish btn col-12 col-lg-6" onClick={accept} disabled={loading.accept}>
                                    {loading.accept ? <Spinner animation="border" size="sm" /> : 'Accept Verification'}
                                </div>
                            </div>
                        </> : null
                    }
                </div>
            </div>
        </>
    );
}

function VerificationRequestDetailsFetcher(props) {
    const [user,] = store.useState("user");

    const headers = {
        Authorization: `Token ${user.auth_token}`,
        "Content-Type": "application/json",
    }

    let fetchReservation = () => {
        return fetch(`${BASE_API_URL}/user-verifications/${props.id}/`, { headers: headers })
            .then(res => res.json().then(data => ({ statusCode: res.status, data })))
    }

    return (
        <DataFetcher action={fetchReservation} selection={`verification-request/${props.id}`}
            placeholder={<BouncePageLoader />} onError={renderPageError}>{response => {
                if (response.data.statusCode === 404) {
                    return <NotFoundError />
                }
                return <VerificationRequestDetails verificationRequest={response.data.data} />
            }}</DataFetcher>
    )
}


export { VerificationRequestDetailsFetcher as VerificationRequestDetails }
