import React from 'react';
import './TourOverview.scss';
import { Link } from 'react-router-dom';
import {
    capitalizeFirst,
    thousandsSeparator
} from '../utils';


function TourOverview(props) {
    const tour = props.tour;
    const property = tour.property;

    let location = property.location;
    let main_img = {is_main: null, src: null, id: null}
    main_img = property.pictures.filter((picture) => picture.is_main);
    if (main_img.length > 0) {
        main_img = main_img[0];
    }

    let path = `/tours/${tour.id}`

    return (
        <Link className="tour-overview row p-0 m-0" to={path} id={props.id}>
            <div className={"tour-info col-6 p-0 m-0"}>
                {location ?
                    <div className={"location"}> <i className="fa fa-map-marker-alt"></i>
                    &nbsp;{location.address}
                    </div>: null
                }
                <div className="cost">
                {`${tour.currency} ${thousandsSeparator(tour.cost)}`}
                </div>

                {tour.status ?
                    <div className="status">
                        Status: {capitalizeFirst(tour.status)}
                    </div> : null
                }

            </div>
            <div className="tour-img col-6 p-0 m-0 text-right">
                <img src={main_img.src} alt="" />
            </div>
        </Link>
    );
}


export { TourOverview }
