import React from 'react';
import './LogInToViewSaved.scss';
import {Link} from 'react-router-dom';
import { store } from '../store';
import { Button } from 'react-bootstrap';
import lovingItImage from '../images/undraw_loving_it.svg';
import { usePageTransition } from '../hooks';
import i18n from "i18next";


function LogInToViewSaved(props){
    const animate = usePageTransition()
    const [, setShowLogInModal] = store.useState("showLogInModal");
    const [, setShowSignUpModal] = store.useState("showSignUpModal");

    const login = (e) => {
        e.preventDefault();
        setShowLogInModal(true);
    }

    const signUp = (e) => {
        e.preventDefault();
        setShowSignUpModal(true);
    }

    const defaultMessage = "This view requires user to be logged in."

    return (
        <div className={`request-login row p-0 m-0 ${animate()}`}>
            <div className="col-12 p-0 m-0 mt-5 pt-5 pt-md-0 mt-md-4 d-flex justify-content-center">
                <img className="col-12 col-md-6" src={
                    props.image? props.image: lovingItImage
                }/>
            </div>
            <div className="login-message col-12 mt-3 text-center">
                {props.message ?
                    <>{i18n.t(props.message)}</>: i18n.t(defaultMessage)
                }
            </div>
            <div className="col-12 p-0 m-0 px-4 mt-2 mt-sm-4 d-flex justify-content-center">
                <Button className="login-btn col-6 col-md-2 my-3 my-md-1 py-2" variant="primary" onClick={login}>
                    {i18n.t("Login")}
                </Button>
            </div>
            <div className="col-12 mt-3 text-center">
                <p className="h6">{i18n.t("Don't have an account yet?")}
                    <Link to={'#'} onClick={signUp}> {i18n.t("Signup")}</Link>
                </p>
            </div>
        </div>
    );
}

export { LogInToViewSaved };
