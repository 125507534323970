import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { store } from "../store";
import "./SignUp.scss";
import { BASE_API_URL } from "../";
import { Modal, Nav, Button, Spinner } from "react-bootstrap";
import {
  setErrorClass, saveUserInfoToCookies,
  setTabColorDark, buildMessageFromResponse
} from "../utils";
import { useTranslation } from "react-i18next";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import PinField from "react-pin-field";

function PinPage(props) {
  const [pinPageError, setPinPageError] = useState("");
  const [pinPageSuccess, setPinPageSuccess] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [completed, setCompleted] = useState(false);
  let formInput = props.formInput;
  useEffect(setPinPageError, []);

  let showResponse = (response) => {

    let msg = "";
    if (response.status === 200) {
      msg = 'Login success';
      setPinPageSuccess(msg);
      let auth_token = response.data.token;
      if (auth_token !== undefined) {
        let userInfo = {
          id: response.data.id,
          auth_token: auth_token
        };
        saveUserInfoToCookies(userInfo);
        window.location = "/";
      } else if (response.data.non_field_errors !== undefined) {
        setPinPageError("Invalid credentials, please try again!.");
      } else {
        setPinPageError("Failed to login, please try again!.");
      }
    } else if (response.status === 400) {
      msg = buildMessageFromResponse(response.data);
      setPinPageError(msg);
    } else {
      msg = "Something went wrong! Please try again later";
      setPinPageError(msg);
    }
  };

  let verifyPin = (event) => {
    event.preventDefault();
    setPinPageError("");
    setLoading(true);

    let form_data = new FormData();
    form_data.append("phone", formInput.phone.replace(formInput.dial_code, ""));
    form_data.append("dial_code", "+" + formInput.dial_code);
    form_data.append("iso_code", formInput.iso_code);
    form_data.append("otp", code);

    let pinVerificationUrl = `${BASE_API_URL}/otp-verify/`;

    fetch(pinVerificationUrl, { method: "POST", body: form_data })
      .then(res => res.json().then(data => ({ status: res.status, data: data })))
      .then(data => showResponse(data))
      .catch(error => {
        // Network error
        setPinPageError(
          "No network connection, please try again!."
        );
      })
      .finally(() => {
        // Enable button
        setLoading(false);
      });
  };

  let clearErrors = () => {
    setPinPageError("");
  };

  return (
    <div className="container-fluid login py-4">
      <div className="header col-12 h5 m-0 p-0 pt-2 text-center text-secondary">Pin Verification</div>
      <form className="login-form text-secondary mt-3" onSubmit={verifyPin}>
        <div className="row justify-content-center">
          <div className="col-10 p-0 m-0 px-2 my-2 my-lg-3 text-center">
            Enter OTP number <br/>
            Check your email or phone number registered in Seto.
          </div>
          <div className="col-10 p-0 m-0 my-2 my-lg-3">
            <div className="col-12 px-2 floating text-center">
              <PinField
                length={4}
                className="pin-field"
                onChange={setCode}
                onComplete={() => setCompleted(true)}
              />
            </div>
          </div>

          {pinPageError ?
            <div className="col-10 p-0 m-0 text-danger text-center mt-2 mt-lg-3">
              {pinPageError}
            </div> : null
          }

          {pinPageSuccess ?
            <div className="col-10 p-0 m-0 text-success text-center mt-2 mt-lg-3">
              {pinPageSuccess}
            </div> : null
          }

          <div className="col-10 p-0 m-0 my-2 my-lg-3">
            <div className="col-12 px-2">
              <Button className="col-12 my-3" variant="primary" disabled={!completed} type="submit">
                {isLoading ? <Spinner animation="border" size="sm"/> : "Verify OTP"}
              </Button>
            </div>
          </div>
        </div>
      </form>
      <div className="col-12 pb-3 text-center">
        {isLoading ? <br/> : <Link to={"#"} onClick={() => props.setPinPage(false)}>Go back to Login</Link> }
      </div>
    </div>
  );
}

function SignUp(props) {
  const [loginError, setLoginError] = useState("");
  const [showLogInModal, setShowLogInModal] = store.useState("showLogInModal");
  const [showSignUpModal, setShowSignUpModal] = store.useState("showSignUpModal");
  const [isLoading, setLoading] = useState(false);
  const [formInput, ,setFormInput] = store.useState("signUp");

  const [pinPage, setPinPage] = useState(false);

  useEffect(setErrorClass, []);
  setTabColorDark(showLogInModal || showSignUpModal, "#151515");

  let showResponse = (response) => {
    console.log(response);
    if (response.status === 200) {
      setPinPage(true);
    }else if (response.status === 400) {
      let msg = buildMessageFromResponse(response.data);
      setLoginError(msg);
    } else {
      setLoginError(response.data)
    }
  };

  let signUp = (event) => {
    event.preventDefault();
    setLoginError("");

    console.log("formInput", formInput);

    setLoading(true);
    // 'otp-register/'
    // {
    //   "phone"    : phoneNumber,
    //   "iso_code" : isoCode,
    //   "dial_code": dialCode,
    //   "full_name": fullName,
    //   "email"    : email
    // }
    let form_data = new FormData();
    form_data.append("phone", formInput.phone.replace(formInput.dial_code, ""));
    form_data.append("iso_code", formInput.iso_code);
    form_data.append("dial_code", "+" + formInput.dial_code);
    form_data.append("full_name", formInput.full_name);
    form_data.append("email", formInput.email);

    let loginUrl = `${BASE_API_URL}/otp-register/`;

    fetch(loginUrl, { method: "POST", body: form_data })
      .then(res => res.json().then(data => ({ status: res.status, data: data })))
      .then(data => showResponse(data))
      .catch(error => {
        // Network error
        setLoginError("No network connection, please try again!.");
      })
      .finally(() => {
        // Enable button
        setLoading(false);
      });
  };

  let goToLogin = (e) => {
    setShowSignUpModal(false);
    setShowLogInModal(true);

    console.log(showLogInModal);
  };

  let clearErrors = () => {
    setLoginError("");
  };

  let handleValueChange = (e) => {
    setFormInput((user)=> {
      let field = e.target.name;
      let value = e.target.value;
      user[field] = value;
    });

  };

  let isFormValid = () => {
    // if (
    //   form.full_name.length > 0 &&
    //   form.email.length > 0 &&
    //   /\S+@\S+\.\S+/.test(form.email)
    // ) {
    //   return true
    // }
    // return false
  };

  const { t } = useTranslation();
  return (
    <>
      <Nav.Link href="#" onClick={() => setShowSignUpModal(true)}><span
        className="d-lg-none icon icon-enter"/>{t("Sign up")}</Nav.Link>

      <Modal animation={false} scrollable={true} className="login-modal" dialogClassName="custom-modal-dialog"
             show={showSignUpModal} onHide={() => setShowSignUpModal(false)} size="lg" aria-labelledby="" centered>
        <div className="modal-close" onClick={() => setShowSignUpModal(false)}>
          <span className="icon icon-close"/>
        </div>
        <Modal.Body className="p-0 m-0 modal-body">
          {!pinPage ?
            <div className="container-fluid login py-4">
              <div className="header col-12 h5 m-0 p-0 pt-2 text-center text-secondary">{t("Create your account")}</div>
              <form className="login-form text-secondary mt-3" onSubmit={signUp}>
                <div className="row justify-content-center">
                  <div className="col-10 p-0 m-0 my-2 my-lg-3">
                    <div className="col-12 px-2 floating">
                      <input type="text" name="full_name" value={formInput.full_name} required
                             onChange={handleValueChange} className="form-control" placeholder={t("Full Name")} />
                      {/*<label htmlFor="full_name" className="floating__label" data-content={t("Full Name")}></label>*/}
                    </div>
                  </div>

                  <div className="col-10 p-0 m-0 my-2 my-lg-3">
                    <div className="col-12 px-2 floating">
                      <input type="email" name="email" value={formInput.email} required
                             onChange={handleValueChange} className="form-control" placeholder={t("Email")} />
                      {/*<label htmlFor="email" className="floating__label" data-content={t("Email")}></label>*/}
                    </div>
                  </div>

                  <div className="col-10 p-0 m-0 my-2 my-lg-3">
                    <div className="col-12 px-2 floating">
                      <PhoneInput
                        inputStyle={{ color: "black", width: "100%" }}
                        country={"tz"}
                        onlyCountries={['tz','ke', 'ug']}
                        countryCodeEditable={false}
                        masks={{ tz: "... ... ..." }}
                        onChange={(value, country) => {
                          setFormInput(user => {
                            user.phone =  value;
                            user.iso_code = country.countryCode.toUpperCase();
                            user.dial_code = country.dialCode;
                          })
                        }
                        }
                      />
                    </div>
                  </div>

                  {loginError ?
                    <div className="col-10 p-0 m-0 text-danger text-center mt-2 mt-lg-3">
                      {loginError}
                    </div> : null
                  }

                  <div className="col-10 p-0 m-0 my-2 my-lg-3">
                    <div className="col-12 px-2">
                      <Button className="col-12 my-3" variant="primary" disabled={isLoading} type="submit">
                        {isLoading ? <Spinner animation="border" size="sm"/> : t("Sign Up")}
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
              <div className="col-12 pb-3 text-center">
                {t("Already have an account?")} <Link to={"#"} onClick={goToLogin}>{t("Log in")}</Link>
              </div>
            </div>
            : <PinPage setPinPage={setPinPage} formInput={formInput}/>
          }
        </Modal.Body>
      </Modal>
    </>
  );
}

export { SignUp };
