import moment from 'moment'


const PROPERTY_CATEGORIES = {
    "for-rent": ["room", "house", "apartment", "office"],
    "for-sale": ["house", "apartment", "office", "land"],
    "for-short-stay": [
        "room", "house", "apartment", "hotel_room",
        "lodge_room", "guest_house_room"
    ],
    "for-event": ["venue", "hotel_venue"]
}

const PROPERTY_DESCRIPTIONS = {
    "room": "A bedroom as part of a big house",
    "house": "Entire house",
    "apartment": "Fully furnished",
    "office": "Work place",
    "land": "Plot of land",
    "venue": "A separate place dedicated for events",
    "hotel": "Hotel with rooms, apartments & more",
    "hotel_room": "A room in a hotel",
    "hotel_apartment": "Apartment in a hotel",
    "hotel_venue": "A venue in a hotel",
    "lodge": "A lodge with bedrooms",
    "lodge_room": "A bedroom in a lodge",
    "guest_house": "A guest house with bedrooms",
    "guest_house_room": "A bedroom in a guest house"
}


// These properties have no parents
const STANDALONE_PROPERTY_NAMES = {
    "room": "Room",
    "house": "House",
    "apartment": "Apartment",
    "office": "Office",
    "land": "Land",
    "venue": "Venue",
    "hotel": "Hotel",
    "lodge": "Lodge",
    "guest_house": "Guest House"
}


// These properties must have parents(can't stand alone)
const NON_STANDALONE_PROPERTY_NAMES = {
    "hotel_room": "Hotel Room",
    "hotel_apartment": "Hotel Apartment",
    "hotel_venue": "Hotel Venue",
    "lodge_room": "Lodge Room",
    "guest_house_room": "Guest House Room"
}


// These properties can only be parents
// Values are possible values for their compartments
const PARENT_ONLY_PROPERTIES = {
    "hotel": ["room", "apartment", "venue"],
    "lodge": ["room"],
    "guest_house": ["room"]
}


const PROPERTY_ROUTES = {
    "generic": "properties",
    "room": "rooms",
    "house": "houses",
    "apartment": "apartments",
    "office": "offices",
    "land": "lands",
    "venue": "venues",
    "hotel": "hotels",
    "hotel_room": "rooms",
    "hotel_apartment": "apartments",
    "hotel_venue": "venues",
    "lodge": "lodges",
    "lodge_room": "rooms",
    "guest_house": "guest-houses",
    "guest_house_room": "rooms"
}


function getPropertyName(propertyType){
    const propertyNames = {
        ...STANDALONE_PROPERTY_NAMES,
        ...NON_STANDALONE_PROPERTY_NAMES
    }

    return propertyNames[propertyType]
}

function getPropertyRoute(propertyType){
    return PROPERTY_ROUTES[propertyType]
}

function setErrorClass(query = "input, select, textarea") {
    const inputs = document.querySelectorAll(query);

    inputs.forEach(input => {
        input.addEventListener(
            "invalid",
            event => {
                input.classList.add("error");
            },
            false
        );
    });
}

function setCookie({name, value, expires, path='/', sameSite='Lax'}){
    document.cookie = `${name}=${value};path=${path};expires=${expires};SameSite=${sameSite};`;
}

function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

function saveUserInfoToCookies(userData) {
    var d = new Date();
    d.setTime(d.getTime() + 30*24*60*60*1000); // in milliseconds
    for(let infoName in userData) {
        setCookie({name: infoName, value: userData[infoName], expires: d.toGMTString()});
    }
}

function getUserInfoFromCookies(cookieNames){
    let userInfo = {};
    cookieNames.forEach(cookieName => { userInfo[cookieName] = getCookie(cookieName)});
    return userInfo;
}

function deleteUserInfoFromCookies(cookieNames) {
    var d = new Date();
    d.setTime(d.getTime() - 24 * 60 * 60 * 1000); // in milliseconds
    cookieNames.forEach(cookieName => setCookie({name: cookieName, value: "", expires: d.toGMTString()}));
}

function onScrollToBottom(handleScrollToBottom, y = 1) {
    let scrollToBottomEventHandler = () => {
        let scrollTop = (
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop || 0
        );

        let distanceFromBottom = (
            document.documentElement.offsetHeight -
            (window.innerHeight + scrollTop)
        );

        if (distanceFromBottom < y && distanceFromBottom > -1) {
            handleScrollToBottom();
        }
    };

    return scrollToBottomEventHandler
}

function onScrollToTop(handleScrollToTop, y = 1) {
    let scrollToTopEventHandler = () => {
        let scrollTop = (
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop || 0
        );

        if (scrollTop < y && scrollTop > -1) {
            handleScrollToTop();
        }
    };

    return scrollToTopEventHandler
}

/**
 * @param {HTMLImageElement} image - Image File Object
 * @param {Object} crop - crop Object
 * @param {Function} saveImage- Function which receives cropped image and saves
 */
function cropImage(image, crop, saveImage, imageQuality=0.8) {
    image.onload = function () {
        const canvas = document.createElement('canvas');

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        canvas.width = crop.width * scaleX;
        canvas.height = crop.height * scaleY;

        const sx = crop.x * scaleX;
        const sy = crop.y * scaleY;
        const sw = crop.width * scaleX;
        const sh = crop.height * scaleY;
        const dx = 0;
        const dy = 0;
        const dw = crop.width * scaleX;
        const dh = crop.height * scaleY;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(
            image,
            sx, sy, sw, sh,  // Source dimensions
            dx, dy, dw, dh  // Destination dimensions
        );

        canvas.toBlob(saveImage, 'image/jpeg', imageQuality);
    }
}

function getSuitableImageQuality(imageSize){
    let imageSizeInMB = imageSize/1000000  // Size in MB

    if (imageSizeInMB < 0.25)
        return 0.6
    if (imageSizeInMB < 1)
        return 0.4
    if (imageSizeInMB < 5)
        return 0.3
    if (imageSizeInMB < 10)
        return 0.2
    return 0.1
}

function setTabColorDark(conditionToChangeColor, darkColor="rgb(14, 14, 14)"){
    let metaThemeColor = document.querySelector("meta[name=theme-color]");
    if (conditionToChangeColor) {
        metaThemeColor.setAttribute("content", darkColor);
    }
    else {
        metaThemeColor.setAttribute("content", "white");
    }
}

function capitalizeFirst(string) {
    return string[0].toUpperCase() + string.slice(1);
}

function thousandsSeparator(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function scrollIntoView({elementID, yOffset=0, behavior="smooth"}){
    const element = document.getElementById(elementID)
    if (element) {
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: behavior});
    }
}

function isPositiveInt(val){
    return /^\d+$/.test(val)
}

function formatTimeDistance(startDateTime, endDateTime=moment.now()){
    let timeDistance = null;

    let time_difference_in_days = moment(
        endDateTime
    ).diff(startDateTime, 'days')

    if(time_difference_in_days < 1){
        timeDistance = moment(startDateTime).format("HH:mm");
    }
    else if(time_difference_in_days < 2){
        timeDistance = "Yesterday"
    }
    else {
        timeDistance = moment(startDateTime).format("DD/MM/YY");
    }
    return timeDistance
}

const propertyRoutes = new Set()

for(let key in PROPERTY_ROUTES){
    propertyRoutes.add(PROPERTY_ROUTES[key]);
}


function generateLinks(text) {
    const urlRegexStr = `(((https?:\\/\\/)|(www\.)|(${window.location.hostname}))[^\\s]+)`;

    const urlRegex = new RegExp(urlRegexStr)

    let propertyID = null;

    const message = text.replace(urlRegex, function(url, b, c) {
        const url2 = (c == 'www.') ?  'http://' +url : url;

        const propertyURLRegex = `(?:(?:https:\\/\\/|)(?:www\\.|)${
            window.location.hostname}\\/#\\/(${
            propertyRoutes.join("|")
        })\\/(\\d+))`

        const propertyURLMatch = url2.match(new RegExp(propertyURLRegex))

        if(propertyURLMatch){
            const propertyURL =  propertyURLMatch[1]
            propertyID = Number(propertyURLMatch[2])
            return '<a href="' +`#/${propertyURL}/${propertyID}/`+ '">' + url + '</a>'
        }

        return '<a href="' +url2+ '" target="_blank">' + url + '</a>';
    })

    return [message, propertyID]
}


function importAll(require) {
    return require.keys().reduce((acc, next) => {
        acc[next.replace("./", "").replace(/\.(png|jpe?g|svg)$/, "")] = require(next);
        return acc;
    }, {});
}


function buildMessageFromResponse(data) {
    if (typeof data === 'string' || data instanceof String) {
        return data;
    }

    if (Array.isArray(data)) {
        return data.map(item => buildMessageFromResponse(item)).join(" ")
    }

    const messages = [];
    for (let key in data) {
        messages.push(`${buildMessageFromResponse(data[key])}`);
    }
    return messages.join(" ");
}


export {
    setErrorClass, setCookie, getCookie, saveUserInfoToCookies,
    getUserInfoFromCookies, deleteUserInfoFromCookies, onScrollToBottom,
    getPropertyRoute, cropImage, setTabColorDark, getPropertyName,
    capitalizeFirst, getSuitableImageQuality, thousandsSeparator,
    STANDALONE_PROPERTY_NAMES, NON_STANDALONE_PROPERTY_NAMES, PARENT_ONLY_PROPERTIES,
    PROPERTY_ROUTES, PROPERTY_CATEGORIES, PROPERTY_DESCRIPTIONS, scrollIntoView,
    isPositiveInt, onScrollToTop, formatTimeDistance, generateLinks, importAll,
    buildMessageFromResponse
}
