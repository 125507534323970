import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router';
import { Route, MemoryRouter } from 'react-router-dom';
import './AdvancedSearch.scss';
import { store } from '../store';
import { Modal} from 'react-bootstrap';
import {
    getPropertyName, PROPERTY_CATEGORIES,
    PROPERTY_DESCRIPTIONS, importAll
} from '../utils';
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";
import {
    Combobox, ComboboxInput, ComboboxPopover,
    ComboboxList, ComboboxOption,
} from "@reach/combobox";
import { useGeolocationErrorsLogger } from '../hooks';
import {useTranslation} from "react-i18next";
import { InfoModal } from '.';
import { PropertiesFilter } from './PropertiesFilter';


const images = importAll(
  require.context("../images/advanced-search-imgs", false, /\.(png|jpe?g|svg)$/)
);


function CategorySelection(props) {
    const history = useHistory()
    const [form, ,updateForm] = store.useState("global-search");

    const setForRent = () => {
        updateForm(form => {
            form.property.category = "for-rent"
            form.property.available_for = "rent"
        })
        history.push("/what");
    }

    const setForSale = () => {
        updateForm(form => {
            form.property.category = "for-sale"
            form.property.available_for = "sale"
        })
        history.push("/what");
    }

    const setForShortStay = () => {
        updateForm(form => {
            form.property.category = "for-short-stay"
            form.property.available_for = "book"
        })
        history.push("/what");
    }

    const setForEvent = () => {
        updateForm(form => {
            form.property.category = "for-event"
            form.property.available_for = "book"
        })
        history.push("/what");
    }
    return (
        <div className="category-selection row p-0 m-0">
            <div className="header row p-0 m-0">
                <div className="back-btn" onClick={() => props.setShowModal(false)}>
                    <span className="icon icon-up-arrow"></span>
                </div>
                {props.t('What are you looking for?')}

            </div>

            <div className="categories row p-0 m-0 px-2 mt-2 w-100">

                <div className="category row p-0 m-0 w-100" onClick={setForRent}>
                    <div className="info col-9 p-0 m-0">
                        <div className="title col-12 p-0 m-0">
                            {props.t('A place to rent')}
                        </div>
                        <div className="sub-title col-12 p-0 m-0 mt-1">
                            {props.t('House')}, {props.t('Room')}, {props.t('Apartment')}, {props.t('Office')}
                        </div>
                    </div>
                    <div className="picture col-3 p-0 m-0 text-right">
                        <img alt="prop-pic" src={images["for-rent"]} />
                    </div>
                </div>

                <div className="category row p-0 m-0 w-100" onClick={setForSale}>
                    <div className="info col-9 p-0 m-0">
                        <div className="title col-12 p-0 m-0">
                            {props.t('A property to buy')}
                        </div>
                        <div className="sub-title col-12 p-0 m-0 mt-1">
                            {props.t('House')}, {props.t('Apartment')}, {props.t('Office')}, {props.t('Land')}
                        </div>
                    </div>
                    <div className="picture col-3 p-0 m-0 text-right">
                        <img alt="prop-pic" src={images["for-sale"]} />
                    </div>
                </div>

                <div className="category row p-0 m-0 w-100" onClick={setForShortStay}>
                    <div className="info col-9 p-0 m-0">
                        <div className="title col-12 p-0 m-0">
                            {props.t('A short-stay place')}
                        </div>
                        <div className="sub-title col-12 p-0 m-0 mt-1">
                            {props.t('Hotel')}, {props.t('Lodge')}, {props.t('House')}, {props.t('Apartment')}
                        </div>
                    </div>
                    <div className="picture col-3 p-0 m-0 text-right">
                        <img alt="prop-pic" src={images["for-short-stay"]} />
                    </div>
                </div>

                <div className="category row p-0 m-0 w-100" onClick={setForEvent}>
                    <div className="info col-9 p-0 m-0">
                        <div className="title col-12 p-0 m-0">
                            {props.t('A place to host event')}
                        </div>
                        <div className="sub-title col-12 p-0 m-0 mt-1">
                            {props.t('Wedding')}, {props.t('Party')}, {props.t('Meeting')}
                        </div>
                    </div>
                    <div className="picture col-3 p-0 m-0 text-right">
                        <img alt="prop-pic" src={images["for-event"]} />
                    </div>
                </div>

            </div>
        </div>
    );
}


function PropertySelection(props) {
    const history = useHistory();
    const [form, ,updateForm] = store.useState("global-search");

    const goBack = () => {
        history.goBack()
    }

    const setPropertyType = (type) => {
        updateForm(form => {
            form.property.type = type;
        })
        history.push("/where")
    }

    return (
        <div className="property-selection row p-0 m-0">
            <div className="header row p-0 m-0">
                <div className="back-btn" onClick={goBack}>
                    <span className="icon icon-up-arrow"></span>
                </div>
                {props.t('Specify property type')}
            </div>

            <div className="property-types row p-0 m-0 px-2 mt-2 w-100">

                {PROPERTY_CATEGORIES[form.property.category].map(type =>
                    <div className="property-type row p-0 m-0 w-100"
                        onClick={() => setPropertyType(type)}>
                        <div className="info col-9 p-0 m-0">
                            <div className="title col-12 p-0 m-0">
                                {props.t(getPropertyName(type))}
                            </div>
                            <div className="sub-title col-12 p-0 m-0 mt-1">
                                {props.t(PROPERTY_DESCRIPTIONS[type])}
                            </div>
                        </div>
                        <div className="picture col-3 p-0 m-0 text-right">
                            <img alt="prop-pic"  src={images[type]} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}


function Search(props) {
    const searchInput = useRef(null);
    const {
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            location: {
                lat: () => 0,
                lng: () => 0
            },
            radius: 100 * 1000,
        },
    });

    const searchByLatLng = (location) => {
        searchInput.current.blur();
        props.onChange(location);
        //history.push(`/search/?lng=${location.lng}&lat=${location.lat}`);
    }

    const handleSelect = async (address) => {
        setValue(address, false);  // Don't make API call
        clearSuggestions();

        try {
            const results = await getGeocode({ address });
            const { lat, lng } = await getLatLng(results[0]);
            searchByLatLng({lat, lng, address});
        } catch (error) {
            console.log("😱 Error: ", error);
        }
    };

    const handleInputChange = (e) => {
        if (props.simple) {
            setValue(e.target.value, false);  // Don't make API call
        }
        else {
            setValue(e.target.value);  // Make API call
        }
    };

    return (
        <Combobox onSelect={handleSelect} className="location-input-container col-12 p-0 m-0 px-2">
            <ComboboxInput value={value} onChange={handleInputChange} autoComplete="off" autoFocus
                name="search" type="search" placeholder={props.t("What's your preferred location?..")}
                className="location-input col-12" ref={searchInput} />

            {status === "OK" && data ?
                <ComboboxPopover className="advanced-search-location-suggestions-box">
                    <ComboboxList>
                        {data.map(({ id, description }) => (
                            <>
                            <span className="icon icon-marker"/>
                            <ComboboxOption className="location-option" key={id} value={description} />
                            </>
                        ))}
                    </ComboboxList>
                </ComboboxPopover> : null
            }
        </Combobox>
    );
}


function LocationSelection(props) {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const logError = useGeolocationErrorsLogger(true);
    const [parameters, ,updateForm] = store.useState("global-search");

    const goBack = () => {
        history.goBack()
    }

    const updateLocation = (location) => {
        updateForm(form => {
            form.property.location = {...location}
        })

        const path = `/global-search/?${
            parameters.property.type? `&type=${parameters.property.type}`: ""
        }${location.lng & location.lat ?
            `&longitude=${location.lng}&latitude=${location.lat}&address=${location.address||""}&radius_to_scan=${location.radius_to_scan||5000}`: ""
        }${parameters.property.available_for?
            `&available_for=${parameters.property.available_for}`: ""}`;

        props.history.push(path)
        props.setShowModal(false);
    }

    const setLocation = async (position) => {
        const selectedPoint = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
        };

        try {
            const results = await getGeocode({ location: selectedPoint });
            const { lat, lng } = await getLatLng(results[0]);
            const address = results[0].formatted_address;

            updateLocation({lng, lat, address});
        } catch (error) {
            console.log("😱 Error: ", error);
        } finally {
            setLoading(false);
        }
    }

    const setCurrentLocation = (e) => {
        setLoading(true);
        e.preventDefault();
        navigator.geolocation.getCurrentPosition(
            setLocation,
            (error) => {
                setLoading(false)
                logError(error)
            }
        );
    }

    const showLoading = () => {
        if(loading){
            return "load-location"
        }
        return ""
    }

    return (
        <div className="location-selection row p-0 m-0">
            <div className="header row p-0 m-0 w-100">
                <div className="back-btn" onClick={goBack}>
                    <span className="icon icon-up-arrow"></span>
                </div>
                <Search onChange={updateLocation} t={props.t}/>
            </div>

            <div className="other-options row p-0 m-0 px-2 mt-2 w-100">

                <div className="option row p-0 m-0 w-100" onClick={setCurrentLocation}>
                    <div className="info col-9 p-0 m-0">
                        <div className="title col-12 p-0 m-0">
                            {props.t('Current location')}
                        </div>
                        <div className="sub-title col-12 p-0 m-0 mt-1">
                            {props.t("Use my current location")}
                        </div>
                    </div>
                    <div className="location-marker col-3 p-0 m-0 text-right">
                        <span className={`icon icon-marker ${showLoading()}`}/>
                    </div>
                </div>

                <div className="option row p-0 m-0 w-100" onClick={()=>updateLocation({})}>
                    <div className="info col-9 p-0 m-0">
                        <div className="title col-12 p-0 m-0">
                            {props.t("I'm flexible")}
                        </div>
                        <div className="sub-title col-12 p-0 m-0 mt-1">
                            {props.t("Don't specify location")}
                        </div>
                    </div>
                    <div className="location-marker col-3 p-0 m-0 text-right">
                        <span className="icon icon-marker"/>
                    </div>
                </div>
            </div>
        </div>
    );
}


function AdvancedSearch(props) {
    const { t } = useTranslation();
    const history = useHistory();
    const [searchModalShow, setSearchModalShow] = useState(false);
    const [filterModalShow, setFilterModalShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const logError = useGeolocationErrorsLogger(true);

    const setLocation = async (position) => {
        const selectedPoint = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
        };

        try {
            const results = await getGeocode({ location: selectedPoint });
            const { lat, lng } = await getLatLng(results[0]);
            // const address = results[0].formatted_address;

            history.push(`/search/?lng=${lng}&lat=${lat}`);
        } catch (error) {
            console.log("😱 Error: ", error);
        } finally {
            setLoading(false);
        }
    }

    const setCurrentLocation = (e) => {
        setLoading(true);
        e.preventDefault();
        navigator.geolocation.getCurrentPosition(
            setLocation,
            (error) => {
                setLoading(false)
                logError(error)
            }
        );
    }

    const showLoading = () => {
        if(loading){
            return "load-location"
        }
        return ""
    }

    const onSearchView = history.location.pathname === "/global-search/";

    return (
        <>
            <div className="global-search col-7 col-lg-5 p-0 m-0 ml-3 ml-md-0 hide-small-devices">
                <span className="icon icon-search" />
                <input className="global-search-input col-12 p-0 m-0" readOnly
                 placeholder={t("What're u looking for?")} onClick={(e) => setSearchModalShow(true)}/>
                {onSearchView ?
                    <div className="filter" data-toggle="tooltip" onClick={(e) => setFilterModalShow(true)}
                        onMouseDown={(event) => { event.preventDefault() }}
                        data-placement="bottom" title="Do more filtering">
                        <span className={`icon icon-filter`} />
                    </div> :
                    <div className="current-location" data-toggle="tooltip" onClick={setCurrentLocation}
                        onMouseDown={(event) => { event.preventDefault() }}
                        data-placement="bottom" title="Explore nearby listings">
                        <span className={`icon icon-marker ${showLoading()}`} />
                    </div>
                }
            </div>

            <Modal animation={false} className="search-modal"
                dialogClassName="custom-modal-dialog" show={searchModalShow}
                onHide={() => setSearchModalShow(false)} size="lg"
                aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className="p-0 m-0 search-modal-body">
                    <div className="container-fluid advanced-search">
                        <MemoryRouter initialEntries={["/category", "/what", "/where", { pathname: "/" }]} initialIndex={0}>
                            <Route exact path="/category" render={() => {
                                return <CategorySelection showModal={searchModalShow} setShowModal={setSearchModalShow} t={t}/>
                            }} />
                            <Route exact path="/what" render={() => {
                                return <PropertySelection showModal={searchModalShow} setShowModal={setSearchModalShow} t={t}/>
                            }} />
                            <Route exact path="/where" render={() => {
                                return <LocationSelection  history={history} showModal={searchModalShow} setShowModal={setSearchModalShow} t={t}/>
                            }} />
                        </MemoryRouter>
                    </div>
                </Modal.Body>
            </Modal>

            <InfoModal className="filtering-modal" srollable={true} positionBottom
                header="More Filters" modalShow={filterModalShow} setModalShow={setFilterModalShow}>
                <PropertiesFilter onFinish={e => setFilterModalShow(false)}/>
            </InfoModal>
        </>
    );
}

export { AdvancedSearch };
