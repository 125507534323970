export default [
    {
        featureType: "poi",
        elementType: "all",
        stylers: [

        ],
    },
    {
        featureType: "poi.arterial",
        elementType: "labels.icon",
        stylers: [
            {
                saturation: -100,
            },
            {
                lightness: 10,
            },
        ],
    },
    {
        featureType: "poi.arterial",
        elementType: "labels.text.fill",
        stylers: [
            {
                saturation: -100,
            },
            {
                lightness: 10,
            },
        ],
    },
    {
        featureType: "landscape",
        elementType: "all",
        stylers: [
            {
                color: "#eeeeee",
            },
        ],
    },
    {
        featureType: "road",
        elementType: "all",
        stylers: [
            {
                saturation: 0,
            },
            {
                lightness: 0,
            },
        ],
    },
    {
        featureType: "road",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#ffffff",
            },
        ],
    },
    {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#7b7b7b",
            },
        ],
    },
    {
        featureType: "road",
        elementType: "labels.text.stroke",
        stylers: [
            {
                color: "#ffffff",
            },
        ],
    },
    {
        featureType: "road.highway",
        elementType: "all",
        stylers: [
            {
                visibility: "simplified",
            },
        ],
    },
    {
        featureType: "road.arterial",
        elementType: "labels.icon",
        stylers: [
            {
                visibility: "off",
            },
        ],
    }
];