import React from 'react';
import variables from '../variables.scss';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';


const theme = theme => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary: variables.primaryColor,
        neutral30: 'rgb(238, 238, 238)',
        primary25: variables.transparentPrimaryColor
    },
})

const styles = {
    input: styles => ({
        ...styles,
        padding: 0,
        margin: 0
    }),
    control: (styles, state) => ({
        ...styles,
        backgroundColor: 'rgb(250, 250, 250)',
        border: state.isFocused ? 'none' : 'solid 1px rgb(238, 238, 238)',
        minHeight: 40,
        borderRadius: 6,
        padding: '0',
        margin: 0
    }),
    valueContainer: (styles, state) => ({
        ...styles,
        padding: '0 2px',
        margin: 0
    }),
    multiValue: (styles, state) => ({
        ...styles,
        borderRadius: 5,
        margin: '4px 2px'
    }),
    multiValueLabel: (base, state) => ({
        ...base,
        padding: '5px 2px 6px 5px'
    }),
    multiValueRemove: (base, state) => ({
        ...base,
        borderRadius: '0 5px 5px 0'
    })
}

function CustomAsyncCreatableSelect(props){
    return <AsyncCreatableSelect theme={theme} styles={styles} {...props}/>
}

function CustomAsyncSelect(props){
    return <AsyncSelect theme={theme} styles={styles} {...props}/>
}

export {
    CustomAsyncCreatableSelect as AsyncCreatableSelect,
    CustomAsyncSelect as AsyncSelect
}