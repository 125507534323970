import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import smoothscroll from "smoothscroll-polyfill";

import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import ReactDOMServer from "react-dom/server";
import PullToRefresh from "pulltorefreshjs";
import * as serviceWorker from "./serviceWorker";
import "./custom.scss";
import "./index.scss";
import "./icons.scss";
import {App} from "./components";
import {HashRouter as Router} from "react-router-dom";
import {ReactQueryConfigProvider} from "react-query";
import {initializeStore} from "./store";
import "./i18n";
import {Helmet} from "react-helmet";

// kick off the polyfill!
smoothscroll.polyfill();

// Do this before calling ReactDOM.render
initializeStore();

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
const BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_WS_URL = process.env.REACT_APP_BASE_WS_URL;

window.onScrollActions = {};

window.onscroll = () => {
    for (let action in window.onScrollActions) {
        window.onScrollActions[action]();
    }
};

const queryConfig = {
    queries: {
        staleTime: 1000 * 60 * 5,
    },
};

function Application(props) {
    useEffect(() => {
        PullToRefresh.init({
            mainElement: "body",
            onRefresh() {
                window.location.reload();
            },
            distThreshold: 90,
            distMax: 130,
            distReload: 60,
            resistanceFunction: (t) => Math.min(1, t / 6),
            iconArrow: ReactDOMServer.renderToString(
                <span className="icon icon-up-arrow refresh-arrow"/>
            ),
            iconRefreshing: ReactDOMServer.renderToString(<span>...</span>),
        });
        return () => {
            PullToRefresh.destroyAll();
        };
    });

    return (
        <>
            <ReactQueryConfigProvider config={queryConfig}>
                <Router base="/">
                    <Helmet>
                        <meta charSet="utf-8"/>
                        <title>Seto - Dalali Halali</title>
                        <link rel="canonical" href="https://seto.co.tz/"/>
                        <meta name="description" content="Pata Chumba, Nyumba, Apartment, Ofisi, Viwanja, Hoteli, Ukumbi eneo Lolote - yote yamewezeshwa na Seto. Find Room, House, Apartment, Office, Land Plots, Hotel, Venue anywhere - all available in Seto."/>
                        <meta property="og:url" content="seto.co.tz"/>
                        <meta property="og:site_name" content="Seto"/>
                        <meta property="og:description" content="Pata Chumba, Nyumba, Apartment, Ofisi, Viwanja, Hoteli, Ukumbi eneo Lolote - yote yamewezeshwa na Seto."/>
                        <meta property="og:type" content="website"/>
                        <meta property="fb:app_id" content="ID_APP_FACEBOOK"/>
                    </Helmet>
                    <App/>
                </Router>
            </ReactQueryConfigProvider>
        </>
    );
}

ReactDOM.render(<Application/>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

export {BASE_API_URL, BASE_WS_URL, BASE_URL};
