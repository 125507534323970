import React, { useState } from "react";
import "./EditProperty.scss";
import { useHistory } from "react-router";
import { Button, Spinner } from "react-bootstrap";
import {
  FeaturesInput, DataFetcher, BouncePageLoader, ImageUploader,
  MultipleImageUploader, renderPageError, Map, NotFoundError
} from "./";
import { BASE_API_URL } from "../";
import { store } from "../store";
import { getPropertyRoute, PARENT_ONLY_PROPERTIES } from "../utils";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { AsyncSelect } from "./";
import { queryCache } from "react-query";
import { useScrollTop, useLocalState } from "../hooks";
import i18n from "i18next";
import makeAnimated from "react-select/animated";


const animatedComponents = makeAnimated();

function EditFetchedProperty(props) {
  useScrollTop();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [editError, setEditError] = useState("");

  const [fields, , updateFields] = useLocalState({ ...props.property, otherInputs: {} });
  const [user] = store.useState("user");
  const [featuresToDelete] = useState([]);
  const [imgsToDelete] = useState([]);
  const [mainImg, setMainImg] = useState([]);
  const [otherImgs, setOtherImgs] = useState([]);

  let formatOptions = (options) => {
    return options.map(option => {
      return { value: option.id, label: option.name };
    });
  };

  const [selectionFields, , updateSelectionFields] = useLocalState({
    amenities: formatOptions(props.property.amenities),
    services: formatOptions(props.property.services)
  });

  const currencies = ["TZS", "USD"];
  const terms = ["Day", "Month", "Year"];

  let createImage = (img) => {
    let postData = new FormData();
    postData.append("property", fields.id);
    postData.append("is_main", Number(img.is_main));
    postData.append("tool_tip", img.tool_tip);
    postData.append("src", img.src, img.src.name);

    let postUrl = `${BASE_API_URL}/property-pictures/`;
    let headers = {
      "Authorization": `Token ${user.auth_token}`
    };
    return fetch(postUrl, { method: "POST", body: postData, headers: headers })
      .then(res => res.json().then(data => ({ status: res.status, data: data })))
      .then(obj => obj);
  };

  let deleteImage = (imgID) => {
    let postUrl = `${BASE_API_URL}/property-pictures/${imgID}/`;
    let headers = {
      "Authorization": `Token ${user.auth_token}`
    };
    return fetch(postUrl, { method: "DELETE", body: "", headers: headers });
  };

  let updateImages = async (prevResponse) => {
    let pictures = [...mainImg, ...otherImgs];
    for (let picture of pictures) {
      if (picture.id === null) {
        await createImage(picture);
      } else {
        //updateImage(picture);
      }
    }
    for (let pictureID of imgsToDelete) {
      await deleteImage(pictureID);
    }
  };

  let redirect = (response) => {
    // Get a fresh property(This will trigger property fetching)
    queryCache.invalidateQueries(`property/${fields.id}`);
    return history.replace(`/${getPropertyRoute(props.type)}/${fields.id}`);
  };

  let formatSelection = selection => {
    let newValue = selectionFields[selection];
    let oldValue = fields[selection];

    let newValueIDS = newValue.filter(option => !option.__isNew__)
      .map(option => option.value);

    let oldValueIDS = oldValue.map(option => option.id);

    let toAdd = newValueIDS.filter(id => !oldValueIDS.includes(id));
    let toDelete = oldValueIDS.filter(id => !newValueIDS.includes(id));

    let data = {
      "add": toAdd,
      "remove": toDelete
    };
    return data;
  };

  let updateProperty = (e) => {
    e.preventDefault();
    setEditError("");
    setLoading(true);
    let form = e.target;

    let features = {
      create: [],
      remove: [],
      update: {}
    };

    for (let feature of fields.other_features) {
      if (featuresToDelete.indexOf(feature.id) !== -1) {
        //To delete
        continue;
      } else if (feature.id === null) {
        let featureValues = { name: feature.name, value: feature.value };
        features.create.push(featureValues);
      } else {
        let featureValues = { name: feature.name, value: feature.value };
        features.update[`${feature.id}`] = featureValues;
      }
    }
    features.remove = featuresToDelete;

    let price = {};

    if (PARENT_ONLY_PROPERTIES[fields.type] === undefined) {
      price = {
        pricing: {
          amount: fields.pricing.amount,
          rate_unit: fields.available_for !== "sale" ? fields.pricing.rate_unit : null,
          agent_fee: (fields.available_for === 'sale' || fields.available_for === 'rent') ? fields.pricing.agent_fee : null,
          currency: fields.pricing.currency,
          payment_terms: fields.pricing.payment_terms
        }
      };
    }

    console.log('price', price);

    let location = {
      location: {
        address: fields.location.address,
        point: fields.location.point
      }
    };

    let contact = {
      contact: {
        name: form.full_name.value,
        email: form.email.value,
        phone: form.phone.value
      }
    };

    if (fields.parent_property) {
      location = {};
      contact = {};
    }

    let formData = {
      ...price,
      ...contact,
      ...location,
      amenities: formatSelection("amenities"),
      services: formatSelection("services"),
      description: fields.description,
      title: fields.title,
      other_features: features,
      ...fields.otherInputs
    };

    let postUrl = `${BASE_API_URL}/${getPropertyRoute(props.type)}/${fields.id}/?format=json`;
    let headers = {
      "Authorization": `Token ${user.auth_token}`,
      "Content-Type": "application/json"
    };

    fetch(postUrl, { method: "PATCH", body: JSON.stringify(formData), headers: headers })
      .then(res => res.json().then(data => ({ status: res.status, data: data })))
      .then(obj => updateImages(obj))
      .then(obj => redirect(obj))
      .catch(error => {
        // Network error
        setEditError("No network connection, please try again!.");
      })
      .finally(() => {
        // Enable button
        setLoading(false);
      });
  };

  let updatePricing = (e) => {
    updateFields(fields => {
      let field = e.target.getAttribute("data-field");
      let value = e.target.value;
      fields.pricing[field] = value;
    });
  };
  let updateValue = (e) => {
    updateFields(fields => {
      fields[e.target.name] = e.target.value;
    });
  };

  let updateLocation = (location) => {
    updateFields(fields => {
      fields.location.address = location.address;
      fields.location.point = `POINT (${location.point.lng} ${location.point.lat})`;
    });
  };

  let updateContact = (e) => {
    updateFields(fields => {
      let field = e.target.getAttribute("data-field");
      let value = e.target.value;
      fields.contact[field] = value;
    });
  };

  let updateOtherImages = (value) => {
    setOtherImgs(value);
  };

  let updateMainImage = (value) => {
    setMainImg(value);
  };

  let updateFeatures = (features) => {
    updateFields(fields => {
      fields["other_features"] = features;
    });
  };

  let markFeatureToDelete = (feature) => {
    if (feature.id !== null) {
      featuresToDelete.push(feature.id);
    }
  };

  let markImgToDelete = (img) => {
    if (img.id !== null) {
      imgsToDelete.push(img.id);
    }
  };

  let getOptions = (url) => {
    return fetch(url)
      .then(res => res.json())
      .then(results => results.results.map(
        amenity => {
          return { value: amenity.id, label: amenity.name };
        }
      ));
  };

  let get = (selection) => {
    let getSelection = inputValue => {
      const URL = `${BASE_API_URL}/${selection}/?query={id,name}&format=json&name__icontains=${inputValue}`;
      return getOptions(URL);
    };
    return getSelection;
  };

  let update = (selection) => {
    let updateSelection = (value) => {
      if (!value) {
        value = [];
      }
      updateSelectionFields(selectionFields => {
        selectionFields[selection] = value;
      });
    };
    return updateSelection;
  };

  let child;
  if (props.children === undefined) {
    child = {};
  } else {
    child = props.children(props.property);
  }

  return (
    <form className="property-form text-secondary px-3 px-sm-4 mt-2 mt-sm-3" onSubmit={updateProperty}>
      <div className="row mt-3 p-md-2">
        <div className="col-12 col-md-7 pr-md-4">
          <div className="standard-form-field row p-0 m-0 mb-4">
            <label className="form-check-label col-12 p-0 m-0">{i18n.t("Title")}</label>
            <div className="col-12 my-1 px-0">
              <input type="text" data-field="title" name="title" value={fields.title} onChange={updateValue}
                     className="form-control" placeholder={i18n.t("Enter Property / Short Description")}/>
            </div>
          </div>
          {PARENT_ONLY_PROPERTIES[fields.type] === undefined ?
            <div className="row p-0 m-0 mb-4">
              <label className="form-check-label col-12 p-0 m-0">{i18n.t("Pricing")}</label>
              <div className="standard-form-field col-12 p-0 m-0 my-1">
                {fields.available_for === "sale" ?
                  <div className="row">
                    <div className="col-8 pr-1">
                      <input type="number" data-field="amount" name="price" value={fields.pricing.amount}
                             onChange={updatePricing}
                             className="form-control" placeholder={i18n.t("Price")} required/>
                    </div>
                    <div className="col-4 pl-1">
                      <select className="custom-select" data-field="currency" name="currency"
                              value={fields.pricing.currency} onChange={updatePricing} required>
                        <option value="" disabled>{i18n.t("Currency")}</option>
                        {currencies.map((currency, key) => <option key={key}
                                                                   value={currency}>{i18n.t(currency)}</option>)}
                      </select>
                    </div>
                  </div> :

                  <div className="row">
                    <div className="col-6 pr-1">
                      <input type="number" data-field="amount" name="price" value={fields.pricing.amount}
                             onChange={updatePricing}
                             className="form-control" placeholder={i18n.t("Price")} required/>
                    </div>
                    <div className="col-3 px-0">
                      <select className="custom-select" data-field="currency" name="currency"
                              value={fields.pricing.currency} onChange={updatePricing} required>
                        <option value="" disabled>{i18n.t("Currency")}</option>
                        {currencies.map((currency, key) => <option key={key}
                                                                   value={currency}>{i18n.t(currency)}</option>)}
                      </select>
                    </div>
                    <div className="col-3 pl-1">
                      <select className="custom-select" data-field="rate_unit" name="price_rate_unit"
                              value={fields.pricing.rate_unit} onChange={updatePricing} required>
                        <option value="" disabled>/{i18n.t("Term")}</option>
                        {terms.map((term, key) => <option key={key} value={term}>/ {term}</option>)}
                      </select>
                    </div>
                  </div>
                }
                {
                  (fields.available_for === "sale" || fields.available_for === "rent") ?
                    <div className="row">
                      <div className="col-12 pr-1 mt-3">
                        <input type="number" data-field="agent_fee" name="agent_fee" value={fields.pricing.agent_fee}
                               onChange={updatePricing}
                               className="form-control" placeholder={i18n.t("Agent Fee")} required/>
                      </div>
                    </div>
                    :
                    null
                }

                <div className="row p-0 m-0 mt-2">
                  <label className="form-check-label col-12 p-0 m-0">{i18n.t("Payment Terms")}</label>
                  <div className="col-12 p-0 m-0">
                    <textarea type="text" data-field="payment_terms" name="payment_terms"
                              value={fields.pricing.payment_terms} onChange={updatePricing} rows="2"
                              className="form-control"
                              placeholder={i18n.t("Example: 6 months in advance.")}/>
                  </div>
                </div>
              </div>
            </div>
            : null
          }

          {!fields.parent_property ?
            <div className="standard-form-field my-3 map">
              <label className="form-check-label col-12 p-0 m-0">{i18n.t("Location")}</label>
              <Map editable search markerDraggable
                   options={{ gestureHandling: "greedy" }}
                   onChangeLocation={updateLocation}
                   location={{
                     address: fields.location.address,
                     point: { lng: fields.location.longitude, lat: fields.location.latitude }
                   }}/>
            </div>
            : null
          }

          {child.otherInputs ?
            child.otherInputs(fields, updateFields) : null
          }

          <div className="standard-form-field my-4">
            <label className="form-check-label col-12 p-0 m-0">{i18n.t("Amenities")}</label>
            <div className="row mt-1 mb-3">
              <div className="col-12">
                <AsyncSelect closeMenuOnSelect={false} isMulti cacheOptions
                             defaultOptions value={selectionFields.amenities}
                             components={animatedComponents}
                             loadOptions={get("amenities")} onChange={update("amenities")}/>
              </div>
            </div>

            <label className="form-check-label col-12 p-0 m-0">{i18n.t("Nearby Services")}</label>
            <div className="row mt-1 mb-3">
              <div className="col-12">
                <AsyncSelect closeMenuOnSelect={false} isMulti cacheOptions
                             defaultOptions value={selectionFields.services}
                             components={animatedComponents}
                             loadOptions={get("services")} onChange={update("services")}/>
              </div>
            </div>
          </div>

          <div className="standard-form-field row p-0 m-0 my-4">
            <FeaturesInput label="Add Other Features" onChange={updateFeatures}
                           onDelete={markFeatureToDelete} value={fields.other_features}/>
          </div>

          <label className="form-check-label col-12 p-0 m-0">{i18n.t("Description")}</label>
          <div className="standard-form-field editor-container">
            <CKEditor
              editor={ClassicEditor}
              data={fields.description}
              onInit={editor => {
                // You can store the "editor" and use when it is needed.
                console.log("Editor is ready to use!", editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                updateFields(fields => {
                  fields["description"] = data;
                });
              }}
              onBlur={(event, editor) => {
                console.log("Blur.", editor);
              }}
              onFocus={(event, editor) => {
                console.log("Focus.", editor);
              }}
            />
          </div>

          {!fields.parent_property ?
            <div className="standard-form-field row p-0 m-0 mt-4">
              <label className="form-check-label col-12 p-0 m-0">{i18n.t("Contact")}</label>
              <div className="col-12 my-1 px-0">
                <input type="text" data-field="phone" name="phone" value={fields.contact.phone} onChange={updateContact}
                       className="form-control" placeholder={i18n.t("Phone Number")}/>
              </div>
              <div className="col-12 my-1">
                <div className="row">
                  <div className="col m-0 p-0 pr-1">
                    <input type="text" data-field="name" name="full_name" value={fields.contact.name}
                           onChange={updateContact}
                           className="form-control" placeholder={i18n.t("Name")}/>
                  </div>
                  <div className="col m-0 p-0 pl-1">
                    <input type="text" data-field="email" name="email" value={fields.contact.email}
                           onChange={updateContact}
                           className="form-control" placeholder={i18n.t("Email")}/>
                  </div>
                </div>
              </div>
            </div>
            : null
          }
        </div>

        <div className="col-12 col-md-5 mt-4 mt-md-0">
          <div className="prop-pics-card sticky-top m-0 mb-lg-1">
            <label className="form-check-label col-12 p-0 m-0 mb-1">{i18n.t("Main Picture")}</label>
            <ImageUploader name="main_picture" src={fields.pictures.filter(img => img.is_main)}
                           onChange={updateMainImage} onDelete={markImgToDelete}/>
            <hr className="line p-0 m-0 my-3"/>

            <label className="form-check-label col-12 p-0 m-0 my-1">{i18n.t("Other Pictures")}</label>
            <MultipleImageUploader name="other_pictures" src={fields.pictures.filter(img => !img.is_main)}
                                   onChange={updateOtherImages} onDelete={markImgToDelete}/>
          </div>
        </div>
      </div>

      <hr className="line p-0 m-0 my-3"/>

      <div className="standard-form-field row p-0 m-0 justify-content-center py-2 mt-4">
        <div className="col-12 mb-2 text-center text-danger">
          {editError}
        </div>
        <Button className="col-12 col-md-6" variant="primary"
                disabled={isLoading} type="submit">
          {isLoading ? <Spinner animation="border" size="sm"/> : "Save"}
        </Button>
      </div>
    </form>
  );
}


function EditGenericProperty(props) {
  const [user] = store.useState("user");

  const headers = {
    "Content-Type": "application/json"
  };

  if (user.isLoggedIn) {
    headers["Authorization"] = `Token ${user.auth_token}`;
  }

  let fetchProperty = () => {
    return fetch(`${BASE_API_URL}/${getPropertyRoute(props.type)}/${props.id}/`, { headers: headers })
      .then(res => res.json().then(data => ({ statusCode: res.status, data })));
  };

  return (
    <DataFetcher
      selection={`property/${props.id}`}
      action={fetchProperty}
      placeholder={<BouncePageLoader/>}
      onError={renderPageError}>
      {response => {
        if (response.data.statusCode === 404) {
          return <NotFoundError/>;
        }
        const property = response.data.data;
        return (
          <EditFetchedProperty property={property} {...props}>
            {props.children}
          </EditFetchedProperty>
        );
      }}
    </DataFetcher>
  );
}


function EditRoom(props) {
  return (
    <EditGenericProperty {...props}>
      {property => ({})}
    </EditGenericProperty>
  );
}

function EditHouse(props) {
  return (
    <EditGenericProperty {...props}>
      {property => ({})}
    </EditGenericProperty>
  );
}

function EditApartment(props) {
  return (
    <EditGenericProperty {...props}>
      {property => ({})}
    </EditGenericProperty>
  );
}


function EditOffice(props) {
  return (
    <EditGenericProperty {...props}>
      {property => ({})}
    </EditGenericProperty>
  );
}

function EditLand(props) {
  return (
    <EditGenericProperty {...props}>
      {property => ({
        otherInputs: function(fields, updateFields) {
          let updateValue = (e) => {
            updateFields(fields => {
              let field = e.target.getAttribute("data-field");
              let value = e.target.value;
              fields[field] = value;
              fields.otherInputs[field] = value;
            });
          };

          let isRegistered = () => {
            if (fields.is_registered === "Y") {
              return true;
            }
            return false;
          };

          let updateIsRegistered = (e) => {
            let value = e.target.checked;
            if (value) {
              updateFields(fields => {
                fields["is_registered"] = "Y";
                fields.otherInputs["is_registered"] = "Y";
              });
            } else {
              updateFields(fields => {
                fields["is_registered"] = "N";
                fields.otherInputs["is_registered"] = "N";
              });
            }

          };

          return (
            <>
              <div className="standard-form-field row p-0 m-0 my-2 my-lg-2">
                <div className="col m-0 p-0 pr-1">
                  <label className="form-check-label col-12 p-0 m-0">Length</label>
                  <div className="col-12 p-0 m-0 my-1">
                    <input type="text" data-field="length" name="length" value={fields.length} onChange={updateValue}
                           className="form-control" placeholder="E.g 120 meters"/>
                  </div>
                </div>

                <div className="col m-0 p-0 pl-1">
                  <label className="form-check-label col-12 p-0 m-0">Width</label>
                  <div className="col-12 p-0 m-0 my-1">
                    <input type="text" data-field="width" name="width" value={fields.width} onChange={updateValue}
                           className="form-control" placeholder="E.g 45 meters"/>
                  </div>
                </div>
              </div>

              <div className="standard-form-field row p-0 m-0 my-2 my-lg-1">
                <label className="form-check-label col-12 p-0 m-0">Area</label>
                <div className="col-12 p-0 m-0 my-1">
                  <input type="text" data-field="area" name="area" value={fields.area} onChange={updateValue}
                         className="form-control" placeholder="E.g 500 square meters"/>
                </div>
              </div>
              <div className="row p-0 m-0 my-2 my-lg-2">
                <label className="form-check-label mt-2" for="is-registered">Is registered</label>
                <input type="checkbox" className="form-check-input p-0 m-0" id="is-registered" checked={isRegistered()}
                       onChange={updateIsRegistered}/>
              </div>
            </>
          );
        }
      })}
    </EditGenericProperty>
  );
}


function EditHotel(props) {
  return (
    <EditGenericProperty {...props}>
      {property => ({})}
    </EditGenericProperty>
  );
}

function EditVenue(props) {
  return (
    <EditGenericProperty {...props}>
      {property => ({})}
    </EditGenericProperty>
  );
}

function EditLodge(props) {
  return (
    <EditGenericProperty {...props}>
      {property => ({})}
    </EditGenericProperty>
  );
}

function EditGuestHouse(props) {
  return (
    <EditGenericProperty {...props}>
      {property => ({})}
    </EditGenericProperty>
  );
}


const properties = {
  "room": EditRoom,
  "house": EditHouse,
  "apartment": EditApartment,
  "office": EditOffice,
  "land": EditLand,
  "venue": EditVenue,
  "hotel": EditHotel,
  "hotel_room": EditRoom,
  "hotel_venue": EditVenue,
  "hotel_apartment": EditApartment,
  "lodge": EditLodge,
  "lodge_room": EditRoom,
  "guest_house": EditGuestHouse,
  "guest_house_room": EditRoom
};

function EditProperty(props) {
  const id = props.id;
  const type = props.type;
  const EditPropertyComponent = properties[type];
  return <EditPropertyComponent id={id} type={type}/>;
}

export { EditProperty };
