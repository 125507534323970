import React, { useState } from 'react';
import './PaymentSettings.scss';
import { useHistory, MemoryRouter, Route } from 'react-router';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Spinner, Modal } from 'react-bootstrap';
import {
    DataFetcher, BouncePageLoader, renderPageError
} from './';
import { BASE_API_URL } from '../';
import { store } from '../store';
import {
    capitalizeFirst, importAll, thousandsSeparator, buildMessageFromResponse
} from '../utils';
import { LogInToViewSaved } from './LogInToViewSaved';
import { queryCache } from 'react-query';
import { toast } from 'react-toastify';
import { useLocalState } from '../hooks';
import { GenericFilter } from './Filters';
import { BounceBlockLoader } from './Loaders';
import { NoResultsFound } from './NoResultsFound';


const images = importAll(
    require.context("../images/payment-providers-imgs", false, /\.(png|jpe?g|svg)$/)
);


function getWallet(code) {
    const wallets = {
        "VMCASHIN": { name: "mpesa", payment: "M-Pesa", label: "Vodacom" },
        "TPCASHIN": { name: "tigopesa", payment: "Tigo Pesa", label: "Tigo" },
        "AMCASHIN": { name: "airtelmoney", payment: "Airtel Money", label: "Airtel" },
        "HPCASHIN": { name: "halopesa", payment: "Halo Pesa", label: "Halotel" },
        "TTCASHIN": { name: "tpesa", payment: "T-Pesa", label: "TTCL" },
        "EZCASHIN": { name: "ezypesa", payment: "Ezy Pesa", label: "Zantel" },
        "SPCASHIN": { name: "selcom", payment: "Selcom Card", label: "Selcom" }
    }

    return wallets[code];
}


function AmountPanel(props) {
    const history = useHistory();
    const [value, setValue] = useState(props.withdrawInfo.amount)

    const goBack = () => {
        props.setModalShow(false);
    }

    const goNext = () => {
        if (value.match(/\d/g) && value > 0) {
            if (value <= props.balance) {
                props.updateWithdrawInfo(withdrawInfo => {
                    withdrawInfo.amount = value;
                })
                history.push("/provider")
            }
            else {
                toast.error(`You do not have enough balance, your balance is TZS ${thousandsSeparator(props.balance)}`)
            }
        }
        else {
            toast.error("Enter a valid amount")
        }
    }

    return (
        <div className="amount-panel row p-0 m-0">
            <div className="header row p-0 m-0 w-100">
                <div className="back-btn" onClick={goBack}>
                    <span className="icon icon-up-arrow"></span>
                </div>
                <span className="currency">TZS</span>
                <input value={value} onChange={e => setValue(e.target.value)}
                    autoComplete="off" autoFocus name="search" type="number"
                    placeholder="Enter amount" className="amount-input col-12" />
            </div>

            <div className="row p-0 m-0 w-100 mt-5 justify-content-end">
                <button className="next-btn btn btn-primary mr-3" onClick={goNext}>
                    <span className="label">Next</span>
                    <span className="icon icon-right-arrow" />
                </button>
            </div>
        </div>
    );
}


function ProviderSelection(props) {
    const history = useHistory();

    const goBack = () => {
        history.goBack()
    }

    const selectProvider = (code) => {
        props.updateWithdrawInfo(withdrawInfo => {
            withdrawInfo.wallet_code = code;
        })
        history.push("/number")
    }

    return (
        <div className="provider-selection row p-0 m-0">
            <div className="header row p-0 m-0">
                <div className="back-btn" onClick={goBack}>
                    <span className="icon icon-up-arrow"></span>
                </div>
                <span className="title">Select payment method</span>
            </div>

            <div className="providers row p-0 m-0 px-2 mt-2 w-100">
                {[
                    { name: "mpesa", code: "VMCASHIN", payment: "M-Pesa", label: "Vodacom" },
                    { name: "tigopesa", code: "TPCASHIN", payment: "Tigo Pesa", label: "Tigo" },
                    { name: "airtelmoney", code: "AMCASHIN", payment: "Airtel Money", label: "Airtel" },
                    { name: "halopesa", code: "HPCASHIN", payment: "Halo Pesa", label: "Halotel" },
                    { name: "tpesa", code: "TTCASHIN", payment: "T-Pesa", label: "TTCL" },
                    { name: "ezypesa", code: "EZCASHIN", payment: "Ezy Pesa", label: "Zantel" }
                ].map(provider =>
                    <div className="provider row p-0 m-0 w-100"
                        onClick={e => selectProvider(provider.code)}>
                        <div className="col m-0 p-0 provider-logo">
                            <img src={images[provider.name]} />
                        </div>
                        <div className="col p-0 m-0 text-right ">
                            <div className="provider-payment col-12 p-0 m-0">
                                {provider.payment}
                            </div>
                            <div className="provider-name col-12 p-0 m-0">
                                {provider.label}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}


function NumberPanel(props) {
    const history = useHistory();
    const [value, setValue] = useState(props.withdrawInfo.wallet_number)

    const goBack = () => {
        history.goBack()
    }

    const goNext = () => {
        if (value.match(/\d/g) && value.length === 10) {
            props.updateWithdrawInfo(withdrawInfo => {
                withdrawInfo.wallet_number = value;
            })
            history.push("/password")
        }
        else {
            toast.error("Enter a valid phone number with 10 digits")
        }
    }

    return (
        <div className="number-panel row p-0 m-0">
            <div className="header row p-0 m-0 w-100">
                <div className="back-btn" onClick={goBack}>
                    <span className="icon icon-up-arrow"></span>
                </div>
                <input value={value} onChange={e => setValue(e.target.value)}
                    autoComplete="off" autoFocus name="phone" type="number"
                    placeholder="Enter phone number" className="number-input col-12" />
            </div>

            <div className="row p-0 m-0 w-100 mt-5 justify-content-end">
                <button className="next-btn btn btn-primary mr-3" onClick={goNext}>
                    <span className="label">Next</span>
                    <span className="icon icon-right-arrow" />
                </button>
            </div>
        </div>
    );
}


function PasswordPanel(props) {
    const [user, ,] = store.useState("user");
    const history = useHistory();
    const [value, setValue] = useState("")
    const [loading, setLoading] = useState(false);

    const goBack = () => {
        //props.setModalShow(false);
        history.goBack()
    }

    const withdraw = (e) => {
        if (value.length === 0) {
            toast.error("Enter your password")
            return
        }

        setLoading(true);

        const formdata = {
            "password": value,
            "amount": props.withdrawInfo.amount,
            "wallet_code": props.withdrawInfo.wallet_code,
            "wallet_number": props.withdrawInfo.wallet_number
        }

        const url = `${BASE_API_URL}/create-selcom-withdraw/`
        const headers = {
            'Authorization': `Token ${user.auth_token}`,
            'Content-Type': 'application/json'
        }

        fetch(url, { method: 'POST', body: JSON.stringify(formdata), headers: headers })
            .then(res => res.json().then(data => ({ statusCode: res.status, data })))
            .then(res => {
                if (res.statusCode === 200) {
                    queryCache.invalidateQueries(`account/${user.account}`);
                    queryCache.invalidateQueries(`transactions`);
                    history.push("/done");
                }
                else if (res.statusCode === 400 || res.statusCode == 503) {
                    toast.error(
                        buildMessageFromResponse(res.data)
                    )
                }
            })
            .catch(error => {
                toast.error("No network connection, please try again!.")
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return (
        <div className="password-panel row p-0 m-0">
            <div className="header row p-0 m-0 w-100">
                <div className="back-btn" onClick={goBack}>
                    <span className="icon icon-up-arrow"></span>
                    <span className="title">Enter password to confirm</span>
                </div>
            </div>

            <div className="tx-info row p-0 m-0 w-100 px-4">
                <div className="col-12 p-0 m-0">
                    Send: &nbsp;&nbsp; TZS &nbsp; {thousandsSeparator(props.withdrawInfo.amount)}
                </div>
                <div className="col-12 p-0 m-0">
                    To: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {props.withdrawInfo.wallet_number}
                    ({getWallet(props.withdrawInfo.wallet_code).payment})
                </div>
            </div>

            <div className="row p-0 m-0 w-100 px-4">
                <input value={value} onChange={e => setValue(e.target.value)}
                    autoComplete="off" autoFocus name="password" type="password"
                    placeholder="Enter your password" className="password-input col-12" />
            </div>

            <div className="row p-0 m-0 w-100 mt-5 justify-content-end">
                <button className="next-btn btn btn-primary mr-3" onClick={withdraw} disabled={loading}>
                    <span className="label">Send</span>
                    {loading ?
                        <Spinner className="spinner" animation="border" size="sm" /> :
                        <span className="icon icon-right-arrow" />
                    }
                </button>
            </div>
        </div>
    );
}


function WithdrawDone(props) {
    const exit = (e) => {
        props.updateWithdrawInfo((info) => {
            return {
                amount: "",
                wallet_code: "",
                wallet_number: "",
            }
        })

        props.setModalShow(false);
    }
    return (
        <div className="row withdraw-done p-0 m-0 mt-5">
            <div className="row w-100 m-0 p-0" >
                <div className="col-12 col-lg-6 p-0 m-0 mt-5 text-center">
                    <div className="verification-badge">
                        <span className="icon icon-verified" />
                    </div>

                    <div className="tx-info row p-0 m-0 w-100 px-4">
                        <div className="col-12 p-0 m-0">
                            Yo have successfully sent TZS &nbsp;
                            {thousandsSeparator(props.withdrawInfo.amount)} &nbsp;
                            to {props.withdrawInfo.wallet_number}
                            ({getWallet(props.withdrawInfo.wallet_code).payment})
                        </div>
                    </div>
                </div>

                <div className="col-12 w-100" />

                <div className="col-12 col-lg-6 p-0 m-0 mt-5 text-center">
                    <button className="exit-btn btn btn-primary" onClick={exit}>
                        Done
                    </button>
                </div>
            </div>
        </div>
    );
}


function Transaction(props) {
    const tx = props.transaction;
    return (
        <div className="transaction-overview col-12">
            <div className="row p-0 m-0">
                <div className="left col-6 p-0 m-0">
                    <div className="row p-0 m-0">
                        <div className="">
                            <div className={`pic-label ${tx.type}`}>
                                <span className="icon icon-right-arrow" />
                            </div>
                        </div>
                        <div className="col">
                            <div className="row p-0 m-0">
                                <div className="action-type col-12 p-0 m-0">
                                    {capitalizeFirst(tx.type)}
                                </div>
                                <div className="status col-12 p-0 m-0">
                                    {capitalizeFirst(tx.status)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right col-6 p-0 m-0 text-right">
                    <div className="row p-0 m-0">
                        <div className={`amount col-12 p-0 m-0 ${tx.type}`}>
                            TZS &nbsp; {tx.type === "withdraw" ? "-" : ""}
                            {thousandsSeparator(tx.amount)}
                        </div>
                        <div className="datetime col-12 p-0 m-0">
                            {moment(tx.create_date).format("DD MMM, YYYY")}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


function TransactionHistory(props) {
    const selection = `transactions`;
    const endpoint = `transactions/`;
    const notFoundMsg = `You do not have any transaction at the moment.`

    return (
        <GenericFilter placeholder={<BounceBlockLoader className="mt-5" />}
            onError={renderPageError} selection={selection} endpoint={endpoint}>
            {(response) => {
                if (response.data[0].count === 0) {
                    return (
                        <div className="no-results">
                            <NoResultsFound message={notFoundMsg} />
                        </div>
                    );
                }

                return response.data[0].results.slice(0, 5).map(transaction =>
                    <Transaction transaction={transaction} />
                )
            }}
        </GenericFilter>
    );
}


function PaymentSettings(props) {
    const account = props.account;
    const history = useHistory()
    const [modalShow, setModalShow] = useState(false);
    const [withdrawInfo, , updateWithdrawInfo] = useLocalState({
        amount: "",
        wallet_code: "",
        wallet_number: ""
    })

    return (
        <div className="payment-settings pt-4">
            <div className="row account-details p-0 m-0 text-center pt-2">
                <div className="label col-12">Total Balance</div>
                <div className="balance col-12">
                    TZS &nbsp; {thousandsSeparator(account.balance)}
                </div>
            </div>

            <div className="row account-actions p-0 m-0 justify-content-center ">
                <button className="withdraw mt-4 btn btn-outline-secondary" onClick={e => setModalShow(true)}>
                    <span className="icon icon-up-arrow" /> <span className="label">Withdraw</span>
                </button>
                <Modal animation={false} className="search-modal"
                    dialogClassName="custom-modal-dialog" show={modalShow}
                    onHide={() => setModalShow(false)} size="lg"
                    aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Body className="p-0 m-0 withdraw-modal-body">
                        <div className="container-fluid withdraw-modal">
                            <MemoryRouter initialEntries={["/amount", "/provider", "/number", "/password", { pathname: "/" }]} initialIndex={0}>
                                <Route exact path="/amount" render={() => {
                                    return <AmountPanel
                                        showModal={modalShow} setModalShow={setModalShow} balance={account.balance}
                                        withdrawInfo={withdrawInfo} updateWithdrawInfo={updateWithdrawInfo} />
                                }} />
                                <Route exact path="/provider" render={() => {
                                    return <ProviderSelection
                                        showModal={modalShow} setModalShow={setModalShow}
                                        withdrawInfo={withdrawInfo} updateWithdrawInfo={updateWithdrawInfo} />
                                }} />
                                <Route exact path="/number" render={() => {
                                    return <NumberPanel
                                        withdrawInfo={withdrawInfo} updateWithdrawInfo={updateWithdrawInfo}
                                        history={history} showModal={modalShow} setModalShow={setModalShow} />
                                }} />
                                <Route exact path="/password" render={() => {
                                    return <PasswordPanel
                                        withdrawInfo={withdrawInfo} updateWithdrawInfo={updateWithdrawInfo}
                                        history={history} showModal={modalShow} setModalShow={setModalShow} />
                                }} />
                                <Route exact path="/done" render={() => {
                                    return <WithdrawDone
                                        withdrawInfo={withdrawInfo} updateWithdrawInfo={updateWithdrawInfo}
                                        history={history} showModal={modalShow} setModalShow={setModalShow} />
                                }} />
                            </MemoryRouter>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>

            <div className="row transaction-history p-0 m-0 px-3 px-lg-5 mt-4 pt-3 ">
                <div className="header row col-12 p-0 m-0 px-1">
                    <div className="col p-0 m-0 left">History</div>
                    <div className="col p-0 m-0 text-right right">
                        <Link to="/transactions">View All</Link>
                    </div>
                </div>
                <TransactionHistory />
            </div>
        </div>
    );
}

function AccountFetcher(props) {
    const [user, ,] = store.useState("user");
    const headers = {
        'Authorization': `Token ${user.auth_token}`,
        'Content-Type': 'application/json'
    }

    const fetchAccount = () => {
        return fetch(`${BASE_API_URL}/accounts/${user.account}/`, { method: 'GET', headers: headers })
            .then(res => res.json().then(data => ({ statusCode: res.status, data })))
    }

    if (!user.isLoggedIn) {
        const message = "Yo need to be logged in to access this page."
        return <LogInToViewSaved message={message} />
    }
    else if (!user.is_host) {
        const message = "Yo need to be a host to access this page."
        return <LogInToViewSaved message={message} />
    }

    return (
        <DataFetcher
            selection={`account/${user.account}`}
            action={fetchAccount}
            placeholder={<BouncePageLoader />}
            onError={renderPageError}>
            {response => {
                const account = response.data.data;
                return <PaymentSettings account={account} />
            }}
        </DataFetcher>
    );
}


export { AccountFetcher as PaymentSettings, Transaction }


