import React, {useState} from 'react';
import moment from 'moment'
import { queryCache } from 'react-query';
import { store } from '../store';
import { useLocalState } from '../hooks';
import { BASE_API_URL } from '../';
import {
    DataFetcher, BouncePageLoader, renderPageError, NotFoundError,
    InfoModal
} from './';
import './TourDetails.scss';
import { Spinner, Button } from 'react-bootstrap';
import { buildMessageFromResponse, getPropertyRoute, thousandsSeparator } from '../utils';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { PaymentMethods } from './PaymentMethods';
import { Link } from 'react-router-dom';


function TourDetails(props) {
    const tour = props.tour;
    const history = useHistory();
    const [user,] = store.useState("user");
    const [loading, , updateLoading] = useLocalState({
        uncancel: false, cancel: false, pay: false
    })
    const [modalShow, setModalShow] = useState(false);
    const [payerPhone, setPayerPhone] = useState(
        tour.payment && tour.payment.payer_phone?
        tour.payment.payer_phone:
        user.phone
    )

    let tourStatus = null;
    if (tour.status === "pending" && !tour.is_confirmed) {
        tourStatus = "toBeConfirmed"
    }
    else if (tour.status === "pending" && tour.is_confirmed) {
        tourStatus = "toBePaid"
    }
    else if (tour.status === "paid" && tour.is_confirmed) {
        tourStatus = "paid"
    }
    else if (tour.status === "canceled" && tour.is_confirmed) {
        tourStatus = "agentCanceled"
    }
    else if (tour.status === "canceled" && !tour.is_confirmed) {
        tourStatus = "clientCanceled"
    }

    const uncancel = (event) => {
        event.preventDefault();
        updateLoading(loading => {
            loading.uncancel = true
        });

        const formdata = {
            "tour": tour.id
        }

        const uncancelUrl = `${BASE_API_URL}/uncancel-tour/`
        const headers = {
            'Authorization': `Token ${user.auth_token}`,
            'Content-Type': 'application/json'
        }

        fetch(uncancelUrl, { method: 'POST', body: JSON.stringify(formdata), headers: headers })
            .then(res => res.json().then(data => ({ statusCode: res.status, data })))
            .then(res => {
                if (res.statusCode === 200) {
                    queryCache.invalidateQueries(`tour/${tour.id}`);
                }
                else if (res.statusCode === 400) {
                    toast.error(
                        buildMessageFromResponse(res.data)
                    )
                }
            })
            .catch(error => {
                toast.error("No network connection, please try again!.")
            })
            .finally(() => {
                updateLoading(loading => {
                    loading.uncancel = false
                });
            })
    }

    const cancel = (event) => {
        event.preventDefault();
        updateLoading(loading => {
            loading.cancel = true
        });

        const formdata = {
            "tour": tour.id,
            "reason": "--"
        }

        let uncancelUrl = `${BASE_API_URL}/cancel-tour/`
        let headers = {
            'Authorization': `Token ${user.auth_token}`,
            'Content-Type': 'application/json'
        }

        fetch(uncancelUrl, { method: 'POST', body: JSON.stringify(formdata), headers: headers })
            .then(res => res.json()
            .then(data => ({ statusCode: res.status, data })))
            .then(res => {
                if (res.statusCode === 200) {
                    queryCache.invalidateQueries(`tour/${tour.id}`);
                }
                else if (res.statusCode === 400) {
                    toast.error(
                        buildMessageFromResponse(res.data)
                    )
                }
            })
            .catch(error => {
                toast.error("No network connection, please try again!.")
            })
            .finally(() => {
                updateLoading(loading => {
                    loading.cancel = false
                });
            })
    }

    const pay = (e) => {
        if (!(payerPhone.match(/\d/g) && payerPhone.length === 10)) {
            toast.error("Enter a valid phone number with 10 digits");
            return
        }

        updateLoading(loading => {
            loading.pay = true
        });

        const formdata = {
            "tour": tour.id,
            "payer_phone": payerPhone
        }

        const url = `${BASE_API_URL}/create-tour-selcom-payment/`
        const headers = {
            'Authorization': `Token ${user.auth_token}`,
            'Content-Type': 'application/json'
        }

        fetch(url, { method: 'POST', body: JSON.stringify(formdata), headers: headers })
            .then(res => res.json().then(data => ({ statusCode: res.status, data })))
            .then(res => {
                if (res.statusCode === 200) {
                    queryCache.invalidateQueries(`tour/${tour.id}`);
                    setModalShow(false);
                }
                else if (res.statusCode === 400 || res.statusCode == 503) {
                    toast.error(
                        buildMessageFromResponse(res.data)
                    )
                }
            })
            .catch(error => {
                toast.error("No network connection, please try again!.")
            })
            .finally(() => {
                updateLoading(loading => {
                    loading.pay = false
                });
            })
    }

    const openChat = (e) => {
        history.push(`/conversations/${tour.property.owner.id}/`)
    }

    const getMainPicture = (property) => {
        let main_img = {is_main: null, src: null, id: null}
        main_img = property.pictures.filter((picture) => picture.is_main);
        if (main_img.length > 0) {
            main_img = main_img[0];
        }
        return main_img
    }

    return (
        <div className="tour-details row p-0 m-0 pt-4">
            <div className="cost-breakdown col-12 col-lg-6 p-0 m-0 px-4 ">
                <div className="head">
                    Property
                </div>
                <div className="row p-0 m-0 related-property align-items-center justify-content-center">
                    <div className="location col-6 p-0 m-0">
                        <div><i className="icon icon-marker"></i></div> &nbsp;
                        <div>{tour.property.location.address}</div>
                    </div>
                    <div className="picture col-6 p-0 m-0 text-right">
                        <Link to={`/${getPropertyRoute(tour.property.type)}/${tour.property.id}/`}>
                            <img src={getMainPicture(tour.property).src} />
                        </Link>
                    </div>
                </div>
                <div className="row p-0 m-0 dates-info align-items-center">
                    <div className="col-6 p-0 m-0">Tour Date</div>
                    <div className="col-6 p-0 m-0 value text-right">
                        {moment(tour.date).format("DD MMM YYYY")}
                    </div>
                </div>
                <div className="row p-0 m-0 total-cost align-items-center">
                    <div className="col p-0 m-0 text-left">Tour Cost</div>
                    <div className="col p-0 m-0 text-right value">
                        {`${tour.currency} ${thousandsSeparator(tour.cost)}`}
                    </div>
                </div>
            </div>

            <div className="payments col-12 col-lg-6 p-0 m-0 px-4 mt-5 mt-lg-0 ">
                <div className="head">
                    Payments
                </div>
                <div className="details">
                    {tourStatus === "toBeConfirmed" ?
                        <>
                            Waiting for your tour to be confirmed by the agent,
                            we'll let you know when it's done, for you to proceed
                            with the payment.
                        </> : null
                    }
                    {tourStatus === "toBePaid" ?
                        <>
                            {tour.payment && tour.payment.status === "pending" ?
                                <PaymentMethods payment={tour.payment} /> :
                                <>This tour is confirmed, you can now continue with the payment.</>
                            }
                        </> : null
                    }
                    {tourStatus === "paid" ?
                        <>
                            You have already paid for this tour, you can now meet with the
                            agent to proceed with the process of viewing a property.
                        </> : null
                    }
                    {tourStatus === "agentCanceled" ?
                        <>
                            Your agent has declined this tour.
                        </> : null
                    }
                    {tourStatus === "clientCanceled" ?
                        <>
                            You canceled this tour.
                        </> : null
                    }
                </div>
            </div>

            {tourStatus === "agentCanceled" ? null :
                <div className="tour-actions row p-0 m-0">
                    {tourStatus === "toBeConfirmed" ?
                        <>
                            <div className="col-12 text-center">
                                <div className="cancel btn col-12 col-lg-6" onClick={cancel} disabled={loading.cancel}>
                                    {loading.cancel ? <Spinner animation="border" size="sm" /> : 'Cancel Tour'}
                                </div>
                            </div>
                        </> : null
                    }
                    {tourStatus === "toBePaid" ?
                        <>
                            <div className="col text-center">
                                <div className="cancel btn col-12 col-lg-6" onClick={cancel} disabled={loading.cancel}>
                                    {loading.cancel ? <Spinner animation="border" size="sm" /> : 'Cancel Tour'}
                                </div>
                            </div>
                            {tour.payment && tour.payment.status === "pending" ?
                                null :
                                <div className="col text-center">
                                    <div className="pay btn col-12 col-lg-6" onClick={(e) => setModalShow(true)}>
                                        Pay Now
                                    </div>

                                    <InfoModal className="payments" positionBottom header="Confirm your number" modalShow={modalShow} setModalShow={setModalShow}>
                                        <div className="payment-form row pt-5 pb-3 justify-content-center">
                                            <div className="col-10 px-2 floating">
                                                <input value={payerPhone} onChange={ e => setPayerPhone(e.target.value)}
                                                    type="number" name="phone" className="form-control floating__input"
                                                    placeholder="Phone" required/>
                                                <label htmlFor="phone" className="floating__label" data-content="Phone"></label>
                                            </div>
                                            <div className="col-10 px-2">
                                                <Button className="pay-btn col-12 mt-5" variant="primary" disabled={loading.pay} onClick={pay}>
                                                    {loading.pay ? <Spinner animation="border" size="sm" /> : 'Continue'}
                                                </Button>
                                            </div>
                                        </div>
                                    </InfoModal>
                                </div>
                            }
                        </> : null
                    }
                    {tourStatus === "paid" ?
                        <>
                            <div className="col-12 text-center">
                                <div className="send-message btn col-12 col-lg-6" onClick={openChat}>
                                    {loading.cancel ? <Spinner animation="border" size="sm" /> : 'Send Message To Agent'}
                                </div>
                            </div>
                        </> : null
                    }
                    {tourStatus === "clientCanceled" ?
                        <>
                            <div className="col-12 text-center">
                                <div className="uncancel btn col-12 col-lg-6" onClick={uncancel} disabled={loading.uncancel}>
                                    {loading.uncancel ? <Spinner animation="border" size="sm" /> : 'Uncancel Tour'}
                                </div>
                            </div>
                        </> : null
                    }
                </div>
            }
        </div>
    );
}

function TourDetailsFetcher(props) {
    const [user,] = store.useState("user");

    const headers = {
        Authorization: `Token ${user.auth_token}`,
        "Content-Type": "application/json",
    }

    let fetchTour = () => {
        return fetch(`${BASE_API_URL}/tours/${props.id}/`, { headers: headers })
            .then(res => res.json().then(data => ({ statusCode: res.status, data })))
    }

    return (
        <DataFetcher action={fetchTour} selection={`tour/${props.id}`}
            placeholder={<BouncePageLoader />} onError={renderPageError}>{response => {
                if (response.data.statusCode === 404) {
                    return <NotFoundError />
                }
                return <TourDetails tour={response.data.data} />
            }}</DataFetcher>
    )
}


export { TourDetailsFetcher as TourDetails }
