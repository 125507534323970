import React from 'react';
import './NoResultsFound.scss';
import notFoundImage from '../images/undraw_not_found_leafs.svg';
import i18n from "i18next";


function NoResultsFound(props){
    const defaultMessage = "No results found for your search"

    return (
        <div className="no-results-found row p-0 m-0 justify-content-center">
            <div className="col-12 p-0 m-0 mt-5 d-flex justify-content-center">
                <img className="col-8 col-md-4" src={
                    props.image? props.image: notFoundImage
                }/>
            </div>

            <div className="not-found-message col-10 mt-2 justify-content-center">
                <hr className="p-0 m-0 my-2"/>
                <div className="p-0 m-0 px-2">
                {props.message ?
                    <>{i18n.t(props.message)}</>: i18n.t(defaultMessage)
                }
                </div>
            </div>
        </div>
    );
}

export { NoResultsFound };
