import React from 'react';
import { queryCache } from 'react-query';
import { useHistory } from 'react-router';
import { store } from '../store';
import { useLocalState } from '../hooks';
import { BASE_API_URL } from '../';
import {
    DataFetcher, BouncePageLoader, renderPageError, NotFoundError, PropertyDetails
} from './';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import './PublishRequestDetails.scss';


function PublishRequestDetails(props) {
    const publishRequest = props.publishRequest;
    const history = useHistory();
    const [user,] = store.useState("user");
    const [loading, , updateLoading] = useLocalState({
        accept: false, decline: false
    })

    const accept = (event) => {
        event.preventDefault();
        updateLoading(loading => {
            loading.accept = true
        });

        const formdata = {
            "publish_request": publishRequest.id
        }

        let acceptUrl = `${BASE_API_URL}/accept-publish-request/`
        let headers = {
            'Authorization': `Token ${user.auth_token}`,
            'Content-Type': 'application/json'
        }

        fetch(acceptUrl, { method: 'POST', body: JSON.stringify(formdata), headers: headers })
            .then(res => res.json().then(data => ({ statusCode: res.status, data })))
            .then(res => {
                if (res.statusCode === 200) {
                    queryCache.invalidateQueries(`publish-request/${publishRequest.id}`);
                    queryCache.invalidateQueries(`property/${publishRequest.property.id}`);
                }
                else if (res.statusCode === 400) {
                    toast.error(res.data.data.details)
                }
            })
            .catch(error => {
                toast.error("No network connection, please try again!.")
            })
            .finally(() => {
                updateLoading(loading => {
                    loading.accept = false
                });
            })
    }

    const decline = (event) => {
        event.preventDefault();
        updateLoading(loading => {
            loading.decline = true
        });

        const formdata = {
            "publish_request": publishRequest.id,
            "reason": "--"
        }

        let declineUrl = `${BASE_API_URL}/decline-publish-request/`
        let headers = {
            'Authorization': `Token ${user.auth_token}`,
            'Content-Type': 'application/json'
        }

        fetch(declineUrl, { method: 'POST', body: JSON.stringify(formdata), headers: headers })
            .then(res => res.json().then(data => ({ statusCode: res.status, data })))
            .then(res => {
                if (res.statusCode === 200) {
                    queryCache.invalidateQueries(`publish-request/${publishRequest.id}`);
                    queryCache.invalidateQueries(`property/${publishRequest.property.id}`);
                }
                else if (res.statusCode === 400) {
                    toast.error(res.data.data.details)
                }
            })
            .catch(error => {
                toast.error("No network connection, please try again!.")
            })
            .finally(() => {
                updateLoading(loading => {
                    loading.decline = false
                });
            })
    }

    return (
        <>
            <PropertyDetails id={publishRequest.property.id} type={publishRequest.property.type} />
            <div className="publish-request-details row p-0 m-0 pt-4">
                <div className="publish-request-actions row p-0 m-0">
                    {publishRequest.status === "pending" ?
                        <>
                            <div className="col text-center">
                                <div className="accept btn col-12 col-lg-6" onClick={accept} disabled={loading.accept}>
                                    {loading.accept ? <Spinner animation="border" size="sm" /> : 'Accept'}
                                </div>
                            </div>
                            <div className="col text-center">
                                <div className="decline btn col-12 col-lg-6" onClick={decline} disabled={loading.decline}>
                                    {loading.decline ? <Spinner animation="border" size="sm" /> : 'Decline'}
                                </div>
                            </div>
                        </> : null
                    }
                    {publishRequest.status === "accepted" ?
                        <>
                            <div className="col-12 text-center">
                                <div className="unpublish btn col-12 col-lg-6" onClick={decline} disabled={loading.decline}>
                                    {loading.decline ? <Spinner animation="border" size="sm" /> : 'Unpublish'}
                                </div>
                            </div>
                        </> : null
                    }
                    {publishRequest.status === "declined" ?
                        <>
                            <div className="col-12 text-center">
                                <div className="publish btn col-12 col-lg-6" onClick={accept} disabled={loading.accept}>
                                    {loading.accept ? <Spinner animation="border" size="sm" /> : 'Publish'}
                                </div>
                            </div>
                        </> : null
                    }
                </div>
            </div>
        </>
    );
}

function PublishRequestDetailsFetcher(props) {
    const [user,] = store.useState("user");

    const headers = {
        Authorization: `Token ${user.auth_token}`,
        "Content-Type": "application/json",
    }

    let fetchReservation = () => {
        return fetch(`${BASE_API_URL}/publish-requests/${props.id}/`, { headers: headers })
            .then(res => res.json().then(data => ({ statusCode: res.status, data })))
    }

    return (
        <DataFetcher action={fetchReservation} selection={`publish-request/${props.id}`}
            placeholder={<BouncePageLoader />} onError={renderPageError}>{response => {
                if (response.data.statusCode === 404) {
                    return <NotFoundError />
                }
                return <PublishRequestDetails publishRequest={response.data.data} />
            }}</DataFetcher>
    )
}


export { PublishRequestDetailsFetcher as PublishRequestDetails }
