import React from 'react';
import './UserProfile.scss';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import {
    DataFetcher, BouncePageLoader, renderPageError,
    BounceBlockLoader
} from './';
import { BASE_API_URL } from '../';
import { store } from '../store';

import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import "@reach/tabs/styles.css";
import { EndpointPropertiesFilter, GenericFilter, PROPERTIES_QUERY_PARAM } from './Filters';
import { NoResultsFound } from './NoResultsFound';
import { LogInToViewSaved } from './LogInToViewSaved';
import { GenericResourcesGroup } from './ResourcesGroups';
import { SavedSearchOverview } from './SavedSearchOverview';
import { parse } from 'query-string';


function UserProperties(props) {
    const [user,] = store.useState("user");
    const selection = `user-properties.${props.id}`;
    const endpoint = `properties/?${PROPERTIES_QUERY_PARAM}&owner=${props.id}`;
    const viewKey = "userPropertiesView";
    const notFoundMsg = user.id === props.id ?
        "You do not have any property at the moment.":
        "This user has no any property at the moment."

    return <EndpointPropertiesFilter
        restoreScrollOnMovingBackOnly
        loader={<BounceBlockLoader className="mt-5"/>}
        notFoundMsg={notFoundMsg}
        defaultView="grid"
        viewKey={viewKey}
        disableViewSwitch
        selection={selection} endpoint={endpoint} />;
}


function UserFavProperties(props) {
    const [user,] = store.useState("user");
    const selection = `my-fav-properties`;
    const endpoint = `my-fav-properties/?${PROPERTIES_QUERY_PARAM}`;
    const viewKey = "userFavPropertiesView";
    const notFoundMsg = "You do not have any saved property at the moment."

    return <EndpointPropertiesFilter
        restoreScrollOnMovingBackOnly
        loader={<BounceBlockLoader className="mt-5"/>}
        notFoundMsg={notFoundMsg}
        defaultView="grid"
        viewKey={viewKey}
        disableViewSwitch
        selection={selection} endpoint={endpoint} />;
}


function SavedSearches(props) {
    const selection = `saved-searches`;
    const endpoint = `saved-searches/`;
    const viewKey = "savedSearches";
    const notFoundMsg = `You haven't saved any search at the moment.`

    return (
        <>
            <GenericFilter placeholder={<BounceBlockLoader className="mt-5"/>}
                onError={renderPageError} selection={selection} endpoint={endpoint}>
                {(response) => {
                    return (
                        <div className="p-0 m-0 px-1 px-sm-3 mt-2 mt-md-3">
                            <GenericResourcesGroup
                                defaultView="list"
                                viewKey={viewKey}
                                response={response}
                                notFoundMsg={notFoundMsg}
                                disableViewSwitch
                                resourceclassName='col-sm-12 col-md-6 col-lg-6 px-1 px-sm-2 my-2'
                                restoreScrollOnMovingBackOnly
                                FetchMoreOnScrollToBottom>
                                {savedSearch =>
                                    <SavedSearchOverview savedSearch={savedSearch} />
                                }
                            </GenericResourcesGroup>
                        </div>
                    );
                }}
            </GenericFilter>
        </>
    );
}


function UserProfile(props){
    const history = useHistory();
    const parsed = parse(history.location.search);
    const [authUser,] = store.useState("user");
    const user = props.user;

    let defaultIndex = 0
    if(parsed.tab === "0"){
        defaultIndex = 0
    }
    else if(parsed.tab === "1"){
        defaultIndex = 1
    };

    const handleTabsChange = (index) => {
        history.replace(`${history.location.pathname}?tab=${index}`);
    }

    const comingSoonMsg = `
    We are currently working on this feature and will launch soon!. Stay tuned!.
    `
    return (
        <div className="user-profile mt-4">
            <div className="row p-0 m-0 justify-content-center">
                <div className="profile-picture">
                    {user.picture && user.picture.src ?
                        <img src={user.picture.src} alt="" /> :
                        <span className="icon icon-user" />
                    }
                </div>
            </div>

            <div className="row p-0 m-0 mt-2 justify-content-center">
                <div className="full-name">
                    <span>@{user.full_name} </span>
                    { user.is_verified ?
                        <span className="icon icon-verified" />: null
                    }
                </div>
            </div>

            {user.is_host && user.biography ?
                <div className="row p-0 m-0 px-3 mt-2 justify-content-center">
                    <div className="bio">{user.biography}</div>
                </div> : null
            }

            {authUser.id === user.id ?
                <div className="row p-0 m-0 mt-2 justify-content-center">
                    <div className="col settings text-right">
                        <Link to="/settings" className="btn btn-outline-secondary">
                            <span className="icon icon-gear" /> <span>Settings</span>
                        </Link>
                    </div>
                    <div className="col edit text-left">
                        <Link to="/edit-profile" className="btn btn-outline-secondary">
                            <span className="icon icon-edit-feather" /> <span>Edit</span>
                        </Link>
                    </div>
                </div>: null
            }

            <div className="row p-0 m-0 mt-4">
                {/*
                    Personal user viewing self  =>  Saved Properties, Saved Searches
                    Any authenticated user viewing host/agent  =>  Properties, Reviews
                    Host/Agent viewing self  => Properties, Reviews
                */}
                {authUser.id === user.id || user.is_host ?
                    <Tabs className="properties-tabs" defaultIndex={defaultIndex} onChange={handleTabsChange}>
                        <TabList className="tab-list">
                            {!user.is_host ?
                                <>
                                    <Tab className="tab">Properties</Tab>
                                    <Tab className="tab">Searches</Tab>
                                </> :
                                <>
                                    <Tab className="tab">Properties</Tab>
                                    <Tab className="tab">Reviews</Tab>
                                </>
                            }
                        </TabList>
                        <TabPanels>
                            {!user.is_host ?
                                <>
                                    <TabPanel className="tab-panel">
                                        <UserFavProperties id={user.id} />
                                    </TabPanel>
                                    <TabPanel className="tab-panel">
                                        <SavedSearches />
                                    </TabPanel>
                                </> :
                                <>
                                    <TabPanel className="tab-panel">
                                        <UserProperties id={user.id} />
                                    </TabPanel>
                                    <TabPanel className="tab-panel">
                                        <NoResultsFound message={comingSoonMsg} />
                                    </TabPanel>
                                </>
                            }
                        </TabPanels>
                    </Tabs>:
                    <div className="col-12 text-center h6">
                        This account is private
                    </div>
                }
            </div>
        </div>
    );
}


function ProfileFetcher(props){
    const [user, ,] = store.useState("user");
    const headers = {
        'Authorization': `Token ${user.auth_token}`,
        'Content-Type': 'application/json'
    }

    const fetchProfile = () => {
        return fetch(`${BASE_API_URL}/users/${props.id}/`, { method: 'GET', headers: headers })
        .then(res => res.json().then(data => ({statusCode: res.status, data})))
    }

    if(!user.isLoggedIn) {
        const message = "Yo need to be logged in to view host or agent profile."
        return <LogInToViewSaved message={message}/>
    }

    return (
        <DataFetcher 
         selection={`user/${props.id}`}
         action={fetchProfile}
         placeholder={<BouncePageLoader/>} 
         onError={renderPageError}>
             {response => {
                const user = response.data.data;
                return <UserProfile user={user} {...props}/>
             }}
         </DataFetcher>
    );
}


export { ProfileFetcher as UserProfile }
