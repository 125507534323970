import React from 'react';
import moment from 'moment';
import { queryCache } from 'react-query';
import { store } from '../store';
import { useLocalState } from '../hooks';
import { BASE_API_URL } from '..';
import {
    DataFetcher, BouncePageLoader, renderPageError, NotFoundError
} from '.';
import './ReservationRequestDetails.scss';
import { Spinner } from 'react-bootstrap';
import { getPropertyRoute, thousandsSeparator } from '../utils';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';


function propertyPrice(pricing) {
    const cash = `${pricing.currency} ${thousandsSeparator(pricing.amount)}`
    if (pricing.rate_unit) {
        return (
            <span className="price">
                <span className="amount">{cash}</span>
                <span className="forward-slash"> / </span>
                <span className="rate">{pricing.rate_unit}</span>
            </span>
        );
    }
    return (
        <span className="price">
            <span className="amount">{cash}</span>
        </span>
    );
}

function ReservationRequestDetails(props) {
    const reservation = props.reservation;
    const startDate = moment(reservation.check_in_date);
    const endDate = moment(reservation.check_out_date);

    const history = useHistory();
    const [user,] = store.useState("user");
    const [loading, , updateLoading] = useLocalState({
        accept: false, decline: false
    })

    let reservationStatus = null;
    if (reservation.status === "pending" && !reservation.is_confirmed) {
        reservationStatus = "toBeConfirmed"
    }
    else if (reservation.status === "pending" && reservation.is_confirmed) {
        reservationStatus = "toBePaid"
    }
    else if (reservation.status === "paid" && reservation.is_confirmed) {
        reservationStatus = "paid"
    }
    else if (reservation.status === "canceled" && reservation.is_confirmed) {
        reservationStatus = "hostCanceled"
    }
    else if (reservation.status === "canceled" && !reservation.is_confirmed) {
        reservationStatus = "clientCanceled"
    }

    const accept = (event) => {
        event.preventDefault();
        updateLoading(loading => {
            loading.accept = true
        });

        const formdata = {
            "reservation": reservation.id
        }

        let acceptUrl = `${BASE_API_URL}/accept-reservation/`
        let headers = {
            'Authorization': `Token ${user.auth_token}`,
            'Content-Type': 'application/json'
        }

        fetch(acceptUrl, { method: 'POST', body: JSON.stringify(formdata), headers: headers })
            .then(res => res.json().then(data => ({ statusCode: res.status, data })))
            .then(res => {
                if (res.statusCode === 200) {
                    queryCache.invalidateQueries(`reservation-request/${reservation.id}`);
                }
                else if (res.statusCode === 400) {
                    toast.error(res.data.detail)
                }
            })
            .catch(error => {
                toast.error("No network connection, please try again!.")
            })
            .finally(() => {
                updateLoading(loading => {
                    loading.accept = false
                });
            })
    }

    const decline = (event) => {
        event.preventDefault();
        updateLoading(loading => {
            loading.decline = true
        });

        const formdata = {
            "reservation": reservation.id,
            "reason": "--"
        }

        let acceptUrl = `${BASE_API_URL}/reject-reservation/`
        let headers = {
            'Authorization': `Token ${user.auth_token}`,
            'Content-Type': 'application/json'
        }

        fetch(acceptUrl, { method: 'POST', body: JSON.stringify(formdata), headers: headers })
            .then(res => res.json().then(data => ({ statusCode: res.status, data })))
            .then(res => {
                if (res.statusCode === 200) {
                    queryCache.invalidateQueries(`reservation-request/${reservation.id}`);
                }
                else if (res.statusCode === 400) {
                    toast.error(res.data.detail)
                }
            })
            .catch(error => {
                toast.error("No network connection, please try again!.")
            })
            .finally(() => {
                updateLoading(loading => {
                    loading.decline = false
                });
            })
    }

    const openChat = (e) => {
        history.push(`/conversations/${reservation.owner.id}/`)
    }

    return (
        <div className="reservation-request-details row p-0 m-0 pt-4">
            <div className="cost-breakdown col-12 col-lg-6 p-0 m-0 px-4 ">
                <div className="head">
                    Cost Breakdown
                </div>
                {reservation.properties.map(prop =>
                    <div className="row p-0 m-0 reserved-property align-items-center justify-content-center">
                        <div className="pricing col-6 p-0 m-0">
                            {propertyPrice(prop.pricing)}
                        </div>
                        <div className="picture col-6 p-0 m-0 text-right">
                            <Link to={`/${getPropertyRoute(prop.type)}/${prop.id}/`}>
                                <img src={
                                    prop.pictures ?
                                        prop.pictures[0].src : ""
                                } />
                            </Link>
                        </div>
                    </div>
                )}
                <div className="row p-0 m-0 dates-guests-info align-items-center">
                    <div className="col p-0 m-0 text-left">
                        <div className="col-12 p-0 m-0">Check-in</div>
                        <div className="col-12 p-0 m-0 value">{startDate.format("DD MMM Y")}</div>
                    </div>
                    <div className="col p-0 m-0 text-center">
                        <div className="col-12 p-0 m-0">Check-out</div>
                        <div className="col-12 p-0 m-0 value">{endDate.format("DD MMM Y")}</div>
                    </div>
                    <div className="col p-0 m-0 text-right">
                        <div className="col-12 p-0 m-0">Guests</div>
                        <div className="col-12 p-0 m-0 value">1 Guest</div>
                    </div>
                </div>
                <div className="row p-0 m-0 total-cost align-items-center">
                    <div className="col p-0 m-0 text-left">Total</div>
                    <div className="col p-0 m-0 text-right value">
                        {`${reservation.currency} ${thousandsSeparator(reservation.cost)}`}
                    </div>
                </div>
            </div>

            <div className="reservation-status col-12 col-lg-6 p-0 m-0 px-4 mt-5 mt-lg-0 ">
                <div className="head">
                   Reservation Status
                </div>
                <div className="details">
                    {reservationStatus === "toBeConfirmed" ?
                        <>
                            This reservation is waiting for your confirmation, 
                            please check availability before accepting it.
                        </>:null
                    }
                    {reservationStatus === "toBePaid" ?
                        <>
                            You have already confirmed this reservation, it's now waiting
                            for payments from the client, we'll let you know when
                            it's done.
                        </> : null
                    }
                    {reservationStatus === "paid" ?
                        <>
                           This reservation has already been paid.
                        </>:null
                    }
                    {reservationStatus === "hostCanceled" ?
                        <>
                            You canceled this reservation.
                        </>:null
                    }
                    {reservationStatus === "clientCanceled" ?
                        <>
                            Your client canceled this reservation.
                        </>:null
                    }
                </div>
            </div>

            {reservationStatus === "clientCanceled" ? null :
                <div className="reservation-actions row p-0 m-0">
                    {reservationStatus === "toBeConfirmed" ?
                        <>
                            <div className="col text-center">
                                <div className="accept btn col-12 col-lg-6" onClick={accept} disabled={loading.accept}>
                                    {loading.accept ? <Spinner animation="border" size="sm" /> : 'Accept'}
                                </div>
                            </div>
                            <div className="col text-center">
                                <div className="decline btn col-12 col-lg-6" onClick={decline} disabled={loading.decline}>
                                    {loading.decline ? <Spinner animation="border" size="sm" /> : 'Decline'}
                                </div>
                            </div>
                        </> : null
                    }
                    {reservationStatus === "toBePaid" ?
                        <div className="col-12 text-center">
                            <div className="cancel btn col-12 col-lg-6" onClick={decline} disabled={loading.decline}>
                                {loading.decline ? <Spinner animation="border" size="sm" /> : 'Cancel Reservation'}
                            </div>
                        </div>
                        : null
                    }
                    {reservationStatus === "paid" ?
                        <>
                            <div className="col-12 text-center">
                                <div className="send-message btn col-12 col-lg-6" onClick={openChat}>
                                    {loading.decline ? <Spinner animation="border" size="sm" /> : 'Send Message To Client'}
                                </div>
                            </div>
                        </> : null
                    }
                    {reservationStatus === "hostCanceled" ?
                        <>
                            <div className="col-12 text-center">
                                <div className="uncancel btn col-12 col-lg-6" onClick={accept} disabled={loading.accept}>
                                    {loading.accept ? <Spinner animation="border" size="sm" /> : 'Uncancel & Accept'}
                                </div>
                            </div>
                        </> : null
                    }
                </div>
            }
        </div>
    );
}

function ReservationRequestDetailsFetcher(props) {
    const [user,] = store.useState("user");

    const headers = {
        Authorization: `Token ${user.auth_token}`,
        "Content-Type": "application/json",
    }

    let fetchReservation = () => {
        return fetch(`${BASE_API_URL}/reservation-requests/${props.id}/`, {headers: headers})
        .then(res => res.json().then(data => ({statusCode: res.status, data})))
    }

    return (
        <DataFetcher action={fetchReservation} selection={`reservation-request/${props.id}`}
            placeholder={<BouncePageLoader />} onError={renderPageError}>{response => {
                if(response.data.statusCode === 404){
                    return <NotFoundError />
                }
                return <ReservationRequestDetails reservation={response.data.data} />
        }}</DataFetcher>
    )
}


export { ReservationRequestDetailsFetcher as ReservationRequestDetails}
