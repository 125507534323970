import React, { useState } from 'react';
import navbarBrandImage from '../images/navbar-brand.png';
import {Route, MemoryRouter, Link, HashRouter as Router } from 'react-router-dom';
import './TopBar.scss';
import { store } from '../store';
import { LogIn, SignUp, InfoModal, DataFetcher, BounceBlockLoader } from './'
import { Nav, Navbar, Dropdown } from 'react-bootstrap';
import {
    STANDALONE_PROPERTY_NAMES, getPropertyRoute, capitalizeFirst
} from '../utils';
import { BASE_API_URL } from '../';
import { useLoadScript } from "@react-google-maps/api";
import { AdvancedSearch } from './AdvancedSearch';
import {useTranslation} from "react-i18next";
import i18n from "i18next";


const libraries = ["places"];


function SelectPropertyType(props) {
    const { t } = useTranslation();
    return (
        <ul className="m-0 p-0">
            {props.propertyTypes.map((val) => {
                return (
                    <>
                        <li className="p-0 m-0">
                            <Link className="d-block m-0 p-0 px-2 pb-2 pt-3 create-property-link"
                            to={`/available-for/${val}`}>
                                {t(STANDALONE_PROPERTY_NAMES[val])}
                            </Link>
                        </li>
                        <hr className="line m-0 p-0" />
                    </>
                )
            })}
        </ul>
    );
}


function SelectAvailableFor(props){
    let fetchPropertiesAvailability = () => {
        return fetch(`${BASE_API_URL}/properties-availability/`)
            .then(res => res.json())
    }

    return (
        <ul className="m-0 p-0">
            <DataFetcher
                selection={`properties-availability`}
                action={fetchPropertiesAvailability}
                placeholder={<BounceBlockLoader />}
                error={`Couldn't load property availability options.`}>
                {response => {
                    const available_for_options = response.data[props.type];
                    return available_for_options.map((available_for) =>
                        <>
                            <li className="p-0 m-0">
                                <Router>
                                    <Link className="d-block m-0 p-0 px-2 pb-2 pt-3 create-property-link"
                                        onClick={() => props.setModalShow(false)}
                                        to={`/create/${getPropertyRoute(props.type)}?type=${props.type}&available_for=${available_for}`}>
                                        {i18n.t(capitalizeFirst(available_for))}
                                    </Link>
                                </Router>
                            </li>
                            <hr className="line m-0 p-0" />
                        </>
                    )
                }}
            </DataFetcher>
        </ul>
    );
}


function CreateProperty(props) {
    const [modalShow, setModalShow] = useState(false);
    const [header, setHeader] = useState("");

    let propertyTypes = [];
    for (let type in STANDALONE_PROPERTY_NAMES) {
        propertyTypes.push(type);
    }

    return (
        <>
            <Nav.Link href="#" onClick={() => setModalShow(true)}>
                <span className="d-lg-none icon icon-add" />{i18n.t("Create")}
            </Nav.Link>
            <InfoModal scrollable header={header} modalShow={modalShow} setModalShow={setModalShow}>
                <MemoryRouter initialEntries={["/type", "/available-for", { pathname: "/" }]} initialIndex={0}>
                    <Route exact path="/type" render={({ match }) => {
                        setHeader(i18n.t("Select your property type"));
                        return <SelectPropertyType propertyTypes={propertyTypes} />
                    }} />
                    <Route exact path="/available-for/:type/" render={({ match }) => {
                        setHeader(`${i18n.t("What do you want us to help you with your")} ${i18n.t(match.params.type)}?`);
                        return <SelectAvailableFor type={match.params.type} setModalShow={setModalShow} />
                    }} />
                </MemoryRouter>
            </InfoModal>
        </>
    );
}


function TopBar(props) {
    useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });
    const { t, i18n } = useTranslation();
    const [user,] = store.useState("user");

    function handleLanguageChange(lang) {
        i18n.changeLanguage(lang);
    }

    return (
        <Navbar collapseOnSelect="true" className="row px-0 py-1 py-lg-2 " expand="lg" id="top-navbar">
            <div className="notch d-lg-none"></div>
            <Navbar.Brand className="navbar-brand col-1 col-lg-3 m-0 p-0 px-1">
                <Link className="col-12 col-sm-4 m-0 p-0 px-0 px-sm-2 pr-sm-3 pl-lg-3" to="/">
                    <img src={navbarBrandImage} alt="Seto" />
                </Link>
            </Navbar.Brand>
                <AdvancedSearch/>
            <Navbar.Toggle className="navbar-toggler m-0 py-0 px-2" aria-controls="basic-navbar-nav">
                {user.isLoggedIn ?
                    <div className="navbar-profile-picture text-center">
                        {!user.picture || !user.picture.src ?
                            <span className="icon icon-user" /> :
                            <img src={user.picture.src} alt="" />
                        }
                    </div> :
                    <span className="icon icon-menu"></span>
                }
            </Navbar.Toggle>
            <Navbar.Collapse className="row collapsible-menu col-lg-4 m-0 px-0 px-lg-3" id="basic-navbar-nav">
                <Nav className="col-9 col-lg-12 p-0 m-0 nav-menu">
                    <Nav.Link className="d-lg-none" href="#/"><span className="d-lg-none icon icon-house"/>{t('Home')}</Nav.Link>
                    <hr className="line p-0 m-0 d-lg-none" />
                    <Nav.Link href="#/saved"><span className="d-lg-none icon icon-heart"/>{t('Saved')}</Nav.Link>
                    <hr className="line p-0 m-0 d-lg-none" />

                    {user.isLoggedIn && user.is_host ?
                        <>
                            <CreateProperty />
                            <hr className="line p-0 m-0 d-lg-none" />
                        </> :
                        null
                    }

                    {!user.isLoggedIn ?
                        <>
                            <LogIn />
                            <hr className="line p-0 m-0 d-lg-none" />
                            <SignUp />
                            <hr className="line p-0 m-0 d-lg-none" />
                        </> :
                        null
                    }

                    {user.isLoggedIn ?
                        <>
                            <Dropdown alignRight className="order-lg-6">
                                <Dropdown.Toggle as={Nav.Link}>
                                    <span className="d-none d-lg-block" style={{ "marginLeft": "30px" }} />
                                    <div className="profile-dropdown navbar-profile-picture text-center d-none d-lg-block">
                                        {!user.picture || !user.picture.src ?
                                            <span className="icon icon-user" /> :
                                            <img src={user.picture.src} alt="" />
                                        }
                                    </div>
                                    <span className="profile-toggle d-lg-none">
                                        <span className="d-lg-none icon icon-user" />{user.full_name || "Profile"}
                                    </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="nav-dropdown-menu p-0 m-0 bw-0 bw-lg-1">
                                    <hr className="line p-0 m-0" />
                                    <Dropdown.Item className="nav-dropdown-item" href={`#users/${user.id}/`}>{t('Profile')}</Dropdown.Item>
                                    <hr className="line p-0 m-0" />
                                    <Dropdown.Item className="nav-dropdown-item" href="#tours/">{t('My Tours')}</Dropdown.Item>
                                    <hr className="line p-0 m-0" />
                                    <Dropdown.Item className="nav-dropdown-item" href="#reservations/">{t('My Reservations')}</Dropdown.Item>
                                    {user.is_host ?
                                        <>
                                            <hr className="line p-0 m-0" />
                                            <Dropdown.Item className="nav-dropdown-item" href="#tour-requests/"> {t('Tour Requests')}</Dropdown.Item>
                                            <hr className="line p-0 m-0" />
                                            <Dropdown.Item className="nav-dropdown-item" href="#reservation-requests/"> {t('Reservation Requests')}</Dropdown.Item>
                                        </> : null
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                            <hr className="line p-0 m-0 d-lg-none order-lg-6" />

                            {user.is_staff ?
                                <>
                                    <Dropdown alignRight className="order-lg-5">
                                        <Dropdown.Toggle as={Nav.Link}>
                                            <span className="d-lg-none icon icon-setting-gear" />{t('Adminstration')}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="nav-dropdown-menu p-0 m-0 bw-0 bw-lg-1">
                                            <hr className="line p-0 m-0" />
                                            <Dropdown.Item className="nav-dropdown-item" href="#publish-requests/">{t('Publish Requests')}</Dropdown.Item>
                                            <hr className="line p-0 m-0" />
                                            <Dropdown.Item className="nav-dropdown-item" href="#user-verifications/">{t('Verification Requests')}</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <hr className="line p-0 m-0 d-lg-none order-lg-5" />
                                </> : null
                            }
                        </> :
                        null
                    }
                    <div className="py-4 d-lg-none"></div>
                </Nav>
                <Navbar.Toggle className="overlay col-3 d-lg-none p-0 m-0" aria-controls="basic-navbar-nav"/>
            </Navbar.Collapse>
        </Navbar>
    );
}

export { TopBar };
