import React, { useState } from 'react';
import './EditProfile.scss';
import { useHistory } from 'react-router';
import { Button, Spinner } from 'react-bootstrap';
import {
    DataFetcher, BouncePageLoader, ProfilePictureUploader, renderPageError
} from './';
import { BASE_API_URL } from '../';
import { store } from '../store';
import { queryCache } from 'react-query';
import { useScrollTop, useLocalState } from '../hooks';


function EditProfile(props) {
    useScrollTop();
    const history = useHistory();
    const [isLoading, setLoading] = useState(false);
    const [editError, setEditError] = useState('');
    const [profile, ,updateProfile] = useLocalState(props.profile);
    const [user,] = store.useState("user");
    const [profilePicture, setProfilePicture] = useState(undefined);

    let createProfilePicture = (img) => {
        let postData = new FormData();
        postData.append("src", img, img.name);

        let postUrl = `${BASE_API_URL}/profile-pictures/`;
        let headers = {
            'Authorization': `Token ${user.auth_token}`
        };
        return fetch(postUrl, { method: 'POST', body: postData, headers: headers })
            .then(res => res.json().then(data => ({ status: res.status, data: data })))
    };

    let deleteProfilePicture = (imgID) => {
        let postUrl = `${BASE_API_URL}/profile-pictures/${imgID}/`;
        let headers = {
            'Authorization': `Token ${user.auth_token}`
        };
        return fetch(postUrl, { method: 'DELETE', body: "", headers: headers })
    };

    let updateProfilePicture = async (prevResponse) => {
        if (profilePicture === undefined){
            // Nothing changed
            return
        }
        if (profilePicture && profile.picture){
            // Profile picture is replaced(delete & create)
            await deleteProfilePicture(profile.picture.id);
            await createProfilePicture(profilePicture);
        }
        else if (profilePicture && !profile.picture){
            // Profile picture is created
            await createProfilePicture(profilePicture);
        }
        else if (!profilePicture && profile.picture){
            // Profile picture is deleted
            await deleteProfilePicture(profile.picture.id);
        }
    };

    let redirect = (response) => {
        // Invalidate user profile
        queryCache.invalidateQueries(`my-profile`);
        history.push(`/users/${user.id}`);
        window.location.reload();
    };

    let handleProfileUpdate = (e) => {
        e.preventDefault();
        setEditError("");
        setLoading(true);
        let form = e.target;

        let formData = {
            full_name: form.full_name.value,
            biography: form.biography.value
        };

        let postUrl = `${BASE_API_URL}/users/${user.id}/`;
        let headers = {
            'Authorization': `Token ${user.auth_token}`,
            'Content-Type': 'application/json'
        };
        fetch(postUrl, { method: 'PATCH', body: JSON.stringify(formData), headers: headers })
            .then(res => res.json().then(data => ({ status: res.status, data: data })))
            .then(obj => updateProfilePicture(obj))
            .then(obj => redirect(obj))
            .catch(error => {
                // Network error
                setEditError("No network connection, please try again!.");
            })
            .finally(() => {
                // Enable button
                setLoading(false);
            })
    };

    let updateValue = (e) => {
        updateProfile(profile => {
            let field = e.target.getAttribute("data-field");
            let value = e.target.value;
            profile[field] = value;
        })
    };

    let src = (pictureModel) => {
        if (pictureModel) {
            return pictureModel.src;
        }
        return null;
    };

    return (
        <form className="edit-profile row text-secondary m-0 p-0 px-3 px-sm-4 mt-4" onSubmit={handleProfileUpdate}>
            <div className="col-12 col-lg-6 m-0 p-0 px-2">
                <div className="col-12 p-0 m-0">
                    <div className="col-12 p-0 m-0 text-center edit-profile-picture">
                        <ProfilePictureUploader icon="icon-add-user-light" name="picture" src={src(profile.picture)} onChange={setProfilePicture} />
                    </div>
                </div>

                <div className="col-12 standard-form-field p-0 m-0 mt-4">
                    <label className="form-check-label col-12 p-0 m-0">Full Name</label>
                    <div className="col-12 p-0 m-0 my-1">
                        <input type="text" data-field="full_name" name="full_name" placeholder="Full Name"
                            value={profile.full_name} onChange={updateValue} className="form-control" />
                    </div>
                </div>

                <div className="col-12 standard-form-field p-0 m-0 mt-4">
                    <label className="form-check-label col-12 p-0 m-0">Phone</label>
                    <div className="col-12 p-0 m-0 my-1">
                        <input type="text" placeholder="Phone Number"
                            value={profile.dial_code + profile.phone} className="form-control" disabled={true}/>
                    </div>
                </div>

                <div className="col-12 standard-form-field p-0 m-0 mt-4">
                    <label className="form-check-label col-12 p-0 m-0">Biography</label>
                    <div className="col-12 p-0 m-0 my-1">
                        <textarea type="text" data-field="biography" name="biography" placeholder=""
                            value={profile.biography} onChange={updateValue} className="form-control" rows="4" />
                    </div>
                </div>

                <div className="col-12 standard-form-field p-0 m-0 mt-4">
                    <div className="col-12 mb-2 text-center text-danger">
                        {editError}
                    </div>
                    <Button className="col-12 mt-2 standard-form-field" variant="primary" disabled={isLoading} type="submit">
                        {isLoading ? <Spinner animation="border" size="sm" /> : 'Save'}
                    </Button>
                </div>
            </div>
        </form>
    )
}


function ProfileFetcher(props){
    const [user, ,updateUser] = store.useState("user");

    let headers = {
        'Authorization': `Token ${user.auth_token}`,
        'Content-Type': 'application/json'
    }

    let fetchProfile = () => {
        return fetch(`${BASE_API_URL}/users/${user.id}/`, { method: 'GET', headers: headers })
        .then(res => res.json().then(data => ({statusCode: res.status, data})))
    }

    return (
        <DataFetcher
         selection="my-profile"
         action={fetchProfile}
         placeholder={<BouncePageLoader/>}
         onError={renderPageError}>
             {response => {
                const profile = response.data.data;
                return <EditProfile profile={profile} {...props}/>
             }}
         </DataFetcher>
    );
}

export { ProfileFetcher as EditProfile }
