import React, { useState } from 'react';
import './SavedSearchOverview.scss';
import { store } from '../store';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { getPropertyName, importAll } from '../utils';
import { InfoModal } from './';
import { BASE_API_URL } from '../';
import { queryCache } from 'react-query';
import { Spinner } from 'react-bootstrap';


const images = importAll(
    require.context("../images/advanced-search-imgs", false, /\.(png|jpe?g|svg)$/)
);

function SavedSearchOverview(props) {
    const savedSearch = props.savedSearch;
    const [user, ] = store.useState("user");
    const [isLoading, setIsLoading] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const modalHeader = "Options"

    const path = `/saved-searches/${savedSearch.id}/`

    const redirect = (status) => {
        if (status === 204) {
            // Invalidate saved searches
            queryCache.invalidateQueries("saved-searches");
            setModalShow(false);
        }
        // Report Error
    }

    const deleteSavedSearch = (e) => {
        setIsLoading(true);
        const URL = `${BASE_API_URL}/saved-searches/${savedSearch.id}/`;
        const headers = {
            'Authorization': `Token ${user.auth_token}`,
            'Content-Type': 'application/json'
        }
        fetch(URL, { method: 'DELETE', headers: headers })
            .then(res => res.status)
            .then(status => redirect(status))
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <>
            <InfoModal positionBottom header={modalHeader} modalShow={modalShow} setModalShow={setModalShow}>
                <div className="saved-search-options">
                    <div className="option text-danger" onClick={deleteSavedSearch}>
                        Delete {isLoading ? <Spinner className="spinner" animation="border" size="sm" variant="secondary" /> : null}
                    </div>
                    <div className="option text-secondary" onClick={e => setModalShow(false)}>Cancel</div>
                </div>
            </InfoModal>
            <Link className="saved-search-overview row p-0 m-0" to={path}>
                <div className="delete-icon" onClick={e => { e.preventDefault(); setModalShow(true) }} >
                    <span className="icon icon-three-dots" />
                </div>
                <div className="saved-search-info col-7 p-0 m-0">
                    <div className="description">
                        <span className="icon icon-bookmark-star" />&nbsp;
                        {getPropertyName(savedSearch.parameters.type)} to &nbsp;
                        {savedSearch.parameters.available_for == "sale" ? "buy" : null}
                        {savedSearch.parameters.available_for == "book" ? "book" : null}
                        {savedSearch.parameters.available_for == "rent" ? "rent" : null}

                    </div>
                    <div className="label">
                        {!savedSearch.label || savedSearch.label === "flexible" ?
                            "At any location": `At ${savedSearch.label}`
                        }
                    </div>
                    <div className="date-saved">
                        Saved on {moment(savedSearch.created_at).format("D MMM, Y")}
                    </div>
                </div>
                <div className="saved-search-img col-5 p-0 m-0 text-right">
                    <img src={images[savedSearch.parameters.type]} alt="" />
                </div>
            </Link>
        </>
    );
}


export { SavedSearchOverview }
