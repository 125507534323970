import React from 'react';
import './ReservationRequestOverview.scss';
import { Link } from 'react-router-dom';
import {
    capitalizeFirst,
    thousandsSeparator
} from '../utils';


function ReservationRequestOverview(props) {
    const reservation = props.reservation;
    const properties = reservation.properties;
    let main_img = {is_main: null, src: null, id: null}
    let location = null;
    if (properties.length > 0){
        location = properties[0].location
        main_img = properties[0].pictures.filter((picture) => picture.is_main);
        if (main_img.length > 0) {
            main_img = main_img[0];
        }
    }

    let path = `/reservation-requests/${reservation.id}`

    return (
        <Link className="reservation-request-overview row p-0 m-0" to={path} id={props.id}>
            <div className={"reservation-info col-6 p-0 m-0"}>
                {location ?
                    <div className="location"> <i className="fa fa-map-marker-alt"></i>
                    &nbsp;{location.address}
                    </div>: null
                }
                <div className="cost">
                {`${reservation.currency} ${thousandsSeparator(reservation.cost)}`}
                </div>

                {reservation.status ?
                    <div className="status">
                        Status: {capitalizeFirst(reservation.status)}
                    </div> : null
                }

            </div>
            <div className="reservation-img col-6 p-0 m-0 text-right">
                <img src={main_img.src} alt="" />
            </div>
        </Link>
    );
}


export { ReservationRequestOverview }
