import React, { useState } from 'react';
import './Loaders.scss';
import { Spinner } from 'react-bootstrap';


const LOADER_DELAY = 150;

function Bounce(props) {
    return (
        <div className={"bouncing-loader"}>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
}

function SpinnerPageLoader(props) {
    const [visible, setVisibility] = useState(false);
    setTimeout(function () { setVisibility(true) }, LOADER_DELAY);

    if (!visible) {
        return null
    }
    return (
        <div className="col-12 text-center">
            <div className="col-12 page-loader">
                <Spinner animation="border" variant="secondary" size="sm" />
            </div>
        </div>
    );
}

function SpinnerInlineLoader(props) {
    const [visible, setVisibility] = useState(false);
    setTimeout(function () { setVisibility(true) }, LOADER_DELAY);

    if (!visible) {
        return null
    }
    return (
        <div className="col-12 text-center mx-0 px-0">
            <div className="col-12 inline-loader">
                <Spinner animation="border" variant="secondary" size="sm" />
            </div>
        </div>
    );
}

function GlowPageLoader(props) {
    const [visible, setVisibility] = useState(false);
    setTimeout(function () { setVisibility(true) }, LOADER_DELAY);

    if (!visible) {
        return null
    }
    return (
        <div className="col-12 text-center">
            <div className="col-12 page-loader">
                <Spinner animation="grow" variant="primary" size="sm" />
                <Spinner animation="grow" variant="primary" size="sm" />
                <Spinner animation="grow" variant="primary" size="sm" />
            </div>
        </div>
    );
}

function GlowBlockLoader(props) {
    const [visible, setVisibility] = useState(false);
    setTimeout(function () { setVisibility(true) }, LOADER_DELAY);

    if (!visible) {
        return null
    }
    return (
        <div className="glow-block-loader col-12 my-3 text-center">
            <Spinner animation="grow" variant="primary" size="sm" />
            <Spinner animation="grow" variant="primary" size="sm" />
            <Spinner animation="grow" variant="primary" size="sm" />
        </div>
    );
}

function GlowInlineLoader(props) {
    const [visible, setVisibility] = useState(false);
    setTimeout(function () { setVisibility(true) }, LOADER_DELAY);

    if (!visible) {
        return null
    }
    return (
        <div className="glow-inline-loader">
            <Spinner animation="grow" variant="primary" size="sm" />
            <Spinner animation="grow" variant="primary" size="sm" />
            <Spinner animation="grow" variant="primary" size="sm" />
        </div>
    );
}


function BouncePageLoader(props) {
    const [visible, setVisibility] = useState(false);
    setTimeout(function () { setVisibility(true) }, LOADER_DELAY);

    if (!visible) {
        return null;
    };
    return (
        <div className="col-12 text-center">
            <div className="col-12 page-loader">
                <Bounce />
            </div>
        </div>
    );
}

function BounceBlockLoader(props) {
    const [visible, setVisibility] = useState(false);
    setTimeout(function () { setVisibility(true) }, LOADER_DELAY);

    if (!visible) {
        return null
    }
    return (
        <div className={`glow-block-loader col-12 my-3 text-center ${props.className||""}`}>
            <Bounce />
        </div>
    );
}

function BounceInlineLoader(props) {
    const [visible, setVisibility] = useState(false);
    setTimeout(function () { setVisibility(true) }, LOADER_DELAY);

    if (!visible) {
        return null
    }
    return (
        <div className={`glow-inline-loader ${props.className||""}`}>
            <Bounce />
        </div>
    );
}

export {
    SpinnerPageLoader, SpinnerInlineLoader,
    GlowPageLoader, GlowBlockLoader, GlowInlineLoader,
    BouncePageLoader, BounceBlockLoader, BounceInlineLoader,
};
