import React, {useEffect, useRef} from 'react';
import './BottomNavBar.scss';
import { useHistory } from 'react-router';
import { store } from '../store';
import { queryCache } from 'react-query';
import { BASE_API_URL, BASE_WS_URL } from '../';
import { DataFetcher } from '.';
import i18n from "i18next";


function RippleButton({ children, onClick }) {
    const [coords, setCoords] = React.useState({ x: -1, y: -1 });
    const [isRippling, setIsRippling] = React.useState(false);

    React.useEffect(
        () => {
            if (coords.x !== -1 && coords.y !== -1) {
                setIsRippling(true);
                setTimeout(() => setIsRippling(false), 1200);
            } else setIsRippling(false);
        },
        [coords]
    );

    React.useEffect(
        () => {
            if (!isRippling) setCoords({ x: -1, y: -1 });
        },
        [isRippling]
    );

    /* Original version
  const ripplePositionStyle = {
      left: coords.x + 10,
      top: coords.y
  }
  */

    const ripplePositionStyle = {
        // Don't use this for now
    }

    return (
        <div
            className="ripple-button"
            onClick={e => {
                var rect = e.target.getBoundingClientRect();
                var x = e.clientX - rect.left;
                var y = e.clientY - rect.top;
                setCoords({ x, y });
                onClick && onClick(e);
            }}
        >
            {isRippling ? (
                <span
                    className="ripple"
                    style={ripplePositionStyle}
                />
            ) : (
                ""
            )}
            <span className="content">{children}</span>
        </div>
    );
}

function UserNotification(props) {
    const history = useHistory();
    const [user,] = store.useState("user");
    let selection = "user-notifications";

    const ws = useRef(null);

    useEffect(() => {
        ws.current = new WebSocket(
            `${BASE_WS_URL}/ws/notifications?token=${user.auth_token}`)
        ws.current.onopen = () => {
            //
        }
        ws.current.onclose = () => console.log("ws closed");

        const wsCurrent = ws.current;

        return () => {
            wsCurrent.close();
        };
    }, []);

    useEffect(() => {
        if (!ws.current) return;

        ws.current.onmessage = e => {
            const message = JSON.parse(e.data);
            if (message.action === "new_notification_created") {
                queryCache.invalidateQueries(selection);
                queryCache.invalidateQueries("unread-notifications-count");
            }
            else if (message.action === "marked_all_notifications_as_read") {
                queryCache.invalidateQueries(selection);
                queryCache.invalidateQueries("unread-notifications-count");
            }
        };
    }, []);

    useEffect(() => {
        let timerId = null;

        const cleanup = () => clearTimeout(timerId);

        if (!ws.current) return cleanup;
        if(history.location.pathname === "/notifications"){
            // Mark all notifications as read
            const data = {
                action: "mark_all_notifications_as_read"
            }
            if (ws.current.readyState === WebSocket.OPEN){
                timerId = setTimeout(() => ws.current.send(JSON.stringify(data)), 1000)
            }
            // End of marking all notifications as read
        }
        return cleanup;
    }, [history.location.pathname]);


    if (!user.isLoggedIn) {
        return null
    }

    const headers = {
        "Content-Type": "application/json",
    }

    if (user.isLoggedIn) {
        headers["Authorization"] = `Token ${user.auth_token}`
    }

    let fetchUnreadNotificationsCount = () => {
        return fetch(`${BASE_API_URL}/unread-notifications-count/`, {headers: headers})
            .then(res => res.json().then(data => ({statusCode: res.status, data})))

    }

    return (
        <DataFetcher action={fetchUnreadNotificationsCount} selection={`unread-notifications-count`}
                     placeholder={null} onError={() => null}>{response => {
            if(response.data.data.count === 0){
                return null
            }
            return (
                <div className="col-12 text-center justify-content-center">
                    <span className="notifications-badge"></span>
                </div>
            );
        }}
        </DataFetcher>
    );
}

function InboxNotification(props) {
    const [user,] = store.useState("user");
    let selection = "user-conversations";

    const ws = useRef(null);

    useEffect(() => {
        ws.current = new WebSocket(
            `${BASE_WS_URL}/ws/chat-notifications?token=${user.auth_token}`)
        ws.current.onopen = () => {
            //
        }
        ws.current.onclose = () => console.log("ws closed");

        const wsCurrent = ws.current;

        return () => {
            wsCurrent.close();
        };
    }, []);

    useEffect(() => {
        if (!ws.current) return;

        ws.current.onmessage = e => {
            const message = JSON.parse(e.data);
            if (message.action === "message_received") {
                queryCache.invalidateQueries(selection);
                queryCache.invalidateQueries("unread-conversations-count");
            }
            else if (message.action === "message_sent") {
                queryCache.invalidateQueries(selection);
            }
            else if (message.action === "marked_all_messages_as_read") {
                queryCache.invalidateQueries(selection);
                queryCache.invalidateQueries("unread-conversations-count");
            }
        };
    }, []);


    if (!user.isLoggedIn) {
        return null
    }

    const headers = {
        "Content-Type": "application/json",
    }

    if (user.isLoggedIn) {
        headers["Authorization"] = `Token ${user.auth_token}`
    }

    let fetchUnreadMessagesCount = () => {
        return fetch(`${BASE_API_URL}/unread-conversations-count/`, {headers: headers})
            .then(res => res.json().then(data => ({statusCode: res.status, data})))

    }

    return (
        <DataFetcher action={fetchUnreadMessagesCount} selection={`unread-conversations-count`}
                     placeholder={null} onError={() => null}>{response => {
            if(response.data.data.count === 0){
                return null
            }
            return (
                <div className="col-12 text-center justify-content-center">
                    <span className="inbox-badge"></span>
                </div>
            );
        }}
        </DataFetcher>
    );
}


function BottomNavBar(props){
    const history = useHistory();
    const [,setAnimatePageTransition] = store.useState("animatePageTransition");
    let homePath = "/";
    let notificationsPath = "/notifications";
    let conversationsPath = "/conversations";
    let savedPropertiesPath = "/saved";

    let goTo = (location) => {
        if(location === history.location.pathname){
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        }
        else {
            setAnimatePageTransition(true);
            history.push(location);
        }
    }

    let goToHome = (e) => {
        goTo(homePath)
    }

    let goToNotifications = (e) => {
        goTo(notificationsPath)
    }
    let goToConversations = (e) => {
        goTo(conversationsPath)
    }
    let goToSavedProperties = (e) => {
        goTo(savedPropertiesPath)
    }

    let active = (iconPath) => {
        let currentPath = history.location.pathname;
        if(currentPath === iconPath){
            return ["active-icon", "active-text"];
        }
        return ["", ""]
    }

    return (
        <div className="bottom-nav-bar d-lg-none row fixed-bottom">
            <div className="col text-center">
                <RippleButton onClick={goToHome}>
                    <span className={`icon icon-search ${active(homePath)[0]}`}></span>
                    <div className={`icon-label ${active(homePath)[1]}`}>&nbsp;&nbsp;{i18n.t("Explore")}</div>
                </RippleButton>
            </div>

            <div className="col text-center">
                <RippleButton onClick={goToSavedProperties}>
                    <span className={`icon icon-heart ${active(savedPropertiesPath)[0]}`}></span>
                    <div className={`icon-label ${active(savedPropertiesPath)[1]}`}>{i18n.t("Saved")}</div>
                </RippleButton>
            </div>

            <div className="col text-center">
                <RippleButton onClick={goToNotifications}>
                    <span className={`icon icon-notification ${active(notificationsPath)[0]}`}></span>
                    <UserNotification/>
                    <div className={`icon-label ${active(notificationsPath)[1]}`}>{i18n.t("Notifs")}</div>
                </RippleButton>
            </div>
            <div className="col text-center">
                <RippleButton onClick={goToConversations}>
                    <span className={`icon icon-chat ${active(conversationsPath)[0]}`}></span>
                    <InboxNotification/>
                    <div className={`icon-label ${active(conversationsPath)[1]}`}>{i18n.t("Inbox")}</div>
                </RippleButton>
            </div>
        </div>
    );
}

export {BottomNavBar, RippleButton, InboxNotification, UserNotification}
