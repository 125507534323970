import React from 'react';
import './PaymentMethods.scss';
import { Accordion, Card } from 'react-bootstrap';
import { importAll } from '../utils';


const images = importAll(
    require.context("../images/payment-providers-imgs", false, /\.(png|jpe?g|svg)$/)
  );


function PaymentMethod(props){
    return (
        <Card className="payment-method">
            <Accordion.Toggle className="row header" as={Card.Header} eventKey={props.eventKey}>
                <div className="col m-0 p-0 px-1 provider-logo"><img src={images[props.logo]} /></div>
                <div className="col text-right provider-name">{props.name}</div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={props.eventKey}>
                <Card.Body>
                    {props.children}
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
}


function PaymentMethods(props) {
    const payment = props.payment;
    return (
        <div className="payment-methods">
            <div>Follow the following steps to finish payment.</div>
            <div className="payment-token my-3">Pay Number: &nbsp;&nbsp; {payment.payment_token}</div>
            <Accordion defaultActiveKey="0">
                <PaymentMethod name="M-Pesa" logo="mpesa" eventKey="0">
                    <ol>
                        <li>Dial *150*00#</li>
                        <li>Choose 4 - Lipa by M-Pesa</li>
                        <li>Choose 4 - Enter business number</li>
                        <li>Enter 123123 (As Selcom Pay/Masterpass business number)</li>
                        <li>Enter reference number (Pay Number)</li>
                        <li>Enter amount</li>
                        <li>Enter PIN</li>
                        <li>You will receive confirmation SMS</li>
                    </ol>
                </PaymentMethod>

                <PaymentMethod name="Tigo Pesa" logo="tigopesa" eventKey="1">
                    <ol>
                        <li>Dial *150*01#</li>
                        <li>Choose 5 - Lipia bidhaa </li>
                        <li>Choose 2 - Pay by Matercard QR</li>
                        <li>Enter reference number (Pay Number)</li>
                        <li>Enter amount</li>
                        <li>Enter PIN</li>
                        <li>You will receive confirmation SMS</li>
                    </ol>
                </PaymentMethod>

                <PaymentMethod name="Airtel Money" logo="airtelmoney" eventKey="2">
                    <ol>
                        <li>Dial *150*60#</li>
                        <li>Choose 5 - Make Payments</li>
                        <li>Choose 1 - Pay Merchant (ALL Netowkrs)</li>
                        <li>Choose 1 - Pay with Mastercard QR</li>
                        <li>Enter Amount</li>
                        <li>Enter the reference number (Pay Number)</li>
                        <li>Enter PIN to confirm</li>
                    </ol>
                </PaymentMethod>

                <PaymentMethod name="T-Pesa" logo="tpesa" eventKey="3">
                    <ol>
                        <li>Dial *150*71#</li>
                        <li>Choose 6 - Pay Merchant</li>
                        <li>Choose 2 - SelcomPay/Masterpass</li>
                        <li>Enter Pay Number (Pay Number)</li>
                        <li>Enter amount</li>
                        <li>Enter PIN</li>
                        <li>You will receive confirmation SMS</li>
                    </ol>
                </PaymentMethod>

                <PaymentMethod name="Halo Pesa" logo="halopesa" eventKey="4">
                    <ol>
                        <li>Dial *150*60#</li>
                        <li>Choose 5 - Make Payments</li>
                        <li>Choose 1 - Pay Merchant (ALL Netowkrs)</li>
                        <li>Choose 1 - Pay with Mastercard QR</li>
                        <li>Enter Amount</li>
                        <li>Enter the reference number (Pay Number)</li>
                        <li>Enter PIN to confirm</li>
                    </ol>
                </PaymentMethod>

                <PaymentMethod name="Ezy Pesa" logo="ezypesa" eventKey="5">
                    <ol>
                        <li>Dial *150*02#</li>
                        <li>Choose 5 - Payments</li>
                        <li>Choose 1 - Lipa Hapa</li>
                        <li>Choose 2 - Pay by Masterpass QR</li>
                        <li>Enter Merchant Number (Pay Number)</li>
                        <li>Enter Amount</li>
                        <li>Enter PIN</li>
                        <li>You will receive confirmation SMS</li>
                    </ol>
                </PaymentMethod>
                <div className="payment-url mt-3 mb-5">
                    <a href={payment.payment_gateway_url} target="_blank">
                        More Payment Options <span className="icon icon-next" />
                    </a>
                </div>
            </Accordion>
        </div>
    );
}


export {PaymentMethods}
