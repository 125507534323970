import React from 'react';
import './PropertyOverview.scss';
import { Link } from 'react-router-dom';
import { Rating, SaveButton } from './';
import {
    getPropertyRoute, thousandsSeparator, PARENT_ONLY_PROPERTIES,
    getPropertyName
} from '../utils';
import {useTranslation} from "react-i18next";
import i18n from 'i18next';


function pricing(pricing) {
    const cash = <span className="price">{pricing.currency} {thousandsSeparator(pricing.amount)}</span>
    if (pricing.rate_unit) {
        return <span>{cash} / {i18n.t(pricing.rate_unit)}</span>;
    }
    return cash;
}

function price(property) {
    if(property.pricing && PARENT_ONLY_PROPERTIES[property.type] === undefined){
        return pricing(property.pricing)
    }
    return null;
}

function PropertyOverview(props) {
    const property = props.property;
    let main_img = property.pictures.filter((picture) => picture.is_main);
    if (main_img.length < 1) {
        main_img = { is_main: null, src: null, id: null };
    }
    else {
        main_img = main_img[0];
    }

    let path = `/${getPropertyRoute(property.type)}/${property.id}`
    if(!props.actualPropertyPath && property.parent_property){
        path = `/${getPropertyRoute(property.parent_property_type)}/${property.parent_property}/?compartment=${property.id}`
    }
    const { t } = useTranslation();

    return (
        <div className="prop-overview row p-0 m-0" id={props.id}>
            <div className="prop-img col-12 p-0 m-0">
                <Link className="lazy-container lazy-load-animation"
                      to={path}>
                    <img src={main_img.src} alt="" />
                </Link>
                <SaveButton property={property} />
            </div>
            <div className="prop-info col-12 px-1">
                <div className="prop-type">{t(getPropertyName(property.type))} {t("for")} <span className="bg-primary text-light">{t(property.available_for)}</span></div>
                <div className="prop-location"> <i className="fa fa-map-marker-alt"></i>
                    &nbsp;{property.location.address}
                </div>
                <div className="prop-price">
                    {price(property)}
                </div>
                <div className="prop-rating">
                    <Rating property={property}/>({property.total_rating_score})
                </div>
            </div>
        </div>
    );
}


function PropertyLinkOverview(props) {
    const property = props.property;
    let main_img = property.pictures.filter((picture) => picture.is_main);
    if (main_img.length < 1) {
        main_img = { is_main: null, src: null, id: null };
    }
    else {
        main_img = main_img[0];
    }

    const { t } = useTranslation();

    return (
        <div className="prop-link-overview row p-0 m-0" id={props.id}>
            <div className="img col-4 p-0 m-0">
                <div className="lazy-container lazy-load-animation">
                    <img src={main_img.src} alt="" />
                </div>
            </div>
            <div className="info col-8 pl-3 pr-1">
                <div className="type">{t(getPropertyName(property.type))} {t("for")} <span className="bg-primary text-light">{t(property.available_for)}</span></div>
                <div className="location"> <i className="fa fa-map-marker-alt"></i>
                    &nbsp;{property.location.address}
                </div>
                <div className="pricing">
                    {price(property)}
                </div>
            </div>
        </div>
    );
}


function PropertySliderOverview(props) {
    const property = props.property;
    let main_img = property.pictures.filter((picture) => picture.is_main);
    if (main_img.length < 1) {
        main_img = { is_main: null, src: null, id: null };
    }
    else {
        main_img = main_img[0];
    }

    let path = `/${getPropertyRoute(property.type)}/${property.id}`
    if(!props.actualPropertyPath && property.parent_property){
        path = `/${getPropertyRoute(property.parent_property_type)}/${property.parent_property}/?compartment=${property.id}`
    }
    const { t } = useTranslation();
    return (
        <div className="prop-slider-overview row p-0 m-0">
            <div className="prop-img col-12 p-0 m-0">
                <Link className="lazy-container lazy-load-animation" to={path}>
                    <img src={main_img.src} alt="" />
                </Link>
                <SaveButton property={property} />
            </div>
            <div className="prop-info col-12 px-1">
                <div className="prop-type">
                    <div className="prop-type">{t(getPropertyName(property.type))} {t("for")} <span
                        className="bg-primary text-light">{t(property.available_for)}</span></div>
                </div>
                <div className="prop-location"> <i className="fa fa-map-marker-alt"></i>
                    &nbsp;{property.location.address}
                </div>
                <div className="prop-price">
                    {price(property)}
                </div>
                <div className="prop-rating">
                    <Rating property={property}/>({property.total_rating_score})
                </div>
            </div>
        </div>
    );
}

export { PropertyOverview, PropertySliderOverview, PropertyLinkOverview }
