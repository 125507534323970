import React, { useState, useEffect } from 'react';
import './UploadProperty.scss';
import { parse } from 'query-string';
import { useHistory } from 'react-router';
import { Button, Spinner } from 'react-bootstrap';
import { store } from '../store';
import {
    FeaturesInput, ImageUploader, MultipleImageUploader, Map
} from './';
import { BASE_API_URL } from '../';
import { getPropertyRoute, PARENT_ONLY_PROPERTIES, isPositiveInt } from '../utils';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { AsyncSelect } from './'
import { queryCache } from 'react-query';
import { useScrollTop, useLocalState } from '../hooks';
import i18n from "i18next";
import makeAnimated from 'react-select/animated';


const animatedComponents = makeAnimated();


let initialFieldsData = {
    amenities: [],
    services: [],
    main_picture: [],
    other_pictures: [],
    other_features: [],
    contact: {},
    otherInputs: {},
    location: {
        address: "",
        point: "POINT (0 0)"
    }
};

function UploadGenericProperty(props){
    useScrollTop();
    const history = useHistory();
    const parsed = parse(history.location.search);

    const [user, ] = store.useState("user");
    const [fields, ,updateFields] = useLocalState(initialFieldsData);
    const [isLoading, setLoading] = useState(false);
    const [createError, setCreateError] = useState('');

    useEffect(()=>{
        updateFields(fields => {
            // Suggest user contacts
            fields["contact"] = {
                email: user.email,
                phone: user.phone,
                full_name: user.full_name
            }
        })
    }, []);

    const currencies = ["TZS", "USD"];
    const terms = ["Day", "Month", "Year"];

    let postImages = (propertyID, pictures) => {
        // Post Images recusively until they are all done
        if(pictures.length === 0){
            // Invalidate user properties
            queryCache.invalidateQueries(`myProperties.properties`);
            queryCache.invalidateQueries(`myProperties.${getPropertyRoute(props.type)}`);
            // Redirect to created property or compartment management page
            if(PARENT_ONLY_PROPERTIES[parsed.type] !== undefined){
                return history.push(`/create-compartments/${propertyID}`);
            }
            if(isPositiveInt(parsed.parent_property)){
                let parentProperty = Number(parsed.parent_property);
                queryCache.invalidateQueries(`property/${parentProperty}`);
                return history.push(`/create-compartments/${parentProperty}`);
            }
            return history.push(`/${getPropertyRoute(props.type)}/${propertyID}`);
        }
        let img = pictures.pop();
        let postData = new FormData();
        postData.append("property", propertyID)
        postData.append("is_main", Number(img.is_main))
        postData.append("tool_tip", img.tool_tip)
        postData.append("src", img.src, img.src.name)

        let postUrl = `${BASE_API_URL}/property-pictures/`;
        let headers = {
            'Authorization': `Token ${user.auth_token}`
        }
        return fetch(postUrl, {method: 'POST', body: postData, headers: headers})
          .then(res =>  res.json().then(data => ({status: res.status, data: data})))
          .then(obj => postImages(propertyID, pictures))
    }

    let updatePropertyImages = async (response) => {
        if(response.status !== 201){
            // Report error
            return
        }

        let id = response.data.id;
        let pictures = [...fields.main_picture, ...fields.other_pictures]
        await postImages(id, pictures)
    }

    let getAddList = options => {
        return options.filter(option => !option.__isNew__)
          .map(option => option.value)
    }

    let createProperty = (e) => {
        e.preventDefault();
        setCreateError("");
        setLoading(true);

        let form = e.target;

        let price = {};

        if (PARENT_ONLY_PROPERTIES[parsed.type] === undefined) {
            price = {
                pricing: {
                    amount: form.price.value,
                    rate_unit: parsed.available_for !== 'sale'? fields.price_rate_unit : null,
                    agent_fee: (parsed.available_for === 'sale' || parsed.available_for === 'rent') ? fields.agent_fee : null,
                    currency: form.currency.value,
                    payment_terms: form.payment_terms.value,
                }
            }
        }

        let location = {
            location: {
                address: fields.location.address,
                point: fields.location.point
            }
        };

        let contact = {
            contact: {
                name: fields.contact.full_name,
                email: fields.contact.email,
                phone: fields.contact.phone
            }
        };

        let parent = {};

        if(isPositiveInt(parsed.parent_property)){
            parent = {parent_property: Number(parsed.parent_property)};
            location = {};
            contact = {};
        }

        let formData = {
            ...parent,
            ...price,
            ...contact,
            ...location,
            available_for: parsed.available_for,
            description: fields.description,
            title: fields.title,
            amenities: {
                "add": getAddList(fields.amenities)
            },
            services: {
                "add": getAddList(fields.services)
            },
            other_features: {
                "create": fields.other_features
            },
            ...fields.otherInputs
        };

        let postUrl = `${BASE_API_URL}/${getPropertyRoute(props.type)}/`;
        let headers = {
            'Authorization': `Token ${user.auth_token}`,
            'Content-Type': 'application/json'
        };

        fetch(postUrl, {method: 'POST', body: JSON.stringify(formData), headers: headers})
          .then(res =>  res.json().then(data => ({status: res.status, data: data})))
          .then(obj => updatePropertyImages(obj))
          .catch(error => {
              // Network error
              setCreateError("No network connection, please try again!.");
          })
          .finally(() => {
              // Enable button
              setLoading(false);
          })
    };

    let updateValue = (e) => {
        updateFields(fields => {
            fields[e.target.name] = e.target.value;
        })
    };

    let updateLocation = (location) => {
        updateFields(fields => {
            fields.location.address = location.address;
            fields.location.point = `POINT (${location.point.lng} ${location.point.lat})`
        })
    };

    let updateContact = (e) => {
        updateFields(fields => {
            fields.contact[e.target.name] = e.target.value;
        })
    };

    let updateOtherImages = (value) => {
        updateFields(fields => {
            fields['other_pictures'] = value;
        })
    };

    let updateMainImage = (value) => {
        updateFields(fields => {
            fields['main_picture'] = value;
        })
    };

    let updateFeatures = (features) => {
        updateFields(fields => {
            fields['other_features'] = features;
        })
    };

    let getOptions = (url) => {
        return fetch(url)
          .then(res => res.json())
          .then(results => results.results.map(
            amenity => {return {value: amenity.id, label: amenity.name}}
          ))
    };

    let get = (selection) => {
        let getSelection = inputValue => {
            const URL = `${BASE_API_URL}/${selection}/?query={id,name}&format=json&name__icontains=${inputValue}`;
            return getOptions(URL)
        };
        return getSelection;
    };

    let update = (selection) => {
        let updateSelection = (value) => {
            if(!value){
                value = []
            }
            updateFields(fields => {
                fields[selection] = value
            })
        };
        return updateSelection;
    };


    let child;
    if (props.children === undefined) {
        child = {}
    }
    else {
        child = props.children(props.property);
    }

    return (
      <form className="property-form text-secondary px-3 px-sm-4 mt-2 mt-sm-3" onSubmit={createProperty}>
          <div className="row mt-3 p-md-2">
              <div className="col-12 col-md-7 pr-md-4">
                  <div className="standard-form-field row p-0 m-0 mb-4">
                      <label className="form-check-label col-12 p-0 m-0">{i18n.t("Title")}</label>
                      <div className="col-12 my-1 px-0">
                          <input type="text" data-field="title" name="title" value={fields.title} onChange={updateValue}
                                 className="form-control" placeholder={i18n.t("Enter Property / Short Description")} />
                      </div>
                  </div>
                  {PARENT_ONLY_PROPERTIES[props.type] === undefined ?
                    <div className="row p-0 m-0 mb-4">
                        <label className="form-check-label col-12 p-0 m-0">{i18n.t("Pricing")}</label>
                        <div className="standard-form-field col-12 p-0 m-0 my-1">
                            {parsed.available_for === "sale" ?
                              <div className="row">
                                  <div className="col-8 pr-1">
                                      <input type="number" data-field="price" name="price" value={fields.price} onChange={updateValue}
                                             className="form-control" placeholder={i18n.t("Price")} required />
                                  </div>
                                  <div className="col-4 pl-1">
                                      <select className="custom-select" data-field="currency" name="currency" value={fields.currency} onChange={updateValue} required>
                                          <option value="" disabled selected>{i18n.t("Currency")}</option>
                                          {currencies.map((currency) => <option value={currency}>{currency}</option>)}
                                      </select>
                                  </div>
                              </div> :

                              <div className="row">
                                  <div className="col-6 pr-1">
                                      <input type="number" data-field="price" name="price" value={fields.price} onChange={updateValue}
                                             className="form-control" placeholder={i18n.t("Price")} required />
                                  </div>
                                  <div className="col-3 px-0">
                                      <select className="custom-select" data-field="currency" name="currency" value={fields.currency} onChange={updateValue} required>
                                          <option value="" disabled selected>{i18n.t("Currency")}</option>
                                          {currencies.map((currency, key) => <option key={key} value={currency}>{currency}</option>)}
                                      </select>
                                  </div>
                                  <div className="col-3 pl-1">
                                      <select className="custom-select" data-field="price_rate_unit" name="price_rate_unit" value={fields.price_rate_unit} onChange={updateValue} required>
                                          <option value="" disabled selected>/{i18n.t("Term")}</option>
                                          {terms.map((term, key) => <option key={key} value={term}>/ {i18n.t(term)}</option>)}
                                      </select>
                                  </div>
                              </div>
                            }
                            {
                                (parsed.available_for === "sale" || parsed.available_for === "rent") ?
                                  <div className="row">
                                      <div className="col-12 pr-1 mt-3">
                                          <input type="number" data-field="amount" name="agent_fee" value={fields.agent_fee}
                                                 onChange={updateValue}
                                                 className="form-control" placeholder={i18n.t("Agent Fee")} required/>
                                      </div>
                                  </div>
                                  :
                                  null
                            }

                            <div className="row p-0 m-0 mt-2">
                                <label className="form-check-label col-12 p-0 m-0">{i18n.t("Payment Terms")}</label>
                                <div className="col-12 p-0 m-0">
                                        <textarea type="text" data-field="payment_terms" name="payment_terms"
                                                  value={fields.payment_terms} onChange={updateValue} rows="2"
                                                  className="form-control" placeholder={i18n.t("Example: 6 months in advance.")} />
                                </div>
                            </div>

                        </div>
                    </div>
                    : null
                  }

                  {!isPositiveInt(parsed.parent_property) ?
                    <div className="my-3 map standard-form-field ">
                        <label className="form-check-label col-12 p-0 m-0">{i18n.t("Location")}</label>
                        <Map required editable search markerDraggable
                             options={{gestureHandling: 'greedy'}}
                             onChangeLocation={updateLocation}
                             location={{
                                 address: fields.location.address,
                                 point: { lng: fields.location.longitude, lat: fields.location.latitude }
                             }} />
                    </div>
                    : null
                  }

                  {child.otherInputs?
                    child.otherInputs(fields, updateFields): null
                  }

                  <div className="standard-form-field my-4">
                      <label className="form-check-label col-12 p-0 m-0">{i18n.t("Amenities")}</label>
                      <div className="row mt-1 mb-3">
                          <div className="col-12">
                              <AsyncSelect closeMenuOnSelect={false} isMulti cacheOptions defaultOptions
                                           components={animatedComponents}
                                           loadOptions={get('amenities')} onChange={update('amenities')}/>
                          </div>
                      </div>

                      <label className="form-check-label col-12 p-0 m-0">{i18n.t("Nearby Services")}</label>
                      <div className="row mt-1 mb-3">
                          <div className="col-12">
                              <AsyncSelect closeMenuOnSelect={false} isMulti cacheOptions defaultOptions
                                           components={animatedComponents}
                                           loadOptions={get('services')} onChange={update('services')}/>
                          </div>
                      </div>
                  </div>

                  <div className="standard-form-field col-12 p-0 m-0 my-4">
                      <FeaturesInput label={i18n.t("Add Other Features")} onChange={updateFeatures} value={fields.other_features} />
                  </div>

                  <label className="form-check-label col-12 p-0 m-0">{i18n.t("Description")}</label>
                  <div className="standard-form-field editor-container">
                      <CKEditor
                        editor={ClassicEditor}
                        data={fields.description}
                        onInit={editor => {
                            // You can store the "editor" and use when it is needed.
                            console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            updateFields(fields => {
                                fields["description"] = data;
                            })
                        }}
                        onBlur={(event, editor) => {
                            console.log('Blur.', editor);
                        }}
                        onFocus={(event, editor) => {
                            console.log('Focus.', editor);
                        }}
                      />
                  </div>

                  {!isPositiveInt(parsed.parent_property) ?
                    <div className="standard-form-field row p-0 m-0 mt-4">
                        <label className="form-check-label col-12 p-0 m-0">{i18n.t("Contact")}</label>
                        <div className="col-12 my-1 px-0">
                            <input type="text" name="phone" value={fields.contact.phone} onChange={updateContact}
                                   className="form-control" placeholder={i18n.t("Phone Number")} required />
                        </div>
                        <div className="col-12 my-1">
                            <div className="row">
                                <div className="col m-0 p-0 pr-1">
                                    <input type="text" name="full_name" value={fields.contact.full_name} onChange={updateContact}
                                           className="form-control" placeholder={i18n.t("Name")} required />
                                </div>
                                <div className="col m-0 p-0 pl-1">
                                    <input type="text" name="email" value={fields.contact.email} onChange={updateContact}
                                           className="form-control" placeholder={i18n.t("Email")} required />
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
                  }
              </div>

              <div className="col-12 col-md-5 mt-4 mt-md-0">
                  <div className="prop-pics-card sticky-top m-0 mb-md-1">
                      <label className="form-check-label col-12 p-0 m-0 mb-1">{i18n.t("Main Picture")}</label>
                      <ImageUploader name="main_picture" onChange={updateMainImage} />
                      <hr className="line p-0 m-0 my-3" />

                      <label className="form-check-label col-12 p-0 m-0 my-1">{i18n.t("Other Pictures")}</label>
                      <MultipleImageUploader name="other_pictures" onChange={updateOtherImages} />
                  </div>
              </div>
          </div>

          <hr className="line p-0 m-0 my-3" />

          <div className="standard-form-field row p-0 m-0 justify-content-center py-2 mt-4">
              <div className="col-12 mb-2 text-center text-danger">
                  {createError}
              </div>
              <Button className="col-12 col-md-6" variant="primary" disabled={isLoading} type="submit">
                  {isLoading ? <Spinner animation="border" size="sm" /> : i18n.t('Submit')}
              </Button>
          </div>
      </form>
    )
}


function UploadRoom(props) {
    return (
      <UploadGenericProperty {...props}>
          {property => ({})}
      </UploadGenericProperty>
    );
}

function UploadHouse(props) {
    return (
      <UploadGenericProperty {...props}>
          {property => ({})}
      </UploadGenericProperty>
    );
}

function UploadApartment(props) {
    return (
      <UploadGenericProperty {...props}>
          {property => ({})}
      </UploadGenericProperty>
    );
}

function UploadOffice(props) {
    return (
      <UploadGenericProperty {...props}>
          {property => ({})}
      </UploadGenericProperty>
    );
}

function UploadLand(props) {
    return (
      <UploadGenericProperty {...props}>
          {property => ({
              otherInputs: function (fields, updateFields) {
                  let updateValue = (e) => {
                      updateFields(fields => {
                          let field = e.target.getAttribute("data-field");
                          let value = e.target.value;
                          fields[field] = value;
                          fields.otherInputs[field] = value;
                      })
                  }

                  let isRegistered = () => {
                      if(fields.is_registered === 'Y'){
                          return true;
                      }
                      return false;
                  }

                  let updateIsRegistered = (e) => {
                      let value = e.target.checked;
                      if (value) {
                          updateFields(fields => {
                              fields['is_registered'] = 'Y'
                              fields.otherInputs['is_registered'] = 'Y'
                          })
                      }
                      else {
                          updateFields(fields => {
                              fields['is_registered'] = 'N'
                              fields.otherInputs['is_registered'] = 'N'
                          })
                      }
                  };

                  return (
                    <>
                        <div className="standard-form-field row p-0 m-0 my-2 my-lg-2">
                            <div className="col m-0 p-0 pr-1">
                                <label className="form-check-label col-12 p-0 m-0">Length</label>
                                <div className="col-12 p-0 m-0 my-1">
                                    <input type="text" data-field="length" name="length" value={fields.length} onChange={updateValue}
                                           className="form-control" placeholder="E.g 120 m" />
                                </div>
                            </div>

                            <div className="col m-0 p-0 pl-1">
                                <label className="form-check-label col-12 p-0 m-0">Width</label>
                                <div className="col-12 p-0 m-0 my-1">
                                    <input type="text" data-field="width" name="width" value={fields.width} onChange={updateValue}
                                           className="form-control" placeholder="E.g 45 m" />
                                </div>
                            </div>
                        </div>

                        <div className="standard-form-field row p-0 m-0 my-2 my-lg-1">
                            <label className="form-check-label col-12 p-0 m-0">Area</label>
                            <div className="col-12 p-0 m-0 my-1">
                                <input type="text" data-field="area" name="area" value={fields.area} onChange={updateValue}
                                       className="form-control" placeholder="E.g 500 square meter" />
                            </div>
                        </div>
                        <div className="row p-0 m-0 my-2 my-lg-2">
                            <label className="form-check-label mt-2" for="is-registered">Is registered</label>
                            <input type="checkbox" className="form-check-input p-0 m-0" id="is-registered" checked={isRegistered()} onChange={updateIsRegistered} />
                        </div>
                    </>
                  );
              }
          })}
      </UploadGenericProperty>
    );
}


function UploadHotel(props) {
    return (
      <UploadGenericProperty {...props}>
          {property => ({})}
      </UploadGenericProperty>
    );
}

function UploadVenue(props) {
    return (
      <UploadGenericProperty {...props}>
          {property => ({})}
      </UploadGenericProperty>
    );
}

function UploadLodge(props) {
    return (
      <UploadGenericProperty {...props}>
          {property => ({})}
      </UploadGenericProperty>
    );
}

function UploadGuestHouse(props) {
    return (
      <UploadGenericProperty {...props}>
          {property => ({})}
      </UploadGenericProperty>
    );
}


const properties = {
    "room": UploadRoom,
    "house": UploadHouse,
    "apartment": UploadApartment,
    "office": UploadOffice,
    "land": UploadLand,
    "venue": UploadVenue,
    "hotel": UploadHotel,
    "hotel_room": UploadRoom,
    "hotel_venue": UploadVenue,
    "hotel_apartment": UploadApartment,
    "lodge": UploadLodge,
    "lodge_room": UploadRoom,
    "guest_house": UploadGuestHouse,
    "guest_house_room": UploadRoom
}

function UploadProperty(props){
    const id = props.id;
    const type = props.type;
    const UploadPropertyComponent = properties[type];
    return <UploadPropertyComponent id={id} type={type}/>
}

export { UploadProperty }
