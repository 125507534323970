import React, {useState} from 'react';
import moment from 'moment'
import { queryCache } from 'react-query';
import { store } from '../store';
import { useLocalState } from '../hooks';
import { BASE_API_URL } from '../';
import {
    DataFetcher, BouncePageLoader, renderPageError, NotFoundError,
    InfoModal
} from './';
import './ReservationDetails.scss';
import { Spinner, Button } from 'react-bootstrap';
import { buildMessageFromResponse, getPropertyRoute, thousandsSeparator } from '../utils';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { PaymentMethods } from './PaymentMethods';
import { Link } from 'react-router-dom';


function propertyPrice(pricing) {
    const cash = `${pricing.currency} ${thousandsSeparator(pricing.amount)}`
    if (pricing.rate_unit) {
        return (
            <span className="price">
                <span className="amount">{cash}</span>
                <span className="forward-slash"> / </span>
                <span className="rate">{pricing.rate_unit}</span>
            </span>
        );
    }
    return (
        <span className="price">
            <span className="amount">{cash}</span>
        </span>
    );
}

function ReservationDetails(props) {
    const reservation = props.reservation;
    const startDate = moment(reservation.check_in_date);
    const endDate = moment(reservation.check_out_date);

    const history = useHistory();
    const [user,] = store.useState("user");
    const [loading, , updateLoading] = useLocalState({
        uncancel: false, cancel: false, pay: false
    })
    const [modalShow, setModalShow] = useState(false);
    const [payerPhone, setPayerPhone] = useState(
        reservation.payment && reservation.payment.payer_phone?
        reservation.payment.payer_phone:
        user.phone
    )

    let reservationStatus = null;
    if (reservation.status === "pending" && !reservation.is_confirmed) {
        reservationStatus = "toBeConfirmed"
    }
    else if (reservation.status === "pending" && reservation.is_confirmed) {
        reservationStatus = "toBePaid"
    }
    else if (reservation.status === "paid" && reservation.is_confirmed) {
        reservationStatus = "paid"
    }
    else if (reservation.status === "canceled" && reservation.is_confirmed) {
        reservationStatus = "hostCanceled"
    }
    else if (reservation.status === "canceled" && !reservation.is_confirmed) {
        reservationStatus = "clientCanceled"
    }

    const uncancel = (event) => {
        event.preventDefault();
        updateLoading(loading => {
            loading.uncancel = true
        });

        const formdata = {
            "reservation": reservation.id
        }

        let uncancelUrl = `${BASE_API_URL}/uncancel-reservation/`
        let headers = {
            'Authorization': `Token ${user.auth_token}`,
            'Content-Type': 'application/json'
        }

        fetch(uncancelUrl, { method: 'POST', body: JSON.stringify(formdata), headers: headers })
            .then(res => res.json().then(data => ({ statusCode: res.status, data })))
            .then(res => {
                if (res.statusCode === 200) {
                    queryCache.invalidateQueries(`reservation/${reservation.id}`);
                }
                else if (res.statusCode === 400) {
                    toast.error(
                        buildMessageFromResponse(res.data)
                    )
                }
            })
            .catch(error => {
                toast.error("No network connection, please try again!.")
            })
            .finally(() => {
                updateLoading(loading => {
                    loading.uncancel = false
                });
            })
    }

    const cancel = (event) => {
        event.preventDefault();
        updateLoading(loading => {
            loading.cancel = true
        });

        const formdata = {
            "reservation": reservation.id,
            "reason": "--"
        }

        let uncancelUrl = `${BASE_API_URL}/cancel-reservation/`
        let headers = {
            'Authorization': `Token ${user.auth_token}`,
            'Content-Type': 'application/json'
        }

        fetch(uncancelUrl, { method: 'POST', body: JSON.stringify(formdata), headers: headers })
            .then(res => res.json().then(data => ({ statusCode: res.status, data })))
            .then(res => {
                if (res.statusCode === 200) {
                    queryCache.invalidateQueries(`reservation/${reservation.id}`);
                }
                else if (res.statusCode === 400) {
                    toast.error(
                        buildMessageFromResponse(res.data)
                    )
                }
            })
            .catch(error => {
                toast.error("No network connection, please try again!.")
            })
            .finally(() => {
                updateLoading(loading => {
                    loading.cancel = false
                });
            })
    }

    const pay = (e) => {
        if (!(payerPhone.match(/\d/g) && payerPhone.length === 10)) {
            toast.error("Enter a valid phone number with 10 digits");
            return
        }

        updateLoading(loading => {
            loading.pay = true
        });

        const formdata = {
            "reservation": reservation.id,
            "payer_phone": payerPhone
        }

        const url = `${BASE_API_URL}/create-reservation-selcom-payment/`
        const headers = {
            'Authorization': `Token ${user.auth_token}`,
            'Content-Type': 'application/json'
        }

        fetch(url, { method: 'POST', body: JSON.stringify(formdata), headers: headers })
            .then(res => res.json().then(data => ({ statusCode: res.status, data })))
            .then(res => {
                if (res.statusCode === 200) {
                    queryCache.invalidateQueries(`reservation/${reservation.id}`);
                    setModalShow(false);
                }
                else if (res.statusCode === 400 || res.statusCode == 503) {
                    toast.error(
                        buildMessageFromResponse(res.data)
                    )
                }
            })
            .catch(error => {
                toast.error("No network connection, please try again!.")
            })
            .finally(() => {
                updateLoading(loading => {
                    loading.pay = false
                });
            })
    }

    const openChat = (e) => {
        history.push(`/conversations/${reservation.properties[0].owner.id}/`)
    }

    const getMainPicture = (property) => {
        let main_img = {is_main: null, src: null, id: null}
        main_img = property.pictures.filter((picture) => picture.is_main);
        if (main_img.length > 0) {
            main_img = main_img[0];
        }
        return main_img
    }

    return (
        <div className="reservation-details row p-0 m-0 pt-4">
            <div className="cost-breakdown col-12 col-lg-6 p-0 m-0 px-4 ">
                <div className="head">
                    Cost Breakdown
                </div>
                {reservation.properties.map(prop =>
                    <div className="row p-0 m-0 reserved-property align-items-center justify-content-center">
                        <div className="pricing col-6 p-0 m-0">
                            {propertyPrice(prop.pricing)}
                        </div>
                        <div className="picture col-6 p-0 m-0 text-right">
                            <Link to={`/${getPropertyRoute(prop.type)}/${prop.id}/`}>
                                <img src={getMainPicture(prop).src} />
                            </Link>
                        </div>
                    </div>
                )}
                <div className="row p-0 m-0 dates-guests-info align-items-center">
                    <div className="col p-0 m-0 text-left">
                        <div className="col-12 p-0 m-0">Check-in</div>
                        <div className="col-12 p-0 m-0 value">{startDate.format("DD MMM Y")}</div>
                    </div>
                    <div className="col p-0 m-0 text-center">
                        <div className="col-12 p-0 m-0">Check-out</div>
                        <div className="col-12 p-0 m-0 value">{endDate.format("DD MMM Y")}</div>
                    </div>
                    <div className="col p-0 m-0 text-right">
                        <div className="col-12 p-0 m-0">Guests</div>
                        <div className="col-12 p-0 m-0 value">1 Guest</div>
                    </div>
                </div>
                <div className="row p-0 m-0 total-cost align-items-center">
                    <div className="col p-0 m-0 text-left">Total</div>
                    <div className="col p-0 m-0 text-right value">
                        {`${reservation.currency} ${thousandsSeparator(reservation.cost)}`}
                    </div>
                </div>
            </div>


            <div className="payments col-12 col-lg-6 p-0 m-0 px-4 mt-5 mt-lg-0 ">
                <div className="head">
                    Payments
                </div>
                <div className="details">
                    {reservationStatus === "toBeConfirmed" ?
                        <>
                            Waiting for your reservation to be confirmed by the agent,
                            we'll let you know when it's done, for you to proceed
                            with the payment.
                        </> : null
                    }
                    {reservationStatus === "toBePaid" ?
                        <>
                            {reservation.payment && reservation.payment.status === "pending" ?
                                <PaymentMethods payment={reservation.payment} /> :
                                <>This reservation is confirmed, you can now continue with the payment.</>
                            }
                        </> : null
                    }
                    {reservationStatus === "paid" ?
                        <>
                            You have already paid for this reservation, you can now meet with the
                            agent to proceed with the process of viewing a property.
                        </> : null
                    }
                    {reservationStatus === "hostCanceled" ?
                        <>
                            Your agent has declined this reservation.
                        </> : null
                    }
                    {reservationStatus === "clientCanceled" ?
                        <>
                            You canceled this reservation.
                        </> : null
                    }
                </div>
            </div>

            {reservationStatus === "hostCanceled" ? null :
                <div className="reservation-actions row p-0 m-0">
                    {reservationStatus === "toBeConfirmed" ?
                        <>
                            <div className="col-12 text-center">
                                <div className="cancel btn col-12 col-lg-6" onClick={cancel} disabled={loading.cancel}>
                                    {loading.cancel ? <Spinner animation="border" size="sm" /> : 'Cancel Reservation'}
                                </div>
                            </div>
                        </> : null
                    }
                    {reservationStatus === "toBePaid" ?
                        <>
                            <div className="col text-center">
                                <div className="cancel btn col-12 col-lg-6" onClick={cancel} disabled={loading.cancel}>
                                    {loading.cancel ? <Spinner animation="border" size="sm" /> : 'Cancel'}
                                </div>
                            </div>
                            {reservation.payment && reservation.payment === "pending" ?
                                null :
                                <div className="col text-center">
                                    <div className="pay btn col-12 col-lg-6" onClick={(e) => setModalShow(true)}>
                                        Pay Now
                                    </div>

                                    <InfoModal className="payments" positionBottom header="Confirm your number" modalShow={modalShow} setModalShow={setModalShow}>
                                        <div className="payment-form row pt-5 pb-3 justify-content-center">
                                            <div className="col-10 px-2 floating">
                                                <input value={payerPhone} onChange={ e => setPayerPhone(e.target.value)}
                                                    type="number" name="phone" className="form-control floating__input"
                                                    placeholder="Phone" required/>
                                                <label htmlFor="phone" className="floating__label" data-content="Phone"></label>
                                            </div>
                                            <div className="col-10 px-2">
                                                <Button className="pay-btn col-12 mt-5" variant="primary" disabled={loading.pay} onClick={pay}>
                                                    {loading.pay ? <Spinner animation="border" size="sm" /> : 'Continue'}
                                                </Button>
                                            </div>
                                        </div>
                                    </InfoModal>
                                </div>
                            }
                        </> : null
                    }
                    {reservationStatus === "paid" ?
                        <>
                            <div className="col-12 text-center">
                                <div className="send-message btn col-12 col-lg-6" onClick={openChat}>
                                    {loading.cancel ? <Spinner animation="border" size="sm" /> : 'Send Message To Host'}
                                </div>
                            </div>
                        </> : null
                    }
                    {reservationStatus === "clientCanceled" ?
                        <>
                            <div className="col-12 text-center">
                                <div className="uncancel btn col-12 col-lg-6" onClick={uncancel} disabled={loading.uncancel}>
                                    {loading.uncancel ? <Spinner animation="border" size="sm" /> : 'Uncancel Reservation'}
                                </div>
                            </div>
                        </> : null
                    }
                </div>
            }
        </div>
    );
}

function ReservationDetailsFetcher(props) {
    const [user,] = store.useState("user");

    const headers = {
        Authorization: `Token ${user.auth_token}`,
        "Content-Type": "application/json",
    }

    let fetchReservation = () => {
        return fetch(`${BASE_API_URL}/reservations/${props.id}/`, { headers: headers })
            .then(res => res.json().then(data => ({ statusCode: res.status, data })))
    }

    return (
        <DataFetcher action={fetchReservation} selection={`reservation/${props.id}`}
            placeholder={<BouncePageLoader />} onError={renderPageError}>{response => {
                if (response.data.statusCode === 404) {
                    return <NotFoundError />
                }
                return <ReservationDetails reservation={response.data.data} />
            }}</DataFetcher>
    )
}


export { ReservationDetailsFetcher as ReservationDetails }
