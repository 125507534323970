import React, {useState} from "react";
import {
    GoogleMap, useLoadScript, Marker,
    InfoWindow
} from "@react-google-maps/api";
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";
import {
    Combobox, ComboboxInput, ComboboxPopover,
    ComboboxList, ComboboxOption,
} from "@reach/combobox";
import markerIcon from '../images/location-marker.svg';
// import {SvgComponent as markerIcon} from './svg/SvgComponent';

import "./Map.scss";
import "@reach/combobox/styles.css";
import { useGeolocationErrorsLogger } from "../hooks";
import i18n from "i18next";
import mapStyles from "./mapStyles";

const libraries = ["places"];

const options = {
    styles: mapStyles, // Change map style
    disableDefaultUI: true,
    fullscreenControl: false,
    zoomControl: false,
    gestureHandling: 'none'
};


function Locate({ panTo }) {
    return (
        <button
            className="locate"
            onClick={(e) => {
                e.preventDefault();
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        panTo({
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        });
                    },
                    () => null
                );
            }}
        >
            <img src="/compass.svg" alt="compass" />
        </button>
    );
}


function Search(props) {
    const [loading, setLoading] = useState(false);
    const logError = useGeolocationErrorsLogger(true);
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            location: {
                lat: () => props.location.point.lat,
                lng: () => props.location.point.lng
            },
            radius: 100 * 1000,
        },
    });

    React.useEffect(function () {
        setValue(props.location.address, false);
    }, [props.location])

    const handleInput = (e) => {
        setValue(e.target.value);  // Make API call
    };

    const handleSelect = async (address) => {
        setValue(address, false);  // Don't make API call
        clearSuggestions();

        try {
            const results = await getGeocode({ address });
            const { lat, lng } = await getLatLng(results[0]);

            const point = { lat, lng }
            props.panTo(point);

            if (props.onSelectingSearchResult) {
                props.onSelectingSearchResult({ point, address });
            }
        } catch (error) {
            console.log("😱 Error: ", error);
        }
    };

    const setLocation = async (position) => {
        const selectedPoint = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
        };

        try {
            const results = await getGeocode({ location: selectedPoint });
            const { lat, lng } = await getLatLng(results[0]);

            const point = { lat, lng };
            const address = results[0].formatted_address
            props.panTo(point);

            const selectedLocation = { address, point }

            if (props.setLocation) {
                props.setLocation(selectedLocation)
            }
        } catch (error) {
            console.log("😱 Error: ", error);
        } finally {
            setLoading(false);
        }
    }

    const setCurrentLocation = (e) => {
        setLoading(true);
        e.preventDefault();
        navigator.geolocation.getCurrentPosition(
            setLocation,
            (error) => {
                setLoading(false);
                logError(error)
            }
        );
    }

    const showLoading = () => {
        if(loading) {
            return "load-location"
        }
        return ""
    };

    return (
        <div className="search">
            <Combobox onSelect={handleSelect}>
                <ComboboxInput
                    value={value}
                    onChange={handleInput}
                    disabled={!ready}
                    autoComplete="off"
                    type="search"
                    className="location"
                    placeholder={i18n.t("Search your location")}
                    required={props.required}
                />
                <div className="current-location" data-toggle="tooltip" onClick={setCurrentLocation}
                    data-placement="bottom" title="Click to get your current location">
                    <span className={`icon icon-marker ${showLoading()}`} />
                </div>
                {status === "OK" && data ?
                    <ComboboxPopover className="map-search-suggestions-box">
                        <ComboboxList>
                            {data.map(({ id, description }) => (
                                <ComboboxOption key={id} value={description} />
                            ))}
                        </ComboboxList>
                    </ComboboxPopover> : null
                }
            </Combobox>
        </div>
    );
}


function Map(props) {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });

    const [location, setLocation] = React.useState(props.location);
    const [showInfoWindow, setShowInfoWindow] = React.useState(props.showInfoWindow);
    const [mapContainerClassName, setMapContainerClassName] = React.useState("google-map-min-screen");

    const handleLocationChange = async (e) => {
        if (!props.editable) {
            return;
        }
        const selectedPoint = {
            lat: e.latLng.lat(),
            lng: e.latLng.lng()
        };

        try {
            const results = await getGeocode({ location: selectedPoint });
            const { lat, lng } = await getLatLng(results[0]);

            const point = { lat, lng };
            const address = results[0].formatted_address;
            panTo(point);

            const selectedLocation = { address, point }

            setLocation(selectedLocation);
            if (props.onChangeLocation) {
                props.onChangeLocation(selectedLocation);
            }
        } catch (error) {
            console.log("😱 Error: ", error);
        }
    };

    const updateLocation = (selectedLocation) => {
        setLocation(selectedLocation);
        if (props.onChangeLocation) {
            props.onChangeLocation(selectedLocation);
        }
    }

    const mapRef = React.useRef();
    const onMapLoad = React.useCallback((map) => {
        mapRef.current = map;
    }, []);

    const panTo = React.useCallback(({ lat, lng }) => {
        mapRef.current.panTo({ lat, lng });
        mapRef.current.setZoom(15);
    }, []);

    if (loadError) return "Failed to load Map";
    if (!isLoaded) return "Loading Map...";

    const showMap = () => {
        if (location.point.lng && location.point.lat) {
            return ""
        }
        return "d-none"
    }

    const openNavigationApp = () => {
        if /* if we're on iOS, open in Apple Maps */
            ((navigator.platform.indexOf("iPhone") !== -1) ||
            (navigator.platform.indexOf("iPod") !== -1) ||
            (navigator.platform.indexOf("iPad") !== -1)) {

            window.open(
                `maps://maps.google.com/maps?saddr=&daddr=${location.address}&ll=`
            ).close();
        } else {
            /* else use Google */
            window.open(
                `https://maps.google.com/maps?saddr=&daddr=${location.address}&ll=`
            );
        }
    }

    const toggleMapSize = () => {
        if(mapContainerClassName === "google-map-min-screen"){
            setMapContainerClassName("google-map-full-screen");
        }
        else {
            setMapContainerClassName("google-map-min-screen")
        }
    }

    const getScreenSizeIcon = () => {
        if(mapContainerClassName === "google-map-min-screen"){
            return "icon-full-screen"
        }
        return "icon-minimum-screen"
    }

    return (
        <div>
            {props.search ?
                <>
                    <Search
                    setLocation={updateLocation}
                    location={location}
                    onSelectingSearchResult={updateLocation}
                    panTo={panTo} required={props.required}/>
                    {props.showCompass ?
                        <Locate panTo={panTo} /> :
                        null
                    }
                </> : null
            }

            <span className={`${showMap()} map-container`}>
                <GoogleMap
                    id="google-map"
                    mapContainerStyle={props.style}
                    mapContainerClassName={mapContainerClassName}
                    zoom={15}
                    center={location.point}
                    options={{options, ...props.options}}
                    onDblClick={handleLocationChange}
                    onLoad={onMapLoad}>

                    <div className="toggle-map-size-btn" onClick={toggleMapSize}>
                        <span className={`icon ${getScreenSizeIcon()}`} />
                    </div>
                    {props.showNavigationBtn ?
                        <div className="navigation-btn" onClick={openNavigationApp}>
                            <span className="icon icon-navigation-solid" />
                        </div> : null
                    }

                    {showInfoWindow ?
                        <InfoWindow
                            position={location.point}
                            onCloseClick={(e) => { setShowInfoWindow(false) }}
                        >
                            <div className="info-window text-center">
                                <span className="icon icon-house"></span> <br />
                                {location.address}
                            </div>
                        </InfoWindow> :
                        null
                    }

                    <Marker
                        draggable={props.markerDraggable}
                        options={{
                            icon: markerIcon,
                        }}
                        position={location.point}
                        onDragEnd={handleLocationChange}
                        onClick={() => {
                            setShowInfoWindow(true);
                        }}
                    />
                    { /*
                    <Circle
                        center={location.point}
                        options={{
                            strokeColor: 'black',
                            strokeOpacity: 0,
                            strokeWeight: 2,
                            fillColor: 'black',
                            fillOpacity: 0.3,
                            clickable: false,
                            draggable: false,
                            editable: false,
                            visible: true,
                            radius: 120,
                            zIndex: 1
                        }}
                    />
                    */}

                </GoogleMap>
            </span>
        </div>
    );
}

export { Map }
