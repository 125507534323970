import React from 'react';
import './Home.css';
import {
    GenericFilter, BounceBlockLoader, BouncePageLoader, renderPageError,
    renderInlineError, TwoRowsPropertiesGroup, SliderPropertiesGroup,
    PROPERTIES_QUERY_PARAM
} from './';
import { usePageTransition, useRestoreScrollState, useUserLocation } from '../hooks';
import {useTranslation} from "react-i18next";


function NearByProperties(props) {
    const { location } = useUserLocation();
    if(location === null){
        return null;
    }

    const nearbyPropertiesEndpoint = `
    nearby-properties/?${PROPERTIES_QUERY_PARAM}
    &longitude=${location.coords.longitude}
    &latitude=${location.coords.latitude}
    &radius_to_scan=5000
    `;

    return (
        <GenericFilter endpoint={nearbyPropertiesEndpoint} global selection="userNearbyProperties"
            placeholder={<BounceBlockLoader />} onError={renderInlineError}>{response => {
                let data = response.data[0];
                if (data.results.length < 3) {
                    return null;
                }
            return (
                    <div className="p-0 m-0 mt-4">
                        <SliderPropertiesGroup
                            selection="userNearbyProperties"
                            header={props.t('Nearby Properties')}
                            viewAllTitle={props.t('View All')}
                            viewAllLink="/my-nearby-properties"
                            response={response}
                        />
                    </div>
                );
            }}
        </GenericFilter>
    );
}


function Home(props) {
    useRestoreScrollState();
    const animate = usePageTransition();

    const propertiesToRentEndpoint = `properties/?${PROPERTIES_QUERY_PARAM}&available_for=rent`
    const propertiesToBuyEndpoint = `properties/?${PROPERTIES_QUERY_PARAM}&available_for=sale`
    const propertiesToSlideEndpoint = `properties/?${PROPERTIES_QUERY_PARAM}&price__lt=800000`
    const { t } = useTranslation();
    return (
        <GenericFilter endpoint={propertiesToRentEndpoint} global selection="propertiesToRent"
            placeholder={<BouncePageLoader />} onError={renderPageError}>{response => {
                let properties = response.data[0];
                let footer = `${t('Show all')} (${properties.count}+)`;
                let footerLink = "/explore/rent-properties/";
                return (
                    <div className={`${animate()}`}>
                        <div className="p-0 m-0 px-1 px-sm-3 mt-2 mt-md-3">
                            <TwoRowsPropertiesGroup header={t('Rent a place')} properties={properties}
                                footer={footer} footerLink={footerLink} />
                        </div>

                        <GenericFilter endpoint={propertiesToSlideEndpoint} global selection="propertiesToSlide"
                            placeholder={<BounceBlockLoader />} onError={renderInlineError}>{response => {
                                return (
                                    <div className="p-0 m-0 mt-4">
                                        <SliderPropertiesGroup
                                            selection="propertiesToSlide"
                                            header={t('Amazing places')}
                                            response={response}
                                            viewAllTitle={t('View All')}
                                            viewAllLink="/explore/rent-properties/"
                                        />
                                    </div>
                                );
                            }}
                        </GenericFilter>

                        <GenericFilter endpoint={propertiesToBuyEndpoint} global selection="propertiesToBuy"
                            placeholder={<BounceBlockLoader />} onError={renderInlineError}>{response => {
                                let properties = response.data[0];
                                let footer = `${t('Show all')} (${properties.count}+)`;
                                let footerLink = "/explore/buy-properties/";
                                return (
                                    <div className="p-0 m-0 px-1 px-sm-3 mt-4">
                                        <TwoRowsPropertiesGroup header={t('Buy a place')} properties={properties}
                                            footer={footer} footerLink={footerLink} />
                                    </div>
                                );
                            }}
                        </GenericFilter>

                        <NearByProperties t={t}/>
                    </div>
                );
            }}
        </GenericFilter>
    );
}

export { Home }
