import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useLocalState } from '../hooks';
import './PropertiesFilter.scss';
import variables from '../variables.scss';
import { BASE_API_URL } from '../';
import { AsyncSelect } from './'
import { NON_STANDALONE_PROPERTY_NAMES, STANDALONE_PROPERTY_NAMES } from '../utils';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";
import {
    Combobox, ComboboxInput, ComboboxPopover,
    ComboboxList, ComboboxOption,
} from "@reach/combobox";
import { useLoadScript } from "@react-google-maps/api";
import { useGeolocationErrorsLogger } from '../hooks';
import {useTranslation} from "react-i18next";
import { parse } from 'query-string';
import { renderInlineError } from './Errors';
import { DataFetcher } from './Fetchers';


const libraries = ["places"];

const PriceRangeSlider = withStyles({
    root: {
        color: variables.primaryColor,
        height: 8,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -8,
        //marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider);


function Search(props) {
    const [loading, setLoading] = useState(false);
    const logError = useGeolocationErrorsLogger(true);
    const {
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        defaultValue: props.defaultValue
    });

    const handleSelect = async (address) => {
        setValue(address, false);  // Don't make API call
        clearSuggestions();

        try {
            const results = await getGeocode({ address });
            const { lat, lng } = await getLatLng(results[0]);
            if(props.onChange) {
                props.onChange({
                    address: address,
                    point: {latitude: lat, longitude: lng}
                })
            }
        } catch (error) {
            console.log("😱 Error: ", error);
        }
    };

    const handleInputChange = (e) => {
        if (props.simple) {
            setValue(e.target.value, false);  // Don't make API call
        }
        else {
            setValue(e.target.value);  // Make API call
        }
    };

    const setLocation = async (position) => {
        const selectedPoint = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
        };

        try {
            const results = await getGeocode({ location: selectedPoint });
            const { lat, lng } = await getLatLng(results[0]);

            const point = { latitude: lat, longitude: lng };
            const address = results[0].formatted_address
            setValue(address, false);  // Don't make API call
            clearSuggestions();
            if(props.onChange){
                props.onChange({
                    address: address,
                    point: point
                })
            }
        } catch (error) {
            console.log("😱 Error: ", error);
        } finally {
            setLoading(false);
        }
    }

    const setCurrentLocation = (e) => {
        setLoading(true);
        e.preventDefault();
        navigator.geolocation.getCurrentPosition(
            setLocation,
            (error) => {
                setLoading(false);
                logError(error);
            }
        );
    }

    const showLoading = () => {
        if(loading){
            return "load-location"
        }
        return ""
    }

    return (
            <Combobox onSelect={handleSelect} className="location-container col-12 p-0 m-0">
                <ComboboxInput value={value} onChange={handleInputChange} autoComplete="off"
                    name="location" type="search" placeholder={props.t('City, Region or Street')}
                    className="location col-12"/>

                <div className="current-location" data-toggle="tooltip" onClick={setCurrentLocation}
                    data-placement="bottom" title={props.t('Click to get your current location')}>
                    <span className={`icon icon-marker ${showLoading()}`} />
                </div>

                {status === "OK" && data ?
                    <ComboboxPopover className="location-suggestions">
                        <ComboboxList>
                            {data.map(({ id, description }) => (
                                <ComboboxOption key={id} value={description} />
                            ))}
                        </ComboboxList>
                    </ComboboxPopover> : null
                }
            </Combobox>
    );
}

function SimpleSearch(props){
    return <Search {...props} simple/>
}

function AdvancedSearch(props){
    return <Search {...props}/>
}


function getOptions(url) {
    return fetch(url)
        .then(res => res.json())
        .then(results => results.results.map(
            option => { return { value: option.id, label: option.name } }
        ))
}

function Amenities(props){
    const [amenities, setAmenities] = useState(props.value);

    const getAmenities = inputValue => {
        const URL = `${BASE_API_URL}/amenities/?query={id,name}&format=json&name__icontains=${inputValue}`
        return getOptions(URL)
    }

    const handleChange = (newVal) => {
        setAmenities(newVal);
        props.onChange(newVal);
    }

    return (
        <AsyncSelect isMulti cacheOptions
            defaultOptions value={amenities}
            loadOptions={getAmenities} onChange={handleChange} />
    );
}


function PropertiesFilter(props) {
    const history = useHistory();
    const parsed = parse(history.location.search);
    const [filterFields, ,updateFilterFields] = useLocalState(parsed);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });

    const updateFieldValue = (e) => {
        const field = e.target.name;
        const value = e.target.value;
        updateFilterFields(filter => {
            filter[field] = value;
        });
    }

    const updateLocation = (location) => {
        updateFilterFields(filter => {
            filter["address"] = location.address
            filter["longitude"] = location.point.longitude
            filter["latitude"] = location.point.latitude
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const params = []
        for(let key in filterFields){
            if(key && filterFields[key] !== undefined && filterFields[key] !== null){
                params.push(`${key}=${filterFields[key]}`);
            }
        }
        const path = `/global-search/?${params.join("&")}`
        history.push(path);
        if(props.onFinish) {
            props.onFinish()
        }
    }

    const updateAmenities = (amenities) => {
        if (!amenities) {
            amenities = []
        }
        updateFilterFields(filter => {
            filter['amenities__subset'] = amenities.map(amenity => amenity.value).toString()
        })
    }

    const fetchAmenities = () => {
        const URL = `${BASE_API_URL}/amenities/?query={id,name}&format=json&id__in=${parsed.amenities__subset||"0"}`;
        return getOptions(URL)
    }

    const handlePriceRangeChange = (event, newValue) => {
        updateFilterFields(filter => {
            filter['pricing__amount__gt'] = newValue[0];
            filter['pricing__amount__lt'] = newValue[1];
        });
    };

    let propertyNames = {
        ...STANDALONE_PROPERTY_NAMES,
        ...NON_STANDALONE_PROPERTY_NAMES
    }

    let propertyTypes = [] 

    for(let name in propertyNames){
        propertyTypes.push(name);
    }

    const { t } = useTranslation();

    return (
        <div className={`properties-filter text-secondary p-0 m-0 px-2 px-lg-3 pt-2`}>
            <form id="filter-form" className="standard-form p-0 m-0 px-2 px-lg-3" onSubmit={handleSubmit}>
                <label className="form-check-label p-0 m-0 m-0 p-0 mt-1"> {t('Price Range')}</label>
                <div className="row col-12 p-0 m-0 mt-1">
                    <div className="p-0 m-0 col-5">
                        <label className="form-check-label col-12 p-0 m-0"> {t('Min')}</label>
                        <input type="number" name="pricing__amount__gt" className="form-control number-input"
                            value={filterFields.pricing__amount__gt} onChange={updateFieldValue}
                            placeholder="Min price" />
                    </div>
                    <div className="col-2 p-0 m-0 px-2"><hr className="line-separator"/></div>
                    <div className="p-0 m-0 col-5">
                        <label className="form-check-label col-12 p-0 m-0"> {t('Max')}</label>
                        <input type="number" name="pricing__amount__lt" className="form-control number-input"
                            value={filterFields.pricing__amount__lt} onChange={updateFieldValue}
                            placeholder="Max price" />
                    </div>
                </div>

                <div className="price-range p-0 m-0 mt-3">
                    <PriceRangeSlider
                        className="range-input"
                        value={[
                            filterFields.pricing__amount__gt === ""||
                            filterFields.pricing__amount__gt === undefined?
                                50000: filterFields.pricing__amount__gt,

                            filterFields.pricing__amount__lt === ""||
                            filterFields.pricing__amount__lt === undefined?
                                950000: filterFields.pricing__amount__lt
                        ]}
                        max={1000000}
                        min={0}
                        step={1000}
                        onChange={handlePriceRangeChange}
                        valueLabelDisplay="off"
                        aria-labelledby="range-slider" />
                </div>

                <div className="m-0 p-0">
                    <label className="form-check-label col-12 p-0 m-0"> {t('Currency')}</label>
                    <select className="custom-select" name="pricing__currency" value={filterFields.pricing__currency} onChange={updateFieldValue}>
                        <option value="">{t('All')}</option>
                        <option value="TZS">TZS</option>
                        <option value="USD">USD</option>
                    </select>
                </div>

                <div className="m-0 p-0 mt-5 mt-lg-4">
                    <label className="form-check-label col-12 p-0 m-0">{t('Location')}</label>
                { isLoaded ?
                    <AdvancedSearch defaultValue={filterFields.address} onChange={updateLocation}  t={t}/>:
                    <SimpleSearch defaultValue={filterFields.address} onChange={updateLocation} t={t} />
                }
                </div>

                <div className="m-0 p-0 mt-2 mb-2">
                    <label className="form-check-label col-12 p-0 m-0">{t('Amenities')}</label>
                    <div className="row mt-1 mb-3">
                        <div className="col-12">
                            <DataFetcher action={fetchAmenities} selection={`amenities/${parsed.amenities__subset}`}
                                placeholder={<AsyncSelect isMulti isLoading />}
                                onError={renderInlineError}>{response => {
                                    return <Amenities value={response.data} onChange={updateAmenities} />
                            }}</DataFetcher>
                        </div>
                    </div>

                </div>
                <button type="submit" className="col-12 btn btn-primary mt-3 my-md-2 py-2 py-md-1">
                    {t('Search')}
                </button>
            </form>
        </div>
    );
}


export { PropertiesFilter }
