import React, {useState, useEffect} from 'react';
import './UserSettings.scss';
import { useHistory, MemoryRouter, Route } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import {
    InfoModal
} from './';
import {
    DataFetcher, BouncePageLoader, renderPageError
} from './';
import { BASE_API_URL } from '../';
import { store, initializeStore } from '../store';
import { buildMessageFromResponse, deleteUserInfoFromCookies, saveUserInfoToCookies, setErrorClass } from '../utils';
import { LogInToViewSaved } from './LogInToViewSaved';
import { queryCache } from 'react-query';
import { toast } from 'react-toastify';
import { DocPictureUploader } from './DocPictureUploader';


function Setting(props) {
    return (
        <div className={`setting row p-0 m-0 w-100 ${props.className||''}`} onClick={props.onClick}>
            {props.icon ?
                <div className="label-icon col-1 p-0 m-0 text-center">
                    <span className={`icon ${props.icon}`} />
                </div> : null
            }
            <div className="info col p-0 m-0 px-2 px-md-0">
                <div className="title col-12 p-0 m-0">
                    {props.title}
                </div>
                {props.description ?
                    <div className="description col-12 p-0 m-0 mt-1">
                        {props.description}
                    </div> : null
                }
            </div>
            <div className="arrow-icon col-1 p-0 m-0 text-center">
                { !props.notLink ?
                    <span className="icon icon-next" />: null
                }
            </div>
        </div>
    );
}


function EditPassword(props) {
    const [user,] = store.useState("user");
    const history = useHistory();
    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [newPasswordConfirm, setNewPasswordConfirm] = useState("")
    const [errors, setErrors] = useState("");
    const [isLoading, setLoading] = useState(false);

    useEffect(setErrorClass, []);

    const updateOldPassword = (e) => {
        setOldPassword(e.target.value)
    }

    const updateNewPassword = (e) => {
        setNewPassword(e.target.value)
    }

    const updateNewPasswordConfirm = (e) => {
        setNewPasswordConfirm(e.target.value)
    }

    let redirect = (response) => {
        if (response.status !== 200) {
            const errorMsg = buildMessageFromResponse(response.data);
            setErrors(errorMsg);
        }
        else {
            let userInfo = {
                id: response.id,
                auth_token: response.token
            }
            deleteUserInfoFromCookies(["id", "auth_token"])
            saveUserInfoToCookies(userInfo);
            queryCache.invalidateQueries(`/user/${response.data.id}`);
            history.push(`/users/${response.data.id}`)
            window.location.reload()
        }
    }

    const submit = (e) => {
        e.preventDefault();
        setErrors("");
        setLoading(true);
        const formdata = JSON.stringify({
            "old_password": oldPassword,
            "new_password1": newPassword,
            "new_password2": newPasswordConfirm
        })

        const headers = {
            'Authorization': `Token ${user.auth_token}`,
            'Content-Type': 'application/json'
        }

        const registerUrl = `${BASE_API_URL}/change-password/`
        fetch(registerUrl, { method: 'POST', body: formdata, headers: headers })
            .then(res => res.json().then(data => ({ status: res.status, data: data })))
            .then(res => redirect(res))
            .catch(error => {
                // Network error
                setErrors("No network connection, please try again!.");
            })
            .finally(() => {
                // Enable button
                setLoading(false);
            });
    }

    return (
        <div className="row change-password p-0 m-0 mt-2 mt-md-3">
            <div className="row header p-0 m-0 px-2 px-sm-3">Change Password</div>

            <form className="row w-100 standard-form m-0 p-0 px-0 px-lg-2 mt-2" onSubmit={submit}>
                <div className="col-12 col-lg-6 p-0 m-0 px-2">
                    <div className="col-12 p-0 m-0 px-3 mt-3">
                        <label className="form-check-label col-12 p-0 m-0">Old password</label>
                        <input type="password" name="oldPassword" className="form-control" required
                            value={oldPassword} onChange={updateOldPassword} />
                    </div>
                    <div className="col-12 p-0 m-0 px-3 mt-3">
                        <label className="form-check-label col-12 p-0 m-0">New password</label>
                        <input type="password" name="newPassword" className="form-control" required
                            value={newPassword} onChange={updateNewPassword} />
                    </div>
                    <div className="col-12 p-0 m-0 px-3 mt-3">
                        <label className="form-check-label col-12 p-0 m-0">Confirm new password</label>
                        <input type="password" name="newPasswordConfirm" className="form-control" required
                            value={newPasswordConfirm} onChange={updateNewPasswordConfirm} />
                    </div>

                    {errors ?
                        <div className="col-12 p-0 m-0 px-4 text-danger text-center mt-3 mt-lg-3">
                            {errors}
                        </div> : null
                    }
                    <div className="col-12 p-0 m-0 px-3 mt-4 pt-2">
                        <button type="submit" disabled={isLoading} className="col-12 btn btn-primary my-md-2 py-2 py-md-1">
                            {isLoading ? <Spinner animation="border" size="sm" /> : 'Submit'}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}


function EditEmail(props) {
    const user = props.user;
    const [authUser,] = store.useState("user");
    const history = useHistory();
    const [errors, setErrors] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [newEmail, setNewEmail] = useState("")
    const [password, setPassword] = useState("")

    useEffect(setErrorClass, []);

    const updateNewEmail = (e) => {
        setNewEmail(e.target.value)
    }

    const updatePassword = (e) => {
        setPassword(e.target.value)
    }

    let redirect = (response) => {
        if (response.status !== 200) {
            const errorMsg = buildMessageFromResponse(response.data);
            setErrors(errorMsg);
        }
        else {
            queryCache.invalidateQueries(`/user/${response.data.id}`);
            history.push(`/users/${response.data.id}`)
            window.location.reload()
        }
    }

    const submit = (e) => {
        e.preventDefault();
        setErrors("");
        setLoading(true);
        const formdata = JSON.stringify({
            "new_email": newEmail,
            "password": password
        })

        const headers = {
            'Authorization': `Token ${authUser.auth_token}`,
            'Content-Type': 'application/json'
        }

        const registerUrl = `${BASE_API_URL}/change-email/`
        fetch(registerUrl, { method: 'POST', body: formdata, headers: headers })
            .then(res => res.json().then(data => ({ status: res.status, data: data })))
            .then(res => redirect(res))
            .catch(error => {
                // Network error
                setErrors("No network connection, please try again!.");
            })
            .finally(() => {
                // Enable button
                setLoading(false);
            });
    }

    return (
        <div className="row change-email p-0 m-0 mt-2 mt-md-3">
            <div className="row header p-0 m-0 px-2 px-sm-3">Change Email</div>

            <form className="row w-100 standard-form m-0 p-0 px-0 px-lg-2 mt-2" onSubmit={submit}>
                <div className="col-12 col-lg-6 p-0 m-0 px-2">
                    <div className="col-12 p-0 m-0 px-3 mt-3">
                        <label className="form-check-label col-12 p-0 m-0">Current email</label>
                        <div>{user.email}</div>
                    </div>
                    <div className="col-12 p-0 m-0 px-3 mt-3">
                        <label className="form-check-label col-12 p-0 m-0">New email</label>
                        <input type="text" name="newEmail" className="form-control"
                            value={newEmail} onChange={updateNewEmail} />
                    </div>
                    <div className="col-12 p-0 m-0 px-3 mt-3">
                        <label className="form-check-label col-12 p-0 m-0">Password</label>
                        <input type="password" name="password" className="form-control"
                            value={password} onChange={updatePassword} />
                    </div>

                    {errors ?
                        <div className="col-12 p-0 m-0 px-4 text-danger text-center mt-3 mt-lg-3">
                            {errors}
                        </div> : null
                    }

                    <div className="col-12 p-0 m-0 px-3 mt-4 pt-2">
                        <button type="submit" disabled={isLoading} className="col-12 btn btn-primary my-md-2 py-2 py-md-1">
                            {isLoading ? <Spinner animation="border" size="sm" /> : 'Submit'}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}


function RequestVerification(props) {
    const user = props.user;
    const [authUser,] = store.useState("user");
    const [errors, setErrors] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [fullName, setFullName] = useState(user.full_name)
    const [documentType, setDocumentType] = useState("")
    const [documentPicture, setDocumentPicture] = useState(null)


    useEffect(setErrorClass, []);

    const updateFullName = (e) => {
        setFullName(e.target.value)
    }

    const updateDocumentType = (e) => {
        setDocumentType(e.target.value)
    }

    const updateDocumentPicture = (value) => {
        setDocumentPicture(value)
    }

    const redirect = (response) => {
        if (response.status !== 201) {
            const errorMsg = buildMessageFromResponse(response.data);
            setErrors(errorMsg);
        }
        else {
            // Hope we didn't cache user verification since it 
            // was not yet created
            queryCache.invalidateQueries(`users/${user.id}`);
            window.location.reload()
        }
    }

    const uploadDocument = async (response) => {
        if(response.status !== 201){
            // Report error
            return response
        }

        const verificationID = response.data.id;
        const postData = new FormData();
        postData.append("user_verification", verificationID)
        postData.append("type", documentType)
        postData.append("src", documentPicture, documentPicture.name)

        const postUrl = `${BASE_API_URL}/user-verification-documents/`;
        const headers = {
            'Authorization': `Token ${authUser.auth_token}`
        }
        return fetch(postUrl, {method: 'POST', body: postData, headers: headers})
        .then(res =>  res.json().then(data => ({status: res.status, data: data})))
    }

    const submit = (e) => {
        e.preventDefault();
        setErrors("");
        setLoading(true);
        const formdata = JSON.stringify({
            "full_name": fullName
        })

        const headers = {
            'Authorization': `Token ${authUser.auth_token}`,
            'Content-Type': 'application/json'
        }

        const URL = `${BASE_API_URL}/user-verifications/`
        fetch(URL, { method: 'POST', body: formdata, headers: headers })
            .then(res => res.json().then(data => ({ status: res.status, data: data })))
            .then(res => uploadDocument(res))
            .then(res => redirect(res))
            .catch(error => {
                // Network error
                setErrors("No network connection, please try again!.");
            })
            .finally(() => {
                // Enable button
                setLoading(false);
            });
    }

    const documentTypes = [
        "Passport",
        "Driver's license",
        "National identification card"
    ]

    return (
        <div className="row request-verification p-0 m-0 mt-2 mt-md-3">
            <div className="row header p-0 m-0 px-2 px-sm-3">Request Verification</div>

            <form className="row w-100 standard-form m-0 p-0 px-0 px-lg-2 mt-2" onSubmit={submit}>
                <div className="col-12 col-lg-6 p-0 m-0 px-2">
                    <div className="col-12 p-0 m-0 px-3 mt-3 text-secondary">
                        <h5>Confirm Authenticity</h5>
                        <div>
                            Add an official identification document for yourself or your
                            business
                        </div>
                    </div>
                    <div className="col-12 p-0 m-0 px-3 mt-4">
                        <label className="form-check-label col-12 p-0 m-0">Full name</label>
                        <input type="text" name="newFullName" className="form-control"
                            value={fullName} onChange={updateFullName} required />
                    </div>

                    <div className="col-12 p-0 m-0 px-3 mt-3">
                        <label className="form-check-label col-12 p-0 m-0">Document type</label>
                        <select className="custom-select" name="documentType" required
                            value={documentType} onChange={updateDocumentType}>
                            <option disabled value="">Choose document type</option>
                            {documentTypes.map(docType =>
                                <option value={docType}>{docType}</option>
                            )}
                        </select>
                    </div>

                    <div className="col-12 document-picture p-0 m-0 px-3 mt-3">
                        <label className="form-check-label col-12 p-0 m-0">Document picture</label>
                        <DocPictureUploader name="document_picture" src={documentPicture}
                        onChange={updateDocumentPicture} />
                    </div>

                    {errors ?
                        <div className="col-12 p-0 m-0 px-4 text-danger text-center mt-3 mt-lg-3">
                            {errors}
                        </div> : null
                    }

                    <div className="col-12 p-0 m-0 px-3 mt-3 pt-2">
                        <button type="submit" disabled={isLoading} className="col-12 btn btn-primary my-md-2 py-2 py-md-1">
                            {isLoading ? <Spinner animation="border" size="sm" /> : 'Submit'}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}


function EditVerification(props) {
    const user = props.user;
    const verification = props.verification;
    const [authUser,] = store.useState("user");
    const [errors, setErrors] = useState("");
    const [isLoading, setLoading] = useState(false);

    const [fullName, setFullName] = useState(verification.full_name)
    const [documentType, setDocumentType] = useState(
        verification.document ? verification.document.type: ""
    )
    const [documentPicture, setDocumentPicture] = useState(
        verification.document ? verification.document.src: null
    )


    useEffect(setErrorClass, []);

    const updateFullName = (e) => {
        setFullName(e.target.value)
    }

    const updateDocumentType = (e) => {
        setDocumentType(e.target.value)
    }

    const updateDocumentPicture = (value) => {
        setDocumentPicture(value)
    }

    const redirect = (response) => {
        if (response.status !== 201 && response.status !== 200) {
            const errorMsg = buildMessageFromResponse(response.data);
            setErrors(errorMsg);
        }
        else {
            queryCache.invalidateQueries(`user-verification/${verification.id}`);
            queryCache.invalidateQueries(`users/${user.id}`);
            window.location.reload()
        }
    }

    const uploadDocument = async (verificationID) => {
        const postData = new FormData();
        postData.append("user_verification", verificationID)
        postData.append("type", documentType)
        postData.append("src", documentPicture, documentPicture.name)

        const postUrl = `${BASE_API_URL}/user-verification-documents/`;
        const headers = {
            'Authorization': `Token ${authUser.auth_token}`
        }
        return fetch(postUrl, {method: 'POST', body: postData, headers: headers})
        .then(res =>  res.json().then(data => ({status: res.status, data: data})))
    }


    const editDocument = async (verificationID) => {
        const postData = new FormData();
        postData.append("type", documentType);
        if (documentPicture && documentPicture !== verification.document.src) {
            postData.append("src", documentPicture, documentPicture.name)   
        }

        const URL = `${BASE_API_URL}/user-verification-documents/${verification.document.id}/`;
        const headers = {
            'Authorization': `Token ${authUser.auth_token}`
        }
        return fetch(URL, {method: 'PATCH', body: postData, headers: headers})
        .then(res =>  res.json().then(data => ({status: res.status, data: data})))
    }

    const updateDocument = async (response) => {
        if(response.status !== 200){
            // Report error
            return response
        }

        const verificationID = response.data.id;

        if(response.data.document === null) {
            return uploadDocument(verificationID)
        }
        else {
            return editDocument(verificationID)
        }
    }

    const submit = (e) => {
        e.preventDefault();
        setErrors("");
        setLoading(true);
        const formdata = JSON.stringify({
            "full_name": fullName
        })

        const headers = {
            'Authorization': `Token ${authUser.auth_token}`,
            'Content-Type': 'application/json'
        }

        const URL = `${BASE_API_URL}/user-verifications/${verification.id}/`
        fetch(URL, { method: 'PATCH', body: formdata, headers: headers })
            .then(res => res.json().then(data => ({ status: res.status, data: data })))
            .then(res => updateDocument(res))
            .then(res => redirect(res))
            .catch(error => {
                // Network error
                setErrors("No network connection, please try again!.");
            })
            .finally(() => {
                // Enable button
                setLoading(false);
            });
    }

    const documentTypes = [
        "Passport",
        "Driver's license",
        "National identification card"
    ]

    return (
        <div className="row request-verification p-0 m-0 mt-2 mt-md-3">
            <div className="row header p-0 m-0 px-2 px-sm-3">Request Verification</div>

            <form className="row w-100 standard-form m-0 p-0 px-0 px-lg-2 mt-2" onSubmit={submit}>
                <div className="col-12 col-lg-6 p-0 m-0 px-2">
                    <div className="col-12 p-0 m-0 px-3 mt-3 text-secondary">
                        <h5>Confirm Authenticity</h5>
                        <div>
                            Your verification request is not yet accepted, you can still
                            edit your information. Once your request is accepted you can
                            no longer edit these information.
                        </div>
                    </div>
                    <div className="col-12 p-0 m-0 px-3 mt-4">
                        <label className="form-check-label col-12 p-0 m-0">Full name</label>
                        <input type="text" name="newFullName" className="form-control"
                            value={fullName} onChange={updateFullName} required />
                    </div>

                    <div className="col-12 p-0 m-0 px-3 mt-3">
                        <label className="form-check-label col-12 p-0 m-0">Document type</label>
                        <select className="custom-select" name="documentType" required
                            value={documentType} onChange={updateDocumentType}>
                            <option disabled value="">Choose document type</option>
                            {documentTypes.map(docType =>
                                <option value={docType}>{docType}</option>
                            )}
                        </select>
                    </div>

                    <div className="col-12 document-picture p-0 m-0 px-3 mt-3">
                        <label className="form-check-label col-12 p-0 m-0">Document picture</label>
                        <DocPictureUploader name="document_picture" src={documentPicture} onChange={updateDocumentPicture} />
                    </div>

                    {errors ?
                        <div className="col-12 p-0 m-0 px-4 text-danger text-center mt-3 mt-lg-3">
                            {errors}
                        </div> : null
                    }

                    <div className="col-12 p-0 m-0 px-3 mt-3 pt-2">
                        <button type="submit" disabled={isLoading} className="col-12 btn btn-primary my-md-2 py-2 py-md-1">
                            {isLoading ? <Spinner animation="border" size="sm" /> : 'Submit'}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}


function VerificationDetails(props) {
    return (
        <div className="row verification p-0 m-0 mt-2 mt-md-3">
            <div className="row w-100 m-0 p-0" >
                <div className="col-12 col-lg-6 p-0 m-0 mt-5 text-center">
                    <div className="verification-badge">
                        <span className="icon icon-verified"/>
                    </div>
                    <span className="message">
                        Your account is already verified.
                    </span>
                </div>
            </div>
        </div>
    );
}


function SelectAccountType(props) {
    return (
        <ul className="m-0 p-0">
            <li className="p-0 m-0">
                <Link className="d-block m-0 p-0 px-2 pb-2 pt-3 switch-acc-link"
                    to={`/action/to-host`}>
                    Switch to Host Account
                </Link>
            </li>
            <hr className="line m-0 p-0" />
            <li className="p-0 m-0">
                <Link className="d-block m-0 p-0 px-2 pb-2 pt-3 switch-acc-link"
                    to={`/action/to-personal`}>
                    Switch to Personal Account
                </Link>
            </li>
            <hr className="line m-0 p-0" />
        </ul>
    );
}

function ConfirmAccountSelection(props){
    const [isLoading, setLoading] = useState(false);
    const [editError, setEditError] = useState('');
    const [user, ] = store.useState("user");

    let redirect = (response) => {
        // Invalidate user properties
        if(response.status === 200){
            props.setModalShow(false);
            window.location.reload();
        }
    }

    const switchAccount = () => {
        setEditError("");
        setLoading(true);

        const formData = {
            is_host: props.type === "to-host"? true: false
        }

        const postUrl = `${BASE_API_URL}/users/${user.id}/`;
        const headers = {
            'Authorization': `Token ${user.auth_token}`,
            'Content-Type': 'application/json'
        }
        fetch(postUrl, { method: 'PATCH', body: JSON.stringify(formData), headers: headers })
            .then(res => res.json().then(data => ({ status: res.status, data: data })))
            .then(obj => redirect(obj))
            .catch(error => {
                // Network error
                setEditError("No network connection, please try again!.");
            })
            .finally(() => {
                // Enable button
                setLoading(false);
            })
    }

    let switchMessage = null;
    if (props.type === "to-host") {
        switchMessage = `
        Switching your account type to host will allow you to be able
        to create and manage properties.
        `
    }
    else if (props.type === "to-personal") {
        switchMessage = `
        Switching your account type to personal will remove
        host privileges to create and manage properties.
        `
    }

    return (
        <div className="row m-0 p-0 px-3 pt-3">
            <div className="col-12 p-0 m-0">
                {switchMessage}
            </div>
            <div className="col-12 p-0 m-0 mt-4 text-right">
                <div className="col-12 mb-2 text-center text-danger">
                    {editError}
                </div>
                <Button className="switch-acc-btn col-6 col-md-2 p-0 m-0 py-2"
                    disabled={isLoading} variant="primary" onClick={switchAccount}>
                    {isLoading ? <Spinner animation="border" size="sm" /> : 'Continue'}
                </Button>
            </div>
        </div>
    );
}

function SwitchAccount(props) {
    const modalShow = props.modalShow;
    const setModalShow = props.setModalShow;
    const [header, setHeader] = useState("");

    return (
        <InfoModal header={header} modalShow={modalShow} setModalShow={setModalShow}>
            <MemoryRouter initialEntries={["/select-action", "/action", { pathname: "/" }]} initialIndex={0}>
                <Route exact path="/select-action" render={({ match }) => {
                    setHeader("Select account type to switch to");
                    return <SelectAccountType />
                }} />
                <Route exact path="/action/:type/" render={({ match }) => {
                    setHeader("What's going happen?");
                    return <ConfirmAccountSelection type={match.params.type} setModalShow={setModalShow} />
                }} />
            </MemoryRouter>
        </InfoModal>
    );
}


function AccountAndSecurity(props){
    const user = props.user;
    const history = useHistory();
    const [modalShow, setModalShow] = useState(false);

    const logOut = (event) => {
        deleteUserInfoFromCookies(["id", "auth_token"]);
        window.location = "/"
    }

    return (
        <div className="row user-settings p-0 m-0 mt-2 mt-md-3">
            <div className="row header p-0 m-0 px-2 px-sm-3">
                Account & Security
            </div>

            <div className="settings row p-0 m-0 mt-2 w-100">
                <Setting title="Switch Account Type" icon="icon-user"
                    onClick={e => setModalShow(true)}
                    description="Switch to host, agent or personal account" />
                <SwitchAccount modalShow={modalShow} setModalShow={setModalShow} />
                <Setting title="Change password" icon="icon-key"
                    onClick={e => history.push("/change-password")}
                    description="Change your account's password" />
                <Setting title="Change email" icon="icon-mail"
                    onClick={e => history.push("/change-email")}
                    description="Change your account's primary email" />
                {user.is_host ?
                    <Setting title="Request verification" icon="icon-verified-outline"
                    onClick={e => history.push("/user-verification")}
                    description="User guides and support" />: null
                }
                <Setting title="Log out" icon="icon-exit"
                    onClick={logOut} className="logout" notLink
                    description="Logout from this device" />
            </div>
        </div>
    );
}


function AboutSettings(){
    const history = useHistory();

    return (
        <div className="row user-settings p-0 m-0 mt-2 mt-md-3">
            <div className="row header p-0 m-0 px-2 px-sm-3">
                About
            </div>

            <div className="settings row p-0 m-0 mt-2 w-100">
                <Setting title="Privacy Policy" icon="icon-security-check"
                    onClick={e => history.push("/privacy")}
                    description="Our privacy policy" />
            </div>
        </div>
    );
}


function HelpSettings(){
    const history = useHistory();

    return (
        <div className="row user-settings p-0 m-0 mt-2 mt-md-3">
            <div className="row header p-0 m-0 px-2 px-sm-3">
                Help Center
            </div>

            <div className="settings help-links row p-0 m-0 mt-2 w-100">
                <Setting title="How to become an agent?"
                    onClick={e => history.push("/how-to-become-an-agent")}
                    description="" />
                <Setting title="How to become a host?"
                    onClick={e => history.push("/how-to-become-a-host")}
                    description="" />
                <Setting title="How to list your property?" 
                    onClick={e => history.push("/how-to-list-a-property")}
                    description="" />
            </div>
        </div>
    );
}


function BecomingAnAgent(){

    return (
        <div className="row help-center p-0 m-0 mt-2 mt-md-3">
            <div className="row title p-0 m-0 px-3">
                How to become an agent?
            </div>

            <div className="content row p-0 m-0 px-3 mt-2 w-100">
                To become an agent you need to go to your profile, and Select
                <ul>
                    <li>Settings <span className="doc icon icon-gear"/></li>
                    <li>Account & Security <span className="doc icon icon-user"/></li>
                    <li>Switch Account Type <span className="doc icon icon-user"/></li>
                </ul>
                Then from there select "Switch to agent account" and confirm.
            </div>
        </div>
    );
}


function BecomingAHost(){

    return (
        <div className="row help-center p-0 m-0 mt-2 mt-md-3">
            <div className="row title p-0 m-0 px-3">
                How to become a host?
            </div>

            <div className="content row p-0 m-0 px-3 mt-2 w-100">
                To become a host you need to go to your profile, and Select
                <ul>
                    <li>Settings <span className="doc icon icon-gear"/></li>
                    <li>Account & Security <span className="doc icon icon-user"/></li>
                    <li>Switch Account Type <span className="doc icon icon-user"/></li>
                </ul>
                Then from there select "Switch to host account" and confirm.
            </div>
        </div>
    );
}


function ListingAProperty(){

    return (
        <div className="row help-center p-0 m-0 mt-2 mt-md-3">
            <div className="row title p-0 m-0 px-3">
                How to list a property?
            </div>

            <div className="content row p-0 m-0 px-3 mt-2 w-100">
                To list a property you would need to
                <ul>
                    <li>
                        Create an account if you don't have one
                    </li>
                    <li>
                        Switch your account type to agent or host
                    </li>
                </ul>
                After switching your account type you will get an 
                option to create properties. <br/> <br/>

                <b>Note:</b> You should switch your account type to host/agent depending on
                the property type that you want to create
            </div>
        </div>
    );
}


function ToggleSwitch(props){
    const isToggleOn = props.value;

    const handleClick = (e) => {
        props.onChange(!isToggleOn);
    }

    return (
        <div onClick={handleClick} className={`toggle-switch ${props.className || ''} ${isToggleOn ? 'active' : ''}`}>
            <div className={isToggleOn ? 'knob active' : 'knob'}>
                {props.loading ?
                    <span className="switch-loader">
                        <Spinner className={
                            isToggleOn ? 'load-on' : 'load-off'
                        } animation="border" size="sm" />
                    </span> :
                    <>
                        {isToggleOn ?
                            <span className="icon icon-check" /> : null
                        }
                    </>
                }
            </div>
        </div>
    );
}


function ToggleSetting(props) {
    const [authUser,] = store.useState("user");
    const settings = props.settings;
    const settingValue = settings[props.settingKey]

    const [loading, setLoading] = useState(false);

    const redirect = (response) => {
        if(response.status === 200){
            queryCache.setQueryData(
                `user-settings/${settings.id}`,
                (old) => response
            )
        }
        else {
            toast.error("Something wen't wrong when updating your setting.")
        }
    }

    const onChange = (val) => {
        setLoading(true);
        const formData = {}
        formData[props.settingKey] = val;

        const postUrl = `${BASE_API_URL}/user-settings/${settings.id}/`;
        const headers = {
            'Authorization': `Token ${authUser.auth_token}`,
            'Content-Type': 'application/json'
        }
        fetch(postUrl, { method: 'PATCH', body: JSON.stringify(formData), headers: headers })
            .then(res => res.json().then(data => ({ status: res.status, data: data })))
            .then(obj => redirect(obj))
            .catch(error => {
                // Network error
                toast.error("Something wen't wrong when updating your setting.")
            })
            .finally(() => {
                // Enable button
                setLoading(false);
            })
    }

    return (
        <div className={`toggle-setting row p-0 m-0 w-100 ${props.className||''}`}>
            <div className="info col p-0 m-0 px-1 px-sm-2">
                <div className="title col-12 p-0 m-0">
                    {props.title}
                </div>
                {props.description ?
                    <div className="description col-12 p-0 m-0 mt-1">
                        {props.description}
                    </div> : null
                }
            </div>
            <div className="action col-2 col-lg-1 p-0 m-0 justify-content-right">
                <ToggleSwitch loading={loading} value={settingValue} className="switch" onChange={onChange} />
            </div>
        </div>
    );
}

function Notifications(props){
    const user = props.user;
    const settings = props.settings;

    return (
        <div className="row user-settings p-0 m-0 mt-2 mt-md-3">
            <div className="row header p-0 m-0 px-2 px-sm-3">
                Notification Settings
            </div>

            <div className="settings row p-0 m-0 mt-2 w-100">
                {user.is_staff ?
                    <ToggleSetting title="Publish Requests"
                        settings={settings}
                        settingKey="receive_publish_request_creation_notifications"
                        description="Get notifications related to publish requests" /> : null
                }
                {user.is_staff ?
                    <ToggleSetting title="Verification Requests"
                        settings={settings}
                        settingKey="receive_user_verification_request_creation_notifications"
                        description="Get notifications related to verification requests" /> : null
                }
                <ToggleSetting title="Tour Creation Success"
                    settings={settings}
                    settingKey="receive_tour_creation_success_notifications"
                    description="Get tour creation success notifications" />
                <ToggleSetting title="Reservation Creation Success"
                    settings={settings}
                    settingKey="receive_reservation_creation_success_notifications"
                    description="Get reservation creation success notifications" />
            </div>
        </div>
    );
}


function UserSettings(props){
    const history = useHistory();
    const [user, ,] = store.useState("user");

    const goTo = (location) => {
        history.push(location);
    }

    return (
        <div className="row user-settings p-0 m-0 mt-2 mt-md-3">
            <div className="row header p-0 m-0 px-2 px-sm-3">
                Settings
            </div>

            <div className="settings row p-0 m-0 mt-2 w-100">
                <Setting title="Account & Security" icon="icon-user"
                    onClick={e => goTo("/account-and-security-settings")}
                    description="Manage your account and security" />
                <Setting title="Notifications" icon="icon-notification"
                    onClick={e => goTo("/notification-settings")}
                    description="Select type of notifications that you want to get" />
                {user.is_host ?
                    <Setting title="Payments" icon="icon-money"
                        onClick={e => goTo("/payments-settings")}
                        description="Manage your payments & payouts" /> : null
                }
                <Setting title="About" icon="icon-info"
                    onClick={e => goTo("/about")}
                    description="About us, our terms of use and privacy policy" />
                <Setting title="Help" icon="icon-help"
                    onClick={e => goTo("/help")}
                    description="User guides and support" />
            </div>
        </div>
    );
}


function UserFetcher(props){
    const [user, ,] = store.useState("user");
    const headers = {
        'Authorization': `Token ${user.auth_token}`,
        'Content-Type': 'application/json'
    }

    const fetchProfile = () => {
        return fetch(`${BASE_API_URL}/users/${user.id}/`, { method: 'GET', headers: headers })
        .then(res => res.json().then(data => ({statusCode: res.status, data})))
    }

    if(!user.isLoggedIn) {
        const message = "Yo need to be logged in to do this action."
        return <LogInToViewSaved message={message}/>
    }

    return (
        <DataFetcher 
         selection={`user/${user.id}`}
         action={fetchProfile}
         placeholder={<BouncePageLoader/>} 
         onError={renderPageError}>
             {response => {
                const user = response.data.data;
                return props.children(user)
             }}
         </DataFetcher>
    );
}


function UserSettingsFetcher(props){
    const [user, ,] = store.useState("user");
    const settingsId = props.user.settings;

    const headers = {
        'Authorization': `Token ${user.auth_token}`,
        'Content-Type': 'application/json'
    }

    const fetchUserSettings = () => {
        return fetch(`${BASE_API_URL}/user-settings/${settingsId}/`, { method: 'GET', headers: headers })
        .then(res => res.json().then(data => ({statusCode: res.status, data})))
    }

    if(!user.isLoggedIn) {
        const message = "Yo need to be logged in to do this action."
        return <LogInToViewSaved message={message}/>
    }

    return (
        <DataFetcher 
         selection={`user-settings/${settingsId}`}
         action={fetchUserSettings}
         placeholder={<BouncePageLoader/>} 
         onError={renderPageError}>
             {response => {
                const settings = response.data.data;
                return props.children(settings)
             }}
         </DataFetcher>
    );
}

function VerificationFetcher(props){
    const [user, ,] = store.useState("user");
    const verificationID = props.user.verification;

    const headers = {
        'Authorization': `Token ${user.auth_token}`,
        'Content-Type': 'application/json'
    }

    const fetchVerification = () => {
        return fetch(
            `${BASE_API_URL}/user-verifications/${verificationID}/`,
            { method: 'GET', headers: headers }
        )
        .then(res => res.json().then(data => ({statusCode: res.status, data})))
    }

    if(!user.isLoggedIn) {
        const message = "Yo need to be logged in to do this action."
        return <LogInToViewSaved message={message}/>
    }

    return (
        <DataFetcher 
         selection={`user-verification/${verificationID}`}
         action={fetchVerification}
         placeholder={<BouncePageLoader/>} 
         onError={renderPageError}>
             {response => {
                const verification = response.data.data;
                return props.children(verification)
             }}
         </DataFetcher>
    );
}


function AccountAndSecuritySettings(props){
    return (
        <UserFetcher>
            {user => <AccountAndSecurity user={user}/>}
        </UserFetcher>
    );
}


function ChangePassword(props){
    return (
        <UserFetcher>
            {user => <EditPassword user={user}/>}
        </UserFetcher>
    );
}

function ChangeEmail(props){
    return (
        <UserFetcher>
            {user => <EditEmail user={user}/>}
        </UserFetcher>
    );
}

function UserVerification(props){
    return (
        <UserFetcher>
            {user => {
                if(user.verification === null) {
                    return <RequestVerification user={user}/>
                }
                else if(!user.is_verified) {
                    return (
                        <VerificationFetcher user={user} >
                            { verification =>
                                <EditVerification user={user} verification={verification}/>
                            }
                        </VerificationFetcher>
                    );
                }
                else {
                    return (
                        <VerificationFetcher user={user} >
                            { verification =>
                                <VerificationDetails user={user} verification={verification}/>
                            }
                        </VerificationFetcher>
                    );
                }
            }}
        </UserFetcher>
    );
}

function NotificationSettings(props){
    return (
        <UserFetcher>
            {user => 
                <UserSettingsFetcher user={user}>
                    { settings => <Notifications user={user} settings={settings}/> }
                </UserSettingsFetcher>
            }
        </UserFetcher>
    );
}

export {
    UserSettings, AccountAndSecuritySettings,
    ChangePassword, ChangeEmail,
    AboutSettings, HelpSettings, BecomingAnAgent,
    BecomingAHost, ListingAProperty, NotificationSettings,
    UserVerification
}
