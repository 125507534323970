import React from 'react';
import './PageNotFound.scss';
import notFoundImage from '../images/undraw_page_not_found.svg';


function PageNotFound(props) {
    return (
        <div className="not-found row p-0 m-0 px-5 pt-3">
            <div className="col-12 p-0 m-0 mt-5 pt-5 d-flex justify-content-center">
                <img className="col-12 col-md-6" src={
                    props.image? props.image: notFoundImage
                }/>
            </div>
            <div className="not-found-message col-12 mt-3 text-center">
                {props.message? props.message: "Page Not Found"}
            </div>
        </div>
    );
}

export { PageNotFound};
