import React, {Suspense} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import { Route, Switch, useLocation } from 'react-router-dom';
import {
    TopBar, PropertyDetails, UploadProperty, EditProperty,
    UserProperties, SearchProperties, Home, BottomNavBar,
    TopScroller, PageNotFound, AddToHomeScreen,
    FilterPropertiesByCategory, ShowBuyProperties, ShowRentProperties,
    EditProfile, NearByProperties, UserNearByProperties,
    ResetPassword, ManageCompartments, UserReservations, ReservationDetails
} from './';
import {
    ChatFilter, ConversationsFilter, ReservationRequests,
    NotificationsFilter, PublishRequests, UserTours,
    TourRequests, GlobalSearch, VerificationRequests, SavedItems, UserTransactions
} from './Filters';
import { ReservationRequestDetails } from './ReservationRequestDetails';
import { PublishRequestDetails } from './PublishRequestDetails';
import { GenericProperty } from './PropertyDetails';
import { store } from '../store';
import { deleteUserInfoFromCookies, getUserInfoFromCookies } from '../utils';
import { BASE_API_URL } from '..';
import { DataFetcher } from './Fetchers';
import { BouncePageLoader } from './Loaders';
import { renderPageError } from './Errors';
import { TourDetails } from './TourDetails';
import { TourRequestDetails } from './TourRequestDetails';
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import {PrivacyPolicy} from "./PrivacyPolicy";
import { UserProfile } from './UserProfile';
import {
    AboutSettings, AccountAndSecuritySettings, BecomingAHost,
    BecomingAnAgent, ChangeEmail, ChangePassword,
    HelpSettings, ListingAProperty, NotificationSettings,
    UserSettings, UserVerification
} from './UserSettings';
import { VerificationRequestDetails } from './VerificationRequestDetails';
import { SavedSearchDetails } from './SavedSearchDetails';
import { PaymentSettings } from './PaymentSettings';
import { ToastContainer } from 'react-toastify';
import { SideNavBar } from './SideNavBar';
import {Helmet} from "react-helmet";

const PROPERTY_ROUTES = [
    {type: "room",         path: "rooms"},
    {type: "house",        path: "houses"},
    {type: "apartment",    path: "apartments"},
    {type: "office",       path: "offices"},
    {type: "land",         path: "lands"},
    {type: "hotel",        path: "hotels"},
    {type: "lodge",        path: "lodges"},
    {type: "venue",        path: "venues"},
    {type: "guest_house",  path: "guest_houses"}
]

function App() {
    return (
        <Suspense fallback={<BouncePageLoader />}>
            <AppContent />
        </Suspense>
    );
}

function AppContent() {
    const location = useLocation();
    const { t } = useTranslation();

    return (
        <div className="container-fluid">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Seto - Dalali Halali</title>
                <link rel="canonical" href="https://seto.co.tz/"/>
                <meta
                    name="description"
                    content="Pata Chumba, Nyumba, Apartment, Ofisi, Viwanja, Hoteli, Ukumbi eneo Lolote - yote yamewezeshwa na Seto.
                Find Room, House, Apartment, Office, Land Plots, Hotel, Venue anywhere - all available in Seto."
                />
                <meta property="og:url" content="seto.co.tz"/>
                <meta property="og:site_name" content="Seto"/>
                <meta
                    property="og:description"
                    content="Pata Chumba, Nyumba, Apartment, Ofisi, Viwanja, Hoteli, Ukumbi eneo Lolote - yote yamewezeshwa na Seto."
                />
                <meta property="og:type" content="website"/>
                <meta property="fb:app_id" content="ID_APP_FACEBOOK"/>
            </Helmet>

            <TopBar/>
            <div className="row contents">
                <SideNavBar/>
                <div className="contents-body col-12 col-lg-10 p-0 m-0 mb-4 pb-5">
                    <ToastContainer
                        className="toast-container"
                        toastClassName="toast"
                        position="top-center"
                        autoClose={3000}
                        hideProgressBar={true}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <Switch location={location}>

                        <Route exact path="/" render={({match}) => {
                            return <Home key={match.path}/>;
                        }}/>

                        <Route exact path="/users/:id/" render={({match}) => {
                            return <UserProfile id={match.params.id}/>;
                        }}/>
                        <Route exact path="/edit-profile" component={EditProfile}/>
                        <Route exact path="/settings" component={UserSettings}/>

                        <Route exact path="/account-and-security-settings" component={AccountAndSecuritySettings}/>
                        <Route exact path="/change-password" component={ChangePassword}/>
                        <Route exact path="/change-email" component={ChangeEmail}/>
                        <Route exact path="/user-verification" component={UserVerification}/>
                        <Route exact path="/confirm-password-reset" component={ResetPassword}/>
                        <Route exact path="/payments-settings" component={PaymentSettings} />
                        <Route exact path="/transactions" component={UserTransactions} />

                        <Route exact path="/about" component={AboutSettings}/>
                        <Route exact path="/privacy/" component={PrivacyPolicy} />

                        <Route exact path="/notification-settings" component={NotificationSettings}/>

                        <Route exact path="/help" component={HelpSettings}/>
                        <Route exact path="/how-to-become-an-agent/" component={BecomingAnAgent} />
                        <Route exact path="/how-to-become-a-host/" component={BecomingAHost} />
                        <Route exact path="/how-to-list-a-property/" component={ListingAProperty} />

                        <Route exact path="/notifications" component={NotificationsFilter} />
                        <Route exact path="/conversations" component={ConversationsFilter} />
                        <Route path="/conversations/:id/" render={({ match }) => {
                            return <ChatFilter id={match.params.id} />
                        }} />

                        <Route exact path="/search/"
                               render={() => {
                                   return <SearchProperties t={t}/>
                               }}/>
                        <Route exact path="/global-search"
                               render={() => {
                                   return <GlobalSearch t={t}/>
                               }} />

                        <Route exact path="/explore/rent-properties" component={ShowRentProperties} />
                        <Route exact path="/explore/buy-properties" component={ShowBuyProperties} />

                        <Route exact path="/rent-property" render={({ match }) => {
                            return <FilterPropertiesByCategory key={match.path}  global selection={match.path}
                                    availableFor="rent" header={i18n.t("Properties available for rent")} />;
                        }} />
                        <Route exact path="/buy-property" render={({ match }) => {
                            return <FilterPropertiesByCategory key={match.path}  global selection={match.path}
                                    availableFor="sale" header={i18n.t("Properties available for sale")} />;
                        }} />

                        <Route exact path="/saved" render={({ match }) => {
                            return <SavedItems key={match.path} />;
                        }} />
                        <Route path="/saved-searches/:id/" render={({ match }) => {
                            return <SavedSearchDetails key={match.path} id={match.params.id} />
                        }} />

                        <Route exact path="/my-nearby-properties" render={({ match }) => {
                            return <UserNearByProperties key={match.path} />;
                        }} />
                        <Route exact path="/nearby-properties" component={NearByProperties} />

                        <Route path="/create-compartments/:id/" render={({ match }) => {
                            return <ManageCompartments key={match.path} id={match.params.id} />
                        }} />

                        <Route path="/properties/:id/" render={({ match }) => {
                            return <GenericProperty id={match.params.id} />
                        }} />


                        <Route exact path="/tours/" render={() => {
                            return <UserTours />
                        }} />

                        <Route path="/tours/:id/" render={({ match }) => {
                            return <TourDetails id={match.params.id} />
                        }} />
                        <Route exact path="/tour-requests/" render={() => {
                            return <TourRequests />
                        }} />
                        <Route path="/tour-requests/:id/" render={({ match }) => {
                            return <TourRequestDetails id={match.params.id} />
                        }} />


                        <Route exact path="/reservations/" render={() => {
                            return <UserReservations />
                        }} />
                        <Route path="/reservations/:id/" render={({ match }) => {
                            return <ReservationDetails id={match.params.id} />
                        }} />
                        <Route exact path="/reservation-requests/" render={() => {
                            return <ReservationRequests />
                        }} />
                        <Route path="/reservation-requests/:id/" render={({ match }) => {
                            return <ReservationRequestDetails id={match.params.id} />
                        }} />


                        <Route exact path="/publish-requests/" render={() => {
                            return <PublishRequests />
                        }} />
                        <Route path="/publish-requests/:id/" render={({ match }) => {
                            return <PublishRequestDetails id={match.params.id} key={match.path} />
                        }} />

                        <Route exact path="/user-verifications/" render={() => {
                            return <VerificationRequests />
                        }} />
                        <Route path="/user-verifications/:id/" render={({ match }) => {
                            return <VerificationRequestDetails id={match.params.id} key={match.path} />
                        }} />

                        {PROPERTY_ROUTES.map(route => [
                            // Returning a list here is important, if yo use  JSX sytanx like
                            // <></> it won't work
                            <Route exact path={`"/${route.path}/`} render={({ match }) => {
                                return <UserProperties type={route.type} key={match.path} />
                            }} />,
                            <Route path={`/${route.path}/:id/`} render={({ match }) => {
                                return <PropertyDetails id={match.params.id} type={route.type} key={match.path} />
                            }} />,
                            <Route path={`/create/${route.path}/`} render={({ match }) => {
                                return <UploadProperty type={route.type} key={match.path} />
                            }} />,
                            <Route path={`/edit/${route.path}/:id/`} render={({ match }) => {
                                return <EditProperty id={match.params.id} type={route.type} key={match.path} />
                            }} />
                        ])}

                        <Route component={PageNotFound} />
                    </Switch>
                </div>
            </div>

            <BottomNavBar />
            <TopScroller />
            <AddToHomeScreen />
        </div>
    );
}


function UserFetcher(props) {
    const [] = store.useState("user");
    const userInfo = getUserInfoFromCookies(["auth_token", "id"]);

    const user = {
        id: userInfo.id,
        auth_token: userInfo.auth_token,
        isLoggedIn: userInfo.auth_token !== null? true: false
    }

    if(!user.isLoggedIn) {
        store.getState("user").value = user;
        return <App/>;
    }

    const headers = {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.auth_token}`
    }

    const fetchUser = () => {
        return fetch(`${BASE_API_URL}/users/${user.id}/`, {headers: headers})
        .then(res => res.json().then(data => ({statusCode: res.status, data})))
    }

    return (
        <DataFetcher action={fetchUser} selection={`user/${user.id}`}
            placeholder={<BouncePageLoader />} onError={renderPageError}>{response => {
                if(response.data.statusCode === 200){

                    store.getState("user").value = {
                        ...user,
                        ...response.data.data
                    }
                }
                else {
                    // This happens when user token or id has changes
                    // So we have to logout the user to get fresh values
                    // of both id and token
                    deleteUserInfoFromCookies(["id", "auth_token"]);
                    window.location = "/"
                }
                return <App/>;
        }}</DataFetcher>
    )
}

export { UserFetcher as App };
