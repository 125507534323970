import React from 'react';
import { formatDistance } from 'date-fns';
import { useHistory } from 'react-router';
import { capitalizeFirst } from '../utils';
import './NotificationOverview.scss';


function NotificationOverview(props) {
    const history = useHistory()
    const notification = props.notification;

    const isReadClass = notification.is_read ? null: "unread";

    const openNotification = (e) => {
        if(notification.related_model && notification.related_object){
            history.push(
                `/${
                    notification.related_model
                }/${
                    notification.related_object
                }/${notification.related_parameters?
                    `?${notification.related_parameters}`: ""
                }`)
        }
        else if(notification.related_model){
            history.push(`/${notification.related_model}/${
                notification.related_parameters?
                `?${notification.related_parameters}`: ""
            }`)
        }
    };

    return (
        <div className={`notification-overview ` +isReadClass+ ` row p-0 m-0`} onClick={openNotification}>
            <div className="initiator col-2 col-md-1 p-0 m-0">
                <div className="initiator-profile-picture text-center">
                    {!notification.initiator || !notification.initiator.picture ?
                        <img src={process.env.PUBLIC_URL + 'logo512.png'} alt="" />:
                        <img src={notification.initiator.picture.src} alt="" />
                    }
                </div>
            </div>
            <div className="content col p-0 m-0">
                <div className="">
                    {notification.content}
                </div>
                <div className="info text-right">
                    {capitalizeFirst(formatDistance(new Date(notification.created_at), new Date()))}
                </div>
            </div>
        </div>
    );
}


export { NotificationOverview }
