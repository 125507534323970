import React, { useState, useEffect } from "react";
import "./PropertyDetails.scss";
import { parse } from "query-string";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import {
  DataFetcher, BouncePageLoader, Rating, ConfirmModal, InfoModal,
  Carousel as Slider, SaveButton, renderPageError, Map,
  PROPERTIES_QUERY_PARAM, renderInlineError, SliderPropertiesGroup,
  GenericFilter, BounceBlockLoader, NotFoundError, PropertyOverview
} from "./";
import { BASE_API_URL } from "../";
import { Button, Modal, Spinner } from "react-bootstrap";
import { store } from "../store";
import {
  getPropertyRoute, setTabColorDark, capitalizeFirst,
  thousandsSeparator, PARENT_ONLY_PROPERTIES, getPropertyName,
  scrollIntoView
} from "../utils";
import { queryCache } from "react-query";
import { useScrollTop, useLocalState } from "../hooks";

import moment from "moment";
import { toast } from "react-toastify";
import { formatDistance } from "date-fns";
import { DayPickerRangeController, DayPickerSingleDateController } from "react-dates";
import { START_DATE, END_DATE } from "react-dates/constants";
import i18n from "i18next";
import { Helmet } from "react-helmet";
import { BASE_URL } from "../index";


function ImagesCarousel(props) {
  let activeImageIndex = props.images.indexOf(props.activeImage);
  const [index, setIndex] = useState(activeImageIndex);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: index,
    adaptiveHeight: true,
    afterChange: function(sliderIndex) {
      setIndex(sliderIndex);
    }
  };

  return (
    <>
      <Slider {...settings}>
        {props.images.map((image) =>
          <div className="lazy-container lazy-load-animation">
            <img className="full-img d-block w-100" src={image.src} alt=""/>
          </div>
        )}
      </Slider>
      <div className="corousel-items-counter">{index + 1} / {props.images.length}</div>
    </>
  );
}


function ImageDescription(props) {
  return (
    <>
      {props.image.description ?
        <div className="text-secondary mt-3 px-1">
          {props.image.description}
        </div> :
        null
      }
    </>
  );
}


function ImagesModalCarousel(props) {
  let activeImageIndex = props.images.indexOf(props.activeImage);

  const [index, setIndex] = useState(activeImageIndex);
  const [modalShow, setModalShow] = useState(false);
  setTabColorDark(modalShow);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: index,
    arrows: false,
    adaptiveHeight: true,
    afterChange: function(sliderIndex) {
      setIndex(sliderIndex);
    }
  };

  return (
    <>
      <Slider {...settings}>
        {props.images.map((image, index) =>
          <div className="lazy-container lazy-load-animation d-flex" key={index}>
            <img className="full-img d-block w-100" src={image.src} alt="" onClick={() => setModalShow(true)}/>
          </div>
        )}
      </Slider>
      <div className="corousel-items-counter-sm">{index + 1} / {props.images.length}</div>
      <Modal animation={false} backdropClassName="img-modal-backdrop" dialogClassName="cusom-modal-dialog"
             show={modalShow} onHide={() => setModalShow(false)} size="lg"
             aria-labelledby="contained-modal-title-vcenter" centered>
        <div className="modal-close" onClick={() => setModalShow(false)}>
          <span className="icon icon-close"/>
        </div>
        <Modal.Body className="p-0 m-0">
          <ImagesCarousel activeImage={props.images[index]} images={props.images}/>
          <ImageDescription image={props.images[index]}/>
        </Modal.Body>
      </Modal>
    </>
  );
}


function MainPropertyImage(props) {
  const [modalShow, setModalShow] = useState(false);
  setTabColorDark(modalShow);

  return (
    <>
      <div className="main-prop-img col-12 col-lg-6 mx-0 px-0">
        <div className="lazy-container lazy-load-animation">
          <img className="main-img" src={props.activeImage.src} alt="" onClick={() => setModalShow(true)}/>
        </div>
      </div>

      <Modal animation={false} backdropClassName="img-modal-backdrop-md" dialogClassName="cusom-modal-dialog"
             show={modalShow} onHide={() => setModalShow(false)} size="lg"
             aria-labelledby="contained-modal-title-vcenter" centered>
        <div className="modal-close" onClick={() => setModalShow(false)}>
          <span className="icon icon-close"></span>
        </div>
        <Modal.Body className="p-0 m-0">
          <ImagesCarousel activeImage={props.activeImage} images={props.images}/>
          <ImageDescription image={props.activeImage}/>
        </Modal.Body>
      </Modal>
      <Button className="view-photos-btn d-none d-md-block" variant="light" onClick={() => setModalShow(true)}>
        View all photos
      </Button>
    </>
  );
}


function OthersPropertyImages(props) {
  const [modalShow, setModalShow] = useState(false);
  const [activeImage, setActiveImage] = useState(props.images[0]);

  setTabColorDark(modalShow);

  let getStyle = (index) => {
    let width = 2;
    switch (index) {
      case 0:
        return `0 0 ${width}px ${width}px`;
      case 1:
        return `0 0 ${width}px ${width}px`;
      case 2:
        return `0 0 0 ${width}px`;
      case 3:
        return `0 0 0 ${width}px`;
      default:
        return `0`;
    }
  };

  let openModal = (image) => {
    setActiveImage(image);
    setModalShow(true);
  };

  return (
    <>
      {props.otherImages.map((image, key) => {
        let imageIndex = props.otherImages.indexOf(image);
        let style = { padding: getStyle(imageIndex) };
        return (
          <div className="pictures col-6 m-0 p-0" key={key}>
            <div className="other-prop-img col-12">
              <div className="lazy-container lazy-load-animation">
                <img className="small-img" style={style} src={image.src} alt="" onClick={() => openModal(image)}/>
              </div>
            </div>
          </div>
        );
      })}

      <Modal animation={false} backdropClassName="img-modal-backdrop-md" dialogClassName="cusom-modal-dialog"
             show={modalShow} onHide={() => setModalShow(false)} size="lg"
             aria-labelledby="contained-modal-title-vcenter" centered>
        <div className="modal-close" onClick={() => setModalShow(false)}>
          <span className="icon icon-close"></span>
        </div>
        <Modal.Body className="p-0 m-0">
          <ImagesCarousel activeImage={activeImage} images={props.images}/>
          <ImageDescription image={activeImage}/>
        </Modal.Body>
      </Modal>
    </>
  );
}


function shortenString(str, to) {
  let long = str.slice(to);
  if (long) {
    return str.slice(0, to) + "...";
  }
  return str;
}


function Badges(props) {
  const [modalShow, setModalShow] = useState(false);

  let maxValue = 15;
  let values = [];
  if (props.values && props.values.length > 0) {
    values = props.values.slice(0, maxValue);
  }

  values = values.sort(function(a, b) {
    return a.length - b.length;
  });

  return (
    values.length > 0 ?
      <div className="mb-4">
        <div className="h4 m-0 p-0 mb-1">{i18n.t(props.label)}</div>
        <div className="col-12 p-0 m-0 badges-container">
          {values.map((val, key) => {
            return (
              <span className="badge mb-1 mr-1" key={key}>
                                    <span id={val} className={`fa fa-${val} badge-close`}/>
                                    <span className="badge-body">{i18n.t(shortenString(val, 17))}</span>
                                </span>
            );
          })}
        </div>
        {props.values.length > (maxValue) ?
          <>
            <Button className="text-decoration-none m-0 p-0 mt-1 w-100 text-left" variant="link"
                    onClick={() => setModalShow(true)}>
              {`Show all ${props.values.length} ${props.label.toLowerCase()}`}
            </Button>
            <InfoModal scrollable positionBottom header={props.label} modalShow={modalShow} setModalShow={setModalShow}>

              <ul className="m-0 p-0">
                {props.values.map((val, key) => {
                  return (
                    <>
                      <li className="m-0 p-0" key={key}>
                        <div className="m-0 px-2 pb-2 pt-3">{val}</div>
                      </li>
                      <hr className="line m-0 p-0"/>
                    </>
                  );
                })}
              </ul>
            </InfoModal>
          </> :
          null
        }
        <hr className="line d-md-none m-0 mt-1 p-0"/>
      </div> :
      null
  );
}


function propertyPrice(pricing) {
  const cash = `${thousandsSeparator(pricing.amount)} ${pricing.currency}`;
  if (pricing.rate_unit) {
    return (
      <span className="price">
                <span className="amount">{cash}</span>
                <span className="forward-slash"> / </span>
                <span className="rate">{i18n.t(pricing.rate_unit)}</span>
            </span>
    );
  }
  return (
    <span className="price">
            <span className="amount">{cash}</span>
        </span>
  );
}

function properyPaymentTerms(pricing) {
  if (pricing.payment_terms) {
    return (
      <>
        <hr className="line m-0 p-0 mt-1 mb-1"/>
        <div className="peyment-terms">
          {i18n.t("Payment terms")}: {pricing.payment_terms}
        </div>
      </>
    );
  }
  return null;
}

function propertyPricing(property) {
  if (property.pricing && PARENT_ONLY_PROPERTIES[property.type] === undefined) {
    return {
      price: propertyPrice(property.pricing),
      paymentTerms: properyPaymentTerms(property.pricing)
    };
  }
  return {};
}

function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

function Description(props) {
  if (props.property.description || props.property.title) {
    const propertyName = getPropertyName(props.property.type);
    return (
      <>
        {
          props.property.title ?
            <div className="mb-4">
              <div className="h4">
                {toTitleCase(props.property.title)}
              </div>
            </div> : null
        }
        <div className="mb-4">
          <div className="h5">
            {toTitleCase("About this " + propertyName)}
          </div>
          <div dangerouslySetInnerHTML={{ __html: props.property.description }}/>
        </div>
      </>
    );
  }
  return null;
}


function DayPicker(props) {
  const [dateRange, setDateRange] = useLocalState({
    startDate: props.dates.startDate,
    endDate: props.dates.endDate
  });
  const [focusedInput, setFocusedInput] = useState(props.autoFocusEndDate ? END_DATE : START_DATE);

  const handleDatesChange = ({ startDate, endDate }) => {
    if (props.onDateRangeChange) {
      props.onDateRangeChange({ startDate, endDate });
    }
    setDateRange({ startDate, endDate });
  };

  const onFocusChange = (focusedInput) => {
    setFocusedInput(
      // Force the focusedInput to always be truthy so that dates are always selectable
      !focusedInput ? START_DATE : focusedInput
    );
  };

  const isDayBlocked = (day) => {
    return day < moment.now();
  };

  return (
    <div className="day-picker">
      <DayPickerRangeController
        isDayBlocked={isDayBlocked}
        startDate={dateRange.startDate} // momentPropTypes.momentObj or null,
        endDate={dateRange.endDate} // momentPropTypes.momentObj or null,
        onDatesChange={handleDatesChange} // PropTypes.func.isRequired,
        initialVisibleMonth={() => moment().add(0, "M")} // PropTypes.func or null,
        orientation="verticalScrollable"
        numberOfMonths={6}
        focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        onFocusChange={onFocusChange} // PropTypes.func.isRequired,
      />
    </div>
  );
}


function ReservationSummary(props) {
  const properties = props.getProperties(props.reservation.properties);
  return (
    <div className="reservation-summary px-4">
      {properties.map(prop =>
        <div className="row p-0 m-0 property-to-reserve align-items-center justify-content-center">
          <div className="pricing col-6 p-0 m-0">
            {propertyPrice(prop.pricing)}
          </div>
          <div className="picture col-6 p-0 m-0 text-right">
            <img src={(prop.picture && prop.picture.length > 0) ? prop.picture[0].src : ""} alt="prop pic"/>
          </div>
        </div>
      )}
      <div className="row p-0 m-0 dates-guests-info align-items-center">
        <div className="col p-0 m-0 text-left" onClick={() => props.setDatePickerModalShow(true)}>
          <div className="col-12 p-0 m-0">{i18n.t("Check-in")}</div>
          <div className="col-12 p-0 m-0 value">{props.reservation.startDate.format("DD MMM")}</div>
        </div>
        <div className="col p-0 m-0 text-center" onClick={() => props.setDatePickerModalShow(true)}>
          <div className="col-12 p-0 m-0">{i18n.t("Check-out")}</div>
          <div className="col-12 p-0 m-0 value">{props.reservation.endDate.format("DD MMM")}</div>
        </div>
        <div className="col p-0 m-0 text-right">
          <div className="col-12 p-0 m-0">{i18n.t("Guests")}</div>
          <div className="col-12 p-0 m-0 value">{i18n.t("1 Guest")}</div>
        </div>
      </div>
      <div className="row p-0 m-0 total-cost align-items-center">
        <div className="col p-0 m-0 text-left">{i18n.t("Total")}</div>
        <div className="col p-0 m-0 text-right value">
          {`${props.reservation.currency} ${thousandsSeparator(props.reservation.totalCost)}`}
        </div>
      </div>
    </div>
  );
}

function Reservation(props) {
  const history = useHistory();
  const [user] = store.useState("user");
  const [, setShowLogInModal] = store.useState("showLogInModal");
  const [datePickerModalShow, changeDatePickerModalShow] = useState(false);
  const [reservationModalShow, setReservationModalShow] = useState(false);

  const setDatePickerModalShow = (bool) => {
    if (bool) {
      setDates({ ...props.reservation });
      if (!props.reservation.startDate || !props.reservation.endDate) {
        setIsDisabled(true);
      }
    }
    changeDatePickerModalShow(bool);
  };

  const [isLoading, setLoading] = useState(false);
  const [isLoadingCreatingReservation, setIsLoadingCreatingReservation] = useState(false);
  const [isDisabledCreatingReservation] = useState(false);
  const [editError, setEditError] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [dates, setDates] = useState({ ...props.reservation });

  const handleDateRangeChange = ({ startDate, endDate }) => {
    if (!startDate || !endDate) {
      setIsDisabled(true);
      return;
    }

    setDates({ startDate, endDate });
    let postUrl = `${BASE_API_URL}/check-reservation-availability/?format=json`;
    let headers = {
      "Content-Type": "application/json"
    };

    let formData = {
      properties: { add: props.reservation.properties },
      check_in_date: startDate.utc().format(),
      check_out_date: endDate.utc().format()
    };

    setLoading(true);
    fetch(postUrl, { method: "POST", body: JSON.stringify(formData), headers: headers })
      .then(res => res.json().then(data => ({ status: res.status, data: data })))
      .then(res => {
        if (res.status === 200) {
          setIsDisabled(false);
          setDates({
            startDate,
            endDate,
            totalCost: res.data.total_cost,
            currency: res.data.currency
          });
        } else {
          setIsDisabled(true);
        }
        if (res.status === 400) {
          setEditError("Invalid req");
        }
      })
      .catch(error => {
        // Network error
        //setEditError("No network connection, please try again!.");
      })
      .finally(() => {
        // Enable button
        setLoading(false);
      });
  };

  const btnState = () => {
    if (isDisabled) {
      return "disabled-btn";
    }
    return "active-btn";
  };

  const saveReservation = (e) => {
    if (props.updateReservation) {
      props.updateReservation((reservation) => {
        reservation.startDate = dates.startDate;
        reservation.endDate = dates.endDate;
        reservation.totalCost = dates.totalCost;
        reservation.currency = dates.currency;
      });
    }
    setDatePickerModalShow(false);
    setReservationModalShow(true);
  };

  const openDatePickerModal = (e) => {
    if (props.reservation.properties.length === 0) {
      toast.warning("Select property first to check availability");
      return;
    }
    setDatePickerModalShow(true);
  };

  const redirect = (response) => {
    if (response.status === 201) {
      return history.push(`/reservations/${response.data.id}/`);
    }
    if (response.status === 400) {
      setEditError("Invalid req");
    }
    return response;
  };

  const createReservation = (e) => {
    if (!user.isLoggedIn) {
      setReservationModalShow(false);
      setShowLogInModal(true);
      return;
    }

    setIsLoadingCreatingReservation(true);

    let postUrl = `${BASE_API_URL}/reservations/?format=json`;
    let headers = {
      "Authorization": `Token ${user.auth_token}`,
      "Content-Type": "application/json"
    };

    let formData = {
      properties: { add: props.reservation.properties },
      check_in_date: props.reservation.startDate.utc().format(),
      check_out_date: props.reservation.endDate.utc().format()
    };

    fetch(postUrl, { method: "POST", body: JSON.stringify(formData), headers: headers })
      .then(res => res.json().then(data => ({ status: res.status, data: data })))
      .then(res => redirect(res))
      .catch(error => {
        // Network error
        //setEditError("No network connection, please try again!.");
      })
      .finally(() => {
        // Enable button
        setIsLoadingCreatingReservation(false);
      });
  };

  return (
    <div className="reservation">
      <div className="row p-0 m-0 align-items-center justify-content-center">
        <div className="price-breakdown col-6">
          {props.reservation.startDate && props.reservation.endDate ?
            <span onClick={() => setDatePickerModalShow(true)}>
                            <span className="reservation-cost">
                                {props.reservation.totalCost && props.reservation.currency ?
                                  <>
                                    {`${props.reservation.currency} ${thousandsSeparator(props.reservation.totalCost)}`}
                                  </> :
                                  <>{i18n.t("Add dates for prices")}</>
                                }
                              <br/>
                            </span>
                            <span className="reservation-dates">
                                {props.reservation.startDate.format("DD MMM")} - {props.reservation.endDate.format("DD MMM")}
                            </span>
                        </span> :
            <span>{i18n.t("Add dates for prices")}</span>
          }
        </div>
        <div className="availability-btn col-6 text-right">
          {props.reservation.startDate && props.reservation.endDate ?
            <Button className="py-2" variant="primary"
                    onClick={() => setReservationModalShow(true)}>
              Reserve
            </Button> :
            <Button className="py-2" variant="primary"
                    onClick={openDatePickerModal}>
              {i18n.t("Check Availability")}
            </Button>
          }
        </div>
      </div>
      <InfoModal className="date-picker-modal" positionBottom header={i18n.t("Pick Your Dates")}
                 modalShow={datePickerModalShow} setModalShow={setDatePickerModalShow}>
        <DayPicker dates={dates} onDateRangeChange={handleDateRangeChange}/>

        <div className="reservation in-modal-btns">
          <div className="row p-0 m-0 align-items-center justify-content-center">
            <div className="price-breakdown col-6">
              {dates.startDate && dates.endDate ?
                <>
                                    <span className="reservation-cost">
                                        {dates.totalCost && dates.currency ?
                                          <>
                                            {`${dates.currency} ${thousandsSeparator(dates.totalCost)}`}
                                          </> :
                                          <>
                                            {isLoading ?
                                              <>...</> :
                                              <>Not available</>
                                            }
                                          </>
                                        }
                                      <br/>
                                    </span>
                  <span className="reservation-dates">
                                        {dates.startDate.format("DD MMM")} - {dates.endDate.format("DD MMM")}
                                    </span>
                </> :
                <span>{i18n.t("Pick Your Dates")}</span>
              }
            </div>

            <div className="availability-btn col-6 text-right">
              <Button className={`py-2 ${btnState()}`}
                      disabled={isDisabled} onClick={saveReservation}>
                {isLoading ? <Spinner animation="border" size="sm"/> : i18n.t("Save")}
              </Button>
            </div>

          </div>
        </div>
      </InfoModal>

      <InfoModal className="reservation-modal" scrollable
                 positionBottom header={i18n.t("Reservation Summary")}
                 modalShow={reservationModalShow} setModalShow={setReservationModalShow}>

        <ReservationSummary
          reservation={props.reservation}
          getProperties={props.getProperties}
          setDatePickerModalShow={setDatePickerModalShow}/>

        <div className="reservation in-modal-btns">
          <div className="row p-0 m-0 align-items-center justify-content-center">
            <Button className="reservation-btn col-10 col-md-6 py-2" variant="primary"
                    disabled={isDisabledCreatingReservation} onClick={createReservation}>
              {isLoadingCreatingReservation ?
                <Spinner animation="border" size="sm"/>
                : "Reserve"
              }
            </Button>
          </div>
        </div>
      </InfoModal>
    </div>
  );
}


function OneDayPicker(props) {
  const [date, setDate] = useLocalState(props.tour.date);
  const [focusedInput, setFocusedInput] = useState(props.autoFocusEndDate ? END_DATE : START_DATE);

  const handleDatesChange = (newDate) => {
    if (props.onDateRangeChange) {
      props.onDateRangeChange(newDate);
    }
    setDate(newDate);
  };

  const onFocusChange = (focusedInput) => {
    setFocusedInput(
      // Force the focusedInput to always be truthy so that dates are always selectable
      !focusedInput ? START_DATE : focusedInput
    );
  };

  const isDayBlocked = (day) => {
    return day < moment.now();
  };

  return (
    <div className="day-picker">
      <DayPickerSingleDateController
        isDayBlocked={isDayBlocked}
        initialDate={date}
        date={date}
        onDateChange={handleDatesChange} // PropTypes.func.isRequired,
        initialVisibleMonth={() => moment().add(0, "M")} // PropTypes.func or null,
        orientation="verticalScrollable"
        numberOfMonths={6}
        focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        onFocusChange={onFocusChange} // PropTypes.func.isRequired,
      />
    </div>
  );
}


function TourSummary(props) {
  const property = props.property;
  return (
    <div className="tour-summary px-4">
      <div className="row p-0 m-0 property-to-reserve align-items-center justify-content-center">
        <div className="pricing col-6 p-0 m-0">
          <i className="fa fa-map-marker-alt"></i>&nbsp;{property.location.address}
        </div>
        <div className="picture col-6 p-0 m-0 text-right">
          <img src={(property.pictures && property.pictures.length > 0) ? property.pictures[0].src : ""}/>
        </div>
      </div>
      <div className="row p-0 m-0 dates-guests-info align-items-center">
        <div className="col-6 p-0 m-0">{i18n.t("Tour Date")}</div>
        <div className="col-6 p-0 m-0 value text-right" onClick={() => props.setDatePickerModalShow(true)}>
          {props.tour.date.format("DD MMM YYYY")}
        </div>
      </div>
      <div className="row p-0 m-0 total-cost align-items-center">
        <div className="col p-0 m-0 text-left">{i18n.t("Tour Cost")}</div>
        <div className="col p-0 m-0 text-right value">
          {`${props.tour.currency} ${thousandsSeparator(props.tour.totalCost)}`}
        </div>
      </div>
    </div>
  );
}

function Tour(props) {
  const history = useHistory();
  const [user] = store.useState("user");
  const [, setShowLogInModal] = store.useState("showLogInModal");

  const [datePickerModalShow, changeDatePickerModalShow] = useState(false);
  const [tourModalShow, setTourModalShow] = useState(false);

  const setDatePickerModalShow = (bool) => {
    if (bool) {
      setTour({ ...props.tour });
      if (!props.tour.date) {
        setIsDisabled(true);
      }
    }
    changeDatePickerModalShow(bool);
  };

  const [isLoading, setLoading] = useState(false);
  const [isLoadingCreatingTour, setIsLoadingCreatingTour] = useState(false);
  const [isDisabledCreatingTour, setIsDisabledCreatingTour] = useState(false);
  const [editError, setEditError] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [tour, setTour] = useState(props.tour);

  const handleDateRangeChange = (newDate) => {
    if (!newDate) {
      setIsDisabled(true);
      return;
    }

    setTour({ date: newDate, totalCost: null, currency: null });

    if (newDate < moment.now()) {
      setIsDisabled(true);
      toast.error("Can't select past dates");
      return;
    }

    let postUrl = `${BASE_API_URL}/plans/1/?format=json`;
    let headers = {
      "Content-Type": "application/json"
    };

    setLoading(true);
    fetch(postUrl, { method: "GET", headers: headers })
      .then(res => res.json().then(data => ({ status: res.status, data: data })))
      .then(res => {
        if (res.status === 200) {
          setIsDisabled(false);
          setTour({
            date: newDate,
            totalCost: res.data.price_per_item,
            currency: res.data.currency
          });
        } else {
          setIsDisabled(true);
        }
        if (res.status === 400) {
          setEditError("Invalid req");
        }
      })
      .catch(error => {
        // Network error
        //setEditError("No network connection, please try again!.");
      })
      .finally(() => {
        // Enable button
        setLoading(false);
      });
  };

  const btnState = () => {
    if (isDisabled) {
      return "disabled-btn";
    }
    return "active-btn";
  };

  const saveTour = (e) => {
    if (props.updateTour) {
      props.updateTour((oldTour) => tour);
    }
    setDatePickerModalShow(false);
    setTourModalShow(true);
  };

  const openDatePickerModal = (e) => {
    setDatePickerModalShow(true);
  };

  const redirect = (response) => {
    if (response.status === 201) {
      return history.push(`/tours/${response.data.id}/`);
    }
    if (response.status === 400) {
      setEditError("Invalid req");
    }
    return response;
  };

  const createTour = (e) => {
    if (!user.isLoggedIn) {
      setTourModalShow(false);
      setShowLogInModal(true);
      return;
    }

    setIsLoadingCreatingTour(true);

    let postUrl = `${BASE_API_URL}/tours/?format=json`;
    let headers = {
      "Authorization": `Token ${user.auth_token}`,
      "Content-Type": "application/json"
    };

    let formData = {
      property: props.property.id,
      date: props.tour.date.utc().format()
    };

    fetch(postUrl, { method: "POST", body: JSON.stringify(formData), headers: headers })
      .then(res => res.json().then(data => ({ status: res.status, data: data })))
      .then(res => redirect(res))
      .catch(error => {
        // Network error
        //setEditError("No network connection, please try again!.");
      })
      .finally(() => {
        // Enable button
        setIsLoadingCreatingTour(false);
      });
  };

  return (
    <div className="tour">
      <div className="row p-0 m-0 align-items-center justify-content-center">
        <div className="price-breakdown col-6">
          {props.tour.date && props.tour.totalCost && props.tour.currency ?
            <span onClick={() => setDatePickerModalShow(true)}>
                            <span className="tour-cost">
                                {`${props.tour.currency} ${thousandsSeparator(props.tour.totalCost)}`}
                              <br/>
                            </span>
                            <span className="tour-date">
                                {props.tour.date.format("DD MMM YYYY")}
                            </span>
                        </span> :
            <span>
                            Add date for the price
                        </span>
          }
        </div>
        <div className="availability-btn col-6 text-right">
          {props.tour.date ?
            <Button className="py-2" variant="primary"
                    onClick={() => setTourModalShow(true)}>
              {i18n.t("Book Tour")}
            </Button> :
            <Button className="py-2" variant="primary"
                    onClick={openDatePickerModal}>
              {i18n.t("Book Tour")}
            </Button>
          }
        </div>
      </div>
      <InfoModal className="date-picker-modal" positionBottom header="Pick Your Date"
                 modalShow={datePickerModalShow} setModalShow={setDatePickerModalShow}>
        <OneDayPicker tour={tour} onDateRangeChange={handleDateRangeChange}/>

        <div className="tour in-modal-btns">
          <div className="row p-0 m-0 align-items-center justify-content-center">
            <div className="price-breakdown col-6">
              {tour.date ?
                <>
                                    <span className="tour-cost">
                                        {tour.totalCost && tour.currency ?
                                          <>
                                            {`${tour.currency} ${thousandsSeparator(tour.totalCost)}`}
                                          </> :
                                          <>
                                            {isLoading ?
                                              <>...</> :
                                              <>Not available</>
                                            }
                                          </>
                                        }
                                      <br/>
                                    </span>
                  <span className="tour-date">
                                        {tour.date.format("DD MMM YYYY")}
                                    </span>
                </> :
                <span>Pick your date</span>
              }
            </div>
            <div className="availability-btn col-6 text-right">
              <Button className={`py-2 ${btnState()}`}
                      disabled={isDisabled} onClick={saveTour}>
                {isLoading ? <Spinner animation="border" size="sm"/> : "Save"}
              </Button>
            </div>
          </div>
        </div>
      </InfoModal>

      <InfoModal className="tour-modal" scrollable
                 positionBottom header="Tour Summary"
                 modalShow={tourModalShow} setModalShow={setTourModalShow}>
        <TourSummary
          tour={props.tour}
          property={props.property}
          setDatePickerModalShow={setDatePickerModalShow}/>

        <div className="tour in-modal-btns">
          <div className="row p-0 m-0 align-items-center justify-content-center">
            <Button className="tour-btn col-10 col-md-6 py-2" variant="primary"
                    disabled={isDisabledCreatingTour} onClick={createTour}>
              {isLoadingCreatingTour ?
                <Spinner animation="border" size="sm"/>
                : i18n.t("Book Tour")
              }
            </Button>
          </div>
        </div>
      </InfoModal>
    </div>
  );
}


function GenericPropertyDetails(props) {
  useScrollTop();
  const property = props.property;
  const history = useHistory();
  const parsed = parse(history.location.search);
  const [user] = store.useState("user");
  const [loading, setLoading] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [reservation, , updateReservation] = useLocalState({
    properties: PARENT_ONLY_PROPERTIES[property.type] ? [] : [property.id],
    startDate: null,
    endDate: null,
    totalCost: null,
    currency: null
  });
  const [tour, , updateTour] = useLocalState({
    date: null,
    totalCost: null,
    currency: null
  });

  const isAllowedToEdit = user.id == property.owner.id || user.is_staff;

  let main_img = property.pictures.filter((picture) => picture.is_main);
  if (main_img.length < 1) {
    main_img = { is_main: null, src: null, id: null };
  } else {
    main_img = main_img[0];
  }

  let other_imgs = property.pictures.filter((picture) => !picture.is_main).slice(0, 4);

  let redirect = (status) => {
    if (status === 204) {
      // Invalidate user properties
      queryCache.invalidateQueries(`myProperties.properties`);
      queryCache.invalidateQueries(`myProperties.${getPropertyRoute(property.type)}`);
      return history.replace("/properties/");
    }
    // Report Error
  };

  let deleteProperty = (e) => {
    let postUrl = `${BASE_API_URL}/${getPropertyRoute(property.type)}/${property.id}/`;
    let headers = {
      "Authorization": `Token ${user.auth_token}`,
      "Content-Type": "application/json"
    };
    fetch(postUrl, { method: "DELETE", headers: headers })
      .then(res => res.status)
      .then(status => redirect(status));
  };

  const confirmDeletionOptions = [
    { label: "Yes", onClick: deleteProperty, variant: "danger" },
    {
      label: "No", onClick: function(e) {
        setDeleteModalShow(false);
      }, variant: "secondary"
    }
  ];
  const confirmDeletionText = "Are you sure you want to delete this property permanently?.";

  let child;
  if (props.children === undefined) {
    child = {};
  } else {
    child = props.children(property);
  }

  const scrollToMap = (e) => {
    scrollIntoView({
      elementID: "location-on-map",
      yOffset: -110,
      behavior: "smooth"
    });
  };

  const pricing = propertyPricing(property);

  useEffect(() => {
    const compartmentID = parsed.compartment;
    scrollIntoView({
      elementID: `compartment-${compartmentID}`,
      yOffset: -150,
      behavior: "smooth"
    });
  }, []);

  const selectCompartment = (id) => {
    updateReservation((reservation) => {
      reservation.properties.push(id);

      // Reset date range
      reservation.startDate = null;
      reservation.endDate = null;
    });
  };

  const unselectCompartment = (id) => {
    updateReservation((reservation) => {
      reservation.properties = reservation.properties.filter(item => item != id);

      // Reset date range
      reservation.startDate = null;
      reservation.endDate = null;
    });
  };

  const getProperties = (ids) => {
    let properties = PARENT_ONLY_PROPERTIES[property.type] ? property.compartments : [property];
    properties = properties.filter(prop => ids.indexOf(prop.id) >= 0);
    return properties.map(prop => ({
      id: prop.id,
      pricing: prop.pricing,
      picture: prop.pictures.filter(p => p.is_main)
    }));
  };

  let resendPublishRequest = (e) => {
    setLoading(true);
    let postUrl = `${BASE_API_URL}/resend-publish-request/`;
    let headers = {
      "Authorization": `Token ${user.auth_token}`,
      "Content-Type": "application/json"
    };
    let formData = {
      publish_request: property.publish_request.id
    };
    fetch(postUrl, { method: "POST", body: JSON.stringify(formData), headers: headers })
      .then(res => res.status)
      .then(status => {
        if (status === 200) {
          queryCache.invalidateQueries(`property/${property.id}`);
        }
      })
      .catch(error => {
        toast.error("No network connection, please try again!.");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  let property_url = `${BASE_URL}/${getPropertyRoute(property.type)}/${property.id}/`;
  console.log(property);
  return (
    <>
      <Helmet>
        <title>{"Seto - " + property.title}</title>
        <link rel="canonical" href={property_url}/>
        <meta name="description" content={"Seto - " + property.title}/>
        <meta property="og:url" content={property_url}/>
        <meta property="og:site_name" content="Seto"/>
        <meta property="og:description" content={property.title}/>
      </Helmet>
      <div className="row p-0 m-0 property-details">
        <div className="property-images col-12 p-0 m-0 d-md-none">
          <ImagesModalCarousel activeImage={main_img} images={property.pictures}/>
          <span className="save">
                    <SaveButton property={property}/>
                </span>
        </div>

        <div className="property-images-md col-12 p-0 m-0 d-none d-md-flex">
          <MainPropertyImage activeImage={main_img} images={property.pictures}/>
          <div className="other-images d-none d-lg-block col-6 p-0 m-0">
            <div className="row m-0 p-0">
              <OthersPropertyImages otherImages={other_imgs} images={property.pictures}/>
            </div>
          </div>
          <span className="save">
                    <SaveButton property={property}/>
                </span>
        </div>

        {isAllowedToEdit ?
          <div className="col-12 p-0 m-0">
            <div className="actions row m-0 p-0">
              <div className="col text-center py-2 p-0 m-0">
                <ConfirmModal size="md" modalShow={deleteModalShow} setModalShow={setDeleteModalShow}
                              options={confirmDeletionOptions} text={confirmDeletionText}/>
                <Link to={"#"} className="delete-property text-decoration-none" onClick={() => {
                  setDeleteModalShow(true);
                }}>
                  <b><span className="fa fa-trash mt-2 mr-1 mr-lg-3 delete-property-icon"/> Delete</b>
                </Link>
              </div>
              <div className="col text-center py-2 p-0 m-0">
                <Link to={`/edit/${getPropertyRoute(property.type)}/${property.id}`}
                      className="edit-property text-decoration-none">
                  <b><span className="fas fa-edit mt-2 mr-1 mr-lg-3 edit-property-icon"/> Edit</b>
                </Link>
              </div>
            </div>
            <hr className="line m-0 p-0"/>
            {property.publish_request.status !== "accepted" ?
              <div className="publish-info row m-0 p-0 px-3 px-sm-4">
                {property.publish_request.status === "pending" ?
                  <div className="msg pending col-12 p-0 m-0">
                    {user.id == property.owner.id ?
                      <>
                        This property is not yet published,
                        it's under review, we will let you know when it's done.
                      </> :
                      <>This property is not yet published,
                        it's under review.</>
                    }
                  </div> : null
                }
                {property.publish_request.status === "declined" ?
                  <div className="msg rejected col-12 p-0 m-0">
                    {user.id == property.owner.id ?
                      <>
                        <p>This property is not published for reasons which were sent to
                          you.</p>
                        <p>You can do changes as suggested and re-request a review</p>
                        <Button onClick={resendPublishRequest} disabled={loading}
                                className="request-btn" variant="primary">
                          {loading ?
                            <Spinner animation="border" size="sm"/> : "Send Review Request"}
                        </Button>
                      </> :
                      <>
                        This property is declined.
                      </>
                    }
                  </div> : null
                }
              </div> : null
            }
          </div> :
          null
        }

        <div className="col-12 p-0 m-0">
          <div className="row p-0 m-0 px-3 px-sm-4 mt-1 mt-md-4 pt-md-2 text-dark">
            <div className="detailed-prop-info col-12 col-md-5 p-0 m-0 pl-md-2 order-1 order-md-2">
              <div className="prop-info-card sticky-top bw-0 bw-md-1 py-1 px-md-3 py-md-3">
                <div className="row">
                  <div className="col-7">
                    <div
                      className="property-type">{i18n.t(getPropertyName(property.type))} {i18n.t("for")}
                      <span
                        className="bg-primary text-light">{i18n.t(property.available_for)}</span>
                    </div>
                    <div className="property-location mt-2"><i className="fa fa-map-marker-alt"></i>
                      &nbsp;{property.location.address}
                    </div>
                    <div className="scroll-to-map mt-2">
                      <Link to={"#"} onClick={scrollToMap}>
                        <span className={"icon icon-map-location"}/> {i18n.t("View on map")}
                      </Link>
                    </div>
                  </div>

                  <div className="col text-right">
                    {property.available_for !== "book" ?
                      <div className="property-post-time mb-1">
                        <i
                          className="fa fa-clock"></i> {capitalizeFirst(formatDistance(new Date(property.post_date), new Date()))}
                      </div> : null
                    }
                    <div className="property-rating p-0 m-0">
                      <div className="summary">
                        <span className="score">{property.total_rating_score}</span>
                        <span className="reviews-count">
                            ({property.raters_count} {property.raters_count <= 1 ? i18n.t("Review") : i18n.t("Reviews")})
                        </span>
                      </div>
                      <Rating property={property}/>
                    </div>
                  </div>
                </div>

                <div className={"row"}>
                  <div className="property-price mt-2 col-6">
                    <span className="price">
                    <span className="amount">
                       Price:
                    </span>
                    </span>
                  </div>
                  <div className="property-price mt-2 col text-right">
                    {pricing.price}
                  </div>
                </div>

                {
                  property.pricing.agent_fee !== null ?
                  <div className="row">
                    <div className="property-price mt-2 col-6">
                    <span className="price">
                    <span className="amount">
                       Agent Fee:
                    </span>
                    </span>
                    </div>
                    <div className="property-price mt-2 col text-right">
                      <span className="price">
                    <span className="amount">
                      {thousandsSeparator(property.pricing.agent_fee)} {property.pricing.currency}
                    </span>
                    </span>
                    </div>
                  </div>
                  : null
                }

                {pricing.paymentTerms}

                <hr className="line m-0 p-0 my-2"/>

                <div className="row">
                  {child.otherFeatures}
                  {property.other_features.map((feature) => {
                    return <div className="col-6 other-feature">
                      <b>{feature.name}:</b> {feature.value}</div>;
                  })}
                </div>

                {child.otherFeatures || property.other_features.length > 0 ?
                  <hr className="line m-0 p-0 mt-2 mb-4"/> : null
                }

                <Contact property={property}/>
                <hr className="line d-lg-none m-0 p-0 mt-3 mb-2"/>
              </div>
            </div>

            <div className="col-12 col-md-7 p-0 m-0 mt-3 mt-sm-0 pr-md-2 text-dark order-2 order-md-1">
              <Description property={property}/>
              <Badges values={property.amenities.map((amenity) => amenity.name)} label="Amenities"/>
              <Badges values={property.services.map((service) => service.name)} label="Nearby Services"/>
            </div>
          </div>

          {property.compartments.length > 0 ?
            <div className="row p-0 m-0 px-2 px-sm-3 my-4">
              <div className="col-8 p-0 m-0 pl-2 h5">
                {i18n.t("Compartments")}
              </div>
              {user.isLoggedIn && user.id == property.owner.id ?
                <div className="col-4 p-0 m-0 text-right pr-1">
                  <Link className="text-decoration-none edit-compartments-link"
                        to={`/create-compartments/${property.id}`}>
                    <span className="fas fa-edit"/> {i18n.t("Manage")}
                  </Link>
                </div> : <div className="w-100 p-0 m-0"></div>
              }
              {property.compartments.map((compartment, i) =>
                <div className={`col-6 col-md-4 col-lg-3 m-0 p-0 my-2 px-1 px-sm-2`}>
                  <PropertyOverview id={`compartment-${compartment.id}`}
                                    key={i} property={compartment} actualPropertyPath/>
                  {reservation.properties.includes(compartment.id) ?
                    <div className="unselect-compartment"
                         onClick={(e) => unselectCompartment(compartment.id)}>
                      {i18n.t("Unselect")}
                    </div> :
                    <div className="select-compartment"
                         onClick={(e) => selectCompartment(compartment.id)}>
                      {i18n.t("Select")}
                    </div>
                  }
                </div>
              )}
            </div> :
            <>
              {user.isLoggedIn &&
              user.id == property.owner.id &&
              PARENT_ONLY_PROPERTIES[property.type] !== undefined ?
                <div className="col-12 p-0 m-0 text-right px-3">
                  <Link className="text-decoration-none edit-compartments-link"
                        to={`/create-compartments/${property.id}`}>
                    <span className="fas fa-edit"/> {i18n.t("Manage Compartments")}
                  </Link>
                  <hr className="line p-0 m-0 mb-4 mt-1"/>
                </div> : <div className="w-100 p-0 m-0"></div>
              }
            </>
          }

          <div id="location-on-map" className="row p-0 m-0 px-3 px-sm-4 mt-md-4">
            <div className="col-12 p-0 m-0 h4">{i18n.t("Location")}</div>
            <div className="col-12 p-0 m-0">
              {property.location.address}
            </div>
            <div className="col-12 map p-0 m-0">
              <Map location={{
                address: property.location.address,
                point: { lng: property.location.longitude, lat: property.location.latitude }
              }}/>
            </div>
          </div>
          <div className="p-0 m-0 mt-4">
            <NearbyProperties except={property.id}
                              point={{ lng: property.location.longitude, lat: property.location.latitude }}/>
          </div>
        </div>
        {property.is_published && property.available_for === "book" ?
          <Reservation
            reservation={reservation}
            updateReservation={updateReservation}
            getProperties={getProperties}
          /> : null
        }
        {property.is_published && property.available_for === "rent" || property.available_for === "sale" ?
          <Tour
            tour={tour}
            property={property}
            updateTour={updateTour}
          /> : null
        }
      </div>
    </>
  );
}

function PropertyDetailsFetcher(props) {
  const [user] = store.useState("user");

  const headers = {
    "Content-Type": "application/json"
  };

  if (user.isLoggedIn) {
    headers["Authorization"] = `Token ${user.auth_token}`;
  }

  let fetchProperty = () => {
    return fetch(`${BASE_API_URL}/${getPropertyRoute(props.type)}/${props.id}/`, { headers: headers })
      .then(res => res.json().then(data => ({ statusCode: res.status, data })));

  };

  return (
    <DataFetcher action={fetchProperty} selection={`property/${props.id}`}
                 placeholder={<BouncePageLoader/>} onError={renderPageError}>{response => {
      if (response.data.statusCode === 404) {
        return <NotFoundError/>;
      }
      return <GenericPropertyDetails property={response.data.data}/>;
    }}</DataFetcher>
  );
}


function NearbyProperties(props) {
  const nearbyPropertiesEndpoint = `
    properties/?${PROPERTIES_QUERY_PARAM}
    &longitude=${props.point.lng}
    &latitude=${props.point.lat}
    &radius_to_scan=5000
    `;
  const viewAllTitle = `View All`;
  const viewAllLink = `/nearby-properties/?lng=${props.point.lng}&lat=${props.point.lat}&radius_to_scan=5000`;
  const selection = viewAllLink;

  return (
    <GenericFilter endpoint={nearbyPropertiesEndpoint} global selection={selection}
                   placeholder={<BounceBlockLoader/>} onError={renderInlineError}>{response => {
      let data = response.data[0];
      // Remove this current property to nearby properties
      data.results = data.results.filter(property => property.id != props.except);

      if (data.results.length < 3) {
        return null;
      }
      return (
        <div className="p-0 m-0 mt-4 nearby-properties">
          <SliderPropertiesGroup
            selection={`nearby/property/${props.except}`}
            header="Nearby Properties"
            response={response}
            viewAllLink={viewAllLink}
            viewAllTitle={viewAllTitle}
          />
        </div>
      );
    }}
    </GenericFilter>
  );
}


function Contact(props) {
  const [user] = store.useState("user");
  const history = useHistory();
  const contact = props.property.contact;
  const ownerPicture = props.property.owner.picture;
  const propertyURL = window.location.hostname + "/#" + history.location.pathname + "  ";

  const shouldSendMessage = (e) => {
    if (user.id === props.property.owner.id) {
      toast.error("Can't send message to yourself");
      return e.preventDefault();
    }
  };
  return (
    <div className="contact col-12 p-0 m-0">
      <div className="h5 p-0 m-0 mt-3">
        {i18n.t("Meet your")} {props.property.available_for === "book" ? i18n.t("host") : i18n.t("agent")}
      </div>
      <hr className="line m-0 p-0 mt-1 mb-2"/>

      <div className="row p-0 m-0">
        <div className="col-10 p-0 m-0">
          <div className="owner-name">
            <span><b>{contact.name}</b> </span>
            {props.property.owner.is_verified ?
              <span className="icon icon-verified"/> : null
            }
          </div>
          <div className="date-joined mt-1">
                        <span>
                        Joined in {moment(props.property.owner.date_joined).format("MMM Y")}
                        </span>
          </div>
          <div className="message-link mt-1">
            <Link className="chat" to={{
              pathname: `/conversations/${props.property.owner.id}`,
              message: propertyURL
            }} onClick={shouldSendMessage}>
              <b>{i18n.t("Send Message")} </b> <span className="icon icon-chat-bar"/>
            </Link>
          </div>

        </div>
        <div className="col-2 row p-0 m-0 mt-1 d-flex justify-content-end">
          <div className="col-12">
            <Link to={`/users/${props.property.owner.id}/`}>
              <div className="owner-profile-picture text-center">
                {!ownerPicture ?
                  <span className="icon icon-user"/> :
                  <img src={ownerPicture.src} alt=""/>
                }
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}


function RoomDetails(props) {
  return (
    <PropertyDetailsFetcher type="room" id={props.id}>
      {property => ({})}
    </PropertyDetailsFetcher>
  );
}


function HouseDetails(props) {
  return (
    <PropertyDetailsFetcher type="house" id={props.id}>
      {property => ({})}
    </PropertyDetailsFetcher>
  );
}


function ApartmentDetails(props) {
  return (
    <PropertyDetailsFetcher type="apartment" id={props.id}>
      {property => ({})}
    </PropertyDetailsFetcher>
  );
}


function OfficeDetails(props) {
  return (
    <PropertyDetailsFetcher type="office" id={props.id}>
      {property => ({})}
    </PropertyDetailsFetcher>
  );
}


function isRegistered(registered) {
  if (registered === "Y") {
    return i18n.t("Yes");
  }
  return i18n.t("No");
}


function LandDetails(props) {
  return (
    <PropertyDetailsFetcher type="land" id={props.id}>
      {property => ({
        otherFeatures: (
          <>
            {property.length ?
              <div className="property-length">
                {i18n.t("Length")}: {property.length}
              </div> : null
            }

            {property.width ?
              <div className="property-width">
                {i18n.t("Width")}: {property.width}
              </div> : null
            }
            {property.area ?
              <div className="property-area">
                {i18n.t("Area")}: {property.area}
              </div> : null
            }
            <div className="property-is-registered">
              {i18n.t("Is Registered")}: {isRegistered(property.is_registered)}
            </div>
          </>
        )
      })}
    </PropertyDetailsFetcher>
  );
}


function HotelDetails(props) {
  return (
    <PropertyDetailsFetcher type="hotel" id={props.id}>
      {property => ({})}
    </PropertyDetailsFetcher>
  );
}

function VenueDetails(props) {
  return (
    <PropertyDetailsFetcher type="venue" id={props.id}>
      {property => ({})}
    </PropertyDetailsFetcher>
  );
}

function LodgeDetails(props) {
  return (
    <PropertyDetailsFetcher type="lodge" id={props.id}>
      {property => ({})}
    </PropertyDetailsFetcher>
  );
}

function GuestHouseDetails(props) {
  return (
    <PropertyDetailsFetcher type="guest_house" id={props.id}>
      {property => ({})}
    </PropertyDetailsFetcher>
  );
}


const properties = {
  "room": RoomDetails,
  "house": HouseDetails,
  "apartment": ApartmentDetails,
  "office": OfficeDetails,
  "land": LandDetails,
  "venue": VenueDetails,
  "hotel": HotelDetails,
  "hotel_room": RoomDetails,
  "hotel_venue": VenueDetails,
  "hotel_apartment": ApartmentDetails,
  "lodge": LodgeDetails,
  "lodge_room": RoomDetails,
  "guest_house": GuestHouseDetails,
  "guest_house_room": RoomDetails
};

function PropertyDetails(props) {
  const id = props.id;
  const type = props.type;
  const PropertyDetailsComponent = properties[type];
  return <PropertyDetailsComponent id={id} type={type}/>;
}

function GenericProperty(props) {
  const [user] = store.useState("user");

  const headers = {
    "Content-Type": "application/json"
  };

  if (user.isLoggedIn) {
    headers["Authorization"] = `Token ${user.auth_token}`;
  }

  let fetchProperty = () => {
    return fetch(`${BASE_API_URL}/properties/${props.id}/?query={id, type}`, { headers: headers })
      .then(res => res.json().then(data => ({ statusCode: res.status, data })));

  };

  return (
    <DataFetcher action={fetchProperty} selection={`partial-property/${props.id}`}
                 placeholder={<BouncePageLoader/>} onError={renderPageError}>{response => {
      if (response.data.statusCode === 404) {
        return <NotFoundError/>;
      }
      return <PropertyDetails id={props.id} type={response.data.data.type}/>;
    }}</DataFetcher>
  );
}

export { PropertyDetails, GenericProperty };
