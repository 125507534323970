import React, { useState } from 'react';
import './CreateCompartment.scss';
import { Link } from 'react-router-dom';
import { store } from '../store';
import {
    PropertyOverview, DataFetcher, BouncePageLoader, renderPageError,
    NotFoundError, InfoModal
} from './';
import { BASE_API_URL } from '../';
import { getPropertyRoute, PARENT_ONLY_PROPERTIES, STANDALONE_PROPERTY_NAMES } from '../utils';


function CreateProperty(props) {
    const [modalShow, setModalShow] = useState(false);

    let property_types = PARENT_ONLY_PROPERTIES[props.parentProperty.type]

    return (
        <>
            <Link className="lazy-container" onClick={() => setModalShow(true)}>
                <div className="create-icon col-12 p-0 m-0 text-center">
                    <span className="icon icon-add" />
                    <div className="col-12 p-0 m-0">Add compartment</div>
                </div>
            </Link>
            <InfoModal scrollable header="Select Compartment Type" modalShow={modalShow} setModalShow={setModalShow}>
                <ul className="m-0 p-0">
                    {property_types.map((val) => {
                        return (
                            <>
                                <li className="p-0 m-0">
                                    <Link className="d-block m-0 p-0 px-2 pb-2 pt-3 create-property-link"
                                    to={`/create/${getPropertyRoute(val)}?type=${
                                        props.parentProperty.type+'_'+ val
                                    }&available_for=book&parent_property=${props.parentProperty.id}`}
                                    onClick={() => setModalShow(false)}>
                                        {STANDALONE_PROPERTY_NAMES[val]}
                                    </Link>
                                </li>
                                <hr className="line m-0 p-0" />
                            </>
                        )
                    })}
                </ul>
            </InfoModal>
        </>
    );
}


function CreateCompartment(props) {
    return (
        <div className="row p-0 m-0 px-1 px-md-3 manage-compartment">
            <div className="manage-compartments-header col-12 m-0 p-0 px-1 mt-2 mt-md-3">
                Manage Compartments
            </div>
            {props.parentProperty.compartments.map((property, i) =>
                <div className={`col-6 col-md-4 col-lg-3 m-0 p-0 my-2 px-1 px-sm-2`}>
                    <PropertyOverview actualPropertyPath key={i} property={property} />
                </div>
            )}
            <div className={`col-6 col-md-4 col-lg-3 m-0 p-0 my-2 px-1 px-sm-2`}>
                <div className="prop-overview row p-0 m-0">
                    <div className="prop-img col-12 p-0 m-0">
                        <CreateProperty parentProperty={props.parentProperty}/>
                    </div>
                </div>
            </div>
        </div>
    );
}


function ManageCompartments(props) {
    const [user,] = store.useState("user");

    const headers = {
        "Content-Type": "application/json",
    }

    if (user.isLoggedIn) {
        headers["Authorization"] = `Token ${user.auth_token}`
    }

    let fetchProperty = () => {
        return fetch(
            `${BASE_API_URL}/properties/${props.id}/`,
            { headers: headers }
        )
        .then(res => res.json().then(data => ({ statusCode: res.status, data })))
    }

    return (
        <DataFetcher
            selection={`property/${props.id}`}
            action={fetchProperty}
            placeholder={<BouncePageLoader />}
            onError={renderPageError}>
            {response => {
                if (response.data.statusCode === 404) {
                    return <NotFoundError />
                }
                const parentProperty = response.data.data;
                return (
                    <CreateCompartment parentProperty={parentProperty} />
                )
            }}
        </DataFetcher>
    );
}


export {
    ManageCompartments
}
