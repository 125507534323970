import React from 'react';
import './PublishRequestOverview.scss';
import { Link } from 'react-router-dom';
import {
    getPropertyName,
    PARENT_ONLY_PROPERTIES,
    thousandsSeparator
} from '../utils';


function pricing(pricing) {
    const cash = <span className="price">{pricing.currency} {thousandsSeparator(pricing.amount)}</span>
    if (pricing.rate_unit) {
        return <span>{cash} / {pricing.rate_unit}</span>;
    }
    return cash;
}

function price(property) {
    if(property.pricing && PARENT_ONLY_PROPERTIES[property.type] === undefined){
        return pricing(property.pricing)
    }
    return null;
}

function PublishRequestOverview(props) {
    const publishRequest = props.publishRequest;
    const property = publishRequest.property;
    let main_img = {is_main: null, src: null, id: null}

    let location = null;
    location = property.location
    main_img = property.pictures.filter((picture) => picture.is_main);
    if (main_img.length > 0) {
        main_img = main_img[0];
    }

    let path = `/publish-requests/${publishRequest.id}`

    return (
        <Link className="publish-request-overview row p-0 m-0" to={path} id={props.id}>
            <div className="publish-request-info col-6 p-0 m-0">
                <div className="type">{getPropertyName(property.type)} for <span className="bg-primary text-light">{property.available_for}</span></div>
                <div className="location"> <i className="fa fa-map-marker-alt"></i>
                    &nbsp;{property.location.address}
                </div>
                <div className="prop-price">
                    {price(property)}
                </div>
            </div>
            <div className="publish-request-img col-6 p-0 m-0 text-right">
                <img src={main_img.src} alt="" />
            </div>
        </Link>
    );
}


export { PublishRequestOverview }
