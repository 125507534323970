import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { store } from "../store";
import "./LogIn.scss";
import { BASE_API_URL } from "../";
import { Modal, Nav, Button, Spinner } from "react-bootstrap";
import {
  setErrorClass, saveUserInfoToCookies,
  setTabColorDark, buildMessageFromResponse
} from "../utils";
import { useTranslation } from "react-i18next";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import PinField from "react-pin-field";

function PinPage(props) {
  const [pinPageError, setPinPageError] = useState("");
  const [pinPageSuccess, setPinPageSuccess] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [completed, setCompleted] = useState(false);
  let formInput = props.formInput;
  useEffect(setPinPageError, []);

  let showResponse = (response) => {

    let msg = "";
    if (response.status === 200) {
      msg = 'Login success';
      setPinPageSuccess(msg);
      let auth_token = response.data.token;
      if (auth_token !== undefined) {
        let userInfo = {
          id: response.data.id,
          auth_token: auth_token
        };
        saveUserInfoToCookies(userInfo);
        window.location = "/";
      } else if (response.data.non_field_errors !== undefined) {
        setPinPageError("Invalid credentials, please try again!.");
      } else {
        setPinPageError("Failed to login, please try again!.");
      }
    } else if (response.status === 400) {
      msg = buildMessageFromResponse(response.data);
      setPinPageError(msg);
    } else {
      msg = "Something went wrong! Please try again later";
      setPinPageError(msg);
    }
  };

  let verifyPin = (event) => {
    event.preventDefault();
    setPinPageError("");
    setLoading(true);

    let form_data = new FormData();
    form_data.append("phone", formInput.phone.replace(formInput.dial_code, ""));
    form_data.append("dial_code", "+" + formInput.dial_code);
    form_data.append("iso_code", formInput.iso_code);
    form_data.append("otp", code);

    let pinVerificationUrl = `${BASE_API_URL}/otp-verify/`;

    fetch(pinVerificationUrl, { method: "POST", body: form_data })
      .then(res => res.json().then(data => ({ status: res.status, data: data })))
      .then(data => showResponse(data))
      .catch(error => {
        // Network error
        setPinPageError(
          "No network connection, please try again!."
        );
      })
      .finally(() => {
        // Enable button
        setLoading(false);
      });
  };

  let clearErrors = () => {
    setPinPageError("");
  };

  return (
    <div className="container-fluid login py-4">
      <div className="header col-12 h5 m-0 p-0 pt-2 text-center text-secondary">Pin Verification</div>
      <form className="login-form text-secondary mt-3" onSubmit={verifyPin}>
        <div className="row justify-content-center">
          <div className="col-10 p-0 m-0 px-2 my-2 my-lg-3 text-center">
            Enter OTP number <br/>
            Check your email or phone number registered in Seto.
          </div>
          <div className="col-10 p-0 m-0 my-2 my-lg-3">
            <div className="col-12 px-2 floating text-center">
              <PinField
                length={4}
                className="pin-field"
                onChange={setCode}
                onComplete={() => setCompleted(true)}
              />
            </div>
          </div>

          {pinPageError ?
            <div className="col-10 p-0 m-0 text-danger text-center mt-2 mt-lg-3">
              {pinPageError}
            </div> : null
          }

          {pinPageSuccess ?
            <div className="col-10 p-0 m-0 text-success text-center mt-2 mt-lg-3">
              {pinPageSuccess}
            </div> : null
          }

          <div className="col-10 p-0 m-0 my-2 my-lg-3">
            <div className="col-12 px-2">
              <Button className="col-12 my-3" variant="primary" disabled={!completed} type="submit">
                {isLoading ? <Spinner animation="border" size="sm"/> : "Verify OTP"}
              </Button>
            </div>
          </div>
        </div>
      </form>
      <div className="col-12 pb-3 text-center">
        {isLoading ? <br/> : <Link to={"#"} onClick={() => props.setPinPage(false)}>Go back to Login</Link> }

      </div>
    </div>
  );
}

function LogIn(props) {
  const [loginError, setLoginError] = useState("");
  const [showLogInModal, setShowLogInModal] = store.useState("showLogInModal");
  const [showSignUpModal, setShowSignUpModal] = store.useState("showSignUpModal");
  const [isLoading, setLoading] = useState(false);
  const [formInput, setFormInput] = useState({
    phone: "",
    iso_code: "",
    dial_code: "",
    pin: ""
  });
  // const [resetPassword, setResetPassword] = useState(false);
  const [pinPage, setPinPage] = useState(false);

  useEffect(setErrorClass, []);
  setTabColorDark(showLogInModal || showSignUpModal, "#151515");

  let showResponse = (response) => {
    console.log(response);
    if (response.status === 200) {
      setPinPage(true);
    }else if (response.status === 400) {
      let msg = buildMessageFromResponse(response.data);
      setLoginError(msg);
    } else {
      setLoginError(response.data)
    }
  };

  let login = (event) => {
    event.preventDefault();
    setLoginError("");
    setLoading(true);

    let form_data = new FormData();
    form_data.append("phone", formInput.phone.replace(formInput.dial_code, ""));
    form_data.append("dial_code", "+" + formInput.dial_code);
    form_data.append("iso_code", formInput.iso_code);

    let loginUrl = `${BASE_API_URL}/otp-login/`;

    fetch(loginUrl, { method: "POST", body: form_data })
      .then(res => res.json().then(data => ({ status: res.status, data: data })))
      .then(data => showResponse(data))
      .catch(error => {
        // Network error
        setLoginError("No network connection, please try again!.");
      })
      .finally(() => {
        // Enable button
        setLoading(false);
      });
  };

  let goToSignUp = (e) => {
    setShowLogInModal(false);
    setShowSignUpModal(true);
  };

  let clearErrors = () => {
    setLoginError("");
  };

  const { t } = useTranslation();
  return (
    <>
      <Nav.Link href="#" onClick={() => setShowLogInModal(true)}><span
        className="d-lg-none icon icon-enter"/>{t("Login")}</Nav.Link>

      <Modal animation={false} scrollable={true} className="login-modal" dialogClassName="custom-modal-dialog"
             show={showLogInModal} onHide={() => setShowLogInModal(false)} size="lg" aria-labelledby="" centered>
        <div className="modal-close" onClick={() => setShowLogInModal(false)}>
          <span className="icon icon-close"/>
        </div>
        <Modal.Body className="p-0 m-0 modal-body">
          {!pinPage ?
            <div className="container-fluid login py-4">
              <div className="header col-12 h5 m-0 p-0 pt-2 text-center text-secondary">{t("Login to your account")}</div>
              <form className="login-form text-secondary mt-3" onSubmit={login}>
                <div className="row justify-content-center">
                  <div className="col-10 p-0 m-0 my-2 my-lg-3">
                    <div className="col-12 px-2 floating">
                      <PhoneInput
                        inputStyle={{ color: "black", width: "100%" }}
                        country={"tz"}
                        onlyCountries={['tz','ke', 'ug']}
                        countryCodeEditable={false}
                        masks={{ tz: "... ... ..." }}
                        onChange={(value, country) => {
                          setFormInput({
                            phone: value,
                            iso_code: country.countryCode.toUpperCase(),
                            dial_code: country.dialCode
                          });
                        }}
                      />
                    </div>
                  </div>

                  {loginError ?
                    <div className="col-10 p-0 m-0 text-danger text-center mt-2 mt-lg-3">
                      {loginError}
                    </div> : null
                  }

                  <div className="col-10 p-0 m-0 my-2 my-lg-3">
                    <div className="col-12 px-2">
                      <Button className="col-12 my-3" variant="primary" disabled={isLoading} type="submit">
                        {isLoading ? <Spinner animation="border" size="sm"/> : t("Login")}
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
              <div className="col-12 pb-3 text-center">
                  {t("Don’t have an account?")} <Link to={"#"} onClick={goToSignUp}>{t("Sign up")}</Link>
              </div>
            </div>
            : <PinPage setPinPage={setPinPage} formInput={formInput}/>
          }
        </Modal.Body>
      </Modal>
    </>
  );
}

export { LogIn };
