import React from 'react';
import { useHistory } from 'react-router';
import { store } from '../store';
import './SideNavBar.scss';
import i18n from "i18next";
import {useTranslation} from "react-i18next";
import { Nav, Navbar, Dropdown } from 'react-bootstrap';
import {InboxNotification, UserNotification, RippleButton} from './BottomNavBar';


function SideNavBar(props){
    const history = useHistory();
    const [,setAnimatePageTransition] = store.useState("animatePageTransition");
    const { t, i18n } = useTranslation();

    function handleLanguageChange(lang) {
        i18n.changeLanguage(lang);
    }

    let homePath = "/";
    let notificationsPath = "/notifications";
    let conversationsPath = "/conversations";
    let savedPropertiesPath = "/saved";

    let goTo = (location) => {
        if(location === history.location.pathname){
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        }
        else {
            setAnimatePageTransition(true);
            history.push(location);
        }
    }

    let goToHome = (e) => {
        goTo(homePath)
    }

    let goToNotifications = (e) => {
        goTo(notificationsPath)
    }
    let goToConversations = (e) => {
        goTo(conversationsPath)
    }
    let goToSavedProperties = (e) => {
        goTo(savedPropertiesPath)
    }

    let active = (iconPath) => {
        let currentPath = history.location.pathname;
        if(currentPath === iconPath){
            return ["active-icon", "active-text"];
        }
        return ["", ""]
    }

    return (
        <div className="side-nav-bar col-lg-2 p-0 m-0 px-4 pt-2 sticky-top d-none d-lg-block">
            <div className="col-12">
                <div className="link" onClick={goToHome}>
                    <span className={`icon icon-search ${active(homePath)[0]}`}/>
                    <span className={`icon-label ${active(homePath)[1]}`}>{i18n.t("Explore")}</span>
                </div>
            </div>

            <div className="col-12">
                <div className="link" onClick={goToSavedProperties}>
                    <span className={`icon icon-heart ${active(savedPropertiesPath)[0]}`}/>
                    <span className={`icon-label ${active(savedPropertiesPath)[1]}`}>{i18n.t("Saved")}</span>
                </div>
            </div>

            <div className="col-12">
                <div className="link" onClick={goToNotifications}>
                    <UserNotification/>
                    <span className={`icon icon-notification ${active(notificationsPath)[0]}`}/>
                    <span className={`icon-label ${active(notificationsPath)[1]}`}>{i18n.t("Notifs")}</span>
                </div>
            </div>
            <div className="col-12">
                <div className="link" onClick={goToConversations}>
                    <InboxNotification/>
                    <span className={`icon icon-chat ${active(conversationsPath)[0]}`}/>
                    <span className={`icon-label ${active(conversationsPath)[1]}`}>{i18n.t("Inbox")}</span>
                </div>
            </div>

            {/*<div className="languages">*/}
                {/*<Dropdown alignRight>*/}
                    {/*<Dropdown.Toggle className="toggle" as={Nav.Link}>*/}
                        {/*<span className="icon icon-language" />*/}
                        {/*{t('Language')}*/}
                    {/*</Dropdown.Toggle>*/}
                    {/*<Dropdown.Menu className="nav-dropdown-menu p-0 m-0 bw-0 bw-lg-1">*/}
                        {/*<hr className="line p-0 m-0 d-lg-none" />*/}
                        {/*<Dropdown.Item className="nav-dropdown-item" onClick={() => handleLanguageChange('en')}>English</Dropdown.Item>*/}
                        {/*<hr className="line p-0 m-0" />*/}
                        {/*<Dropdown.Item className="nav-dropdown-item" onClick={() => handleLanguageChange('sw')}>Swahili</Dropdown.Item>*/}
                    {/*</Dropdown.Menu>*/}
                {/*</Dropdown>*/}
            {/*</div>*/}
        </div>
    );
}


export { SideNavBar }
