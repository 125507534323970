import React from 'react';
import './Chat.scss';
import { store } from '../store';
import { formatTimeDistance, generateLinks } from '../utils';
import { PropertyOverview } from './PropertyOverview';
import { BASE_API_URL } from '..';
import { BounceInlineLoader, DataFetcher, NotFoundError, PROPERTIES_QUERY_PARAM, renderInlineError } from '.';


function PropertyLinkOverview(props) {
    const [user,] = store.useState("user");
    const endPoint = `${BASE_API_URL}/properties/${props.id}/?${PROPERTIES_QUERY_PARAM}`

    const headers = {
        Authorization: `Token ${user.auth_token}`,
        "Content-Type": "application/json",
    };

    let fetchProperty = () => {
        return fetch(endPoint, {headers: headers})
        .then(res => res.json().then(data => ({statusCode: res.status, data})))
    }

    return (
        <DataFetcher action={fetchProperty} selection={`property-overview/${props.id}`}
            placeholder={<BounceInlineLoader />} onError={renderInlineError}>{response => {
                if(response.data.statusCode === 404){
                    return <NotFoundError />
                }
                return <PropertyOverview property={response.data.data} />
        }}</DataFetcher>
    )
}


function Chat(props) {
    const message = props.message;
    const [user,] = store.useState("user");
    const messageType = user.id == message.sender ? "sent" : "received";

    const [messageContent, propertyID] = generateLinks(message.content);

    return (
        <div className={'message ' + messageType + ' row p-0 m-0'}>
            <div className="col-12 p-0 m-0">
                <div className="message-content col-12 p-0 m-0 px-1">
                    { propertyID ?
                        <div className="property-link-overview">
                            <PropertyLinkOverview id={propertyID} />
                        </div>: null
                    }
                    <div dangerouslySetInnerHTML={{__html: messageContent}} />
                </div>

                <div className="info col-12 p-0 m-0 text-right">
                    <span className="timestamp">
                        { message.timestamp === null ?
                            "Sending.." : formatTimeDistance(message.timestamp)
                        }
                    </span>
                    <span className="is-read">
                        {messageType === "sent" ?
                            <>
                                {message.is_read === null ?
                                    <span className="fa fa-clock" /> :
                                    <>
                                        {message.is_read ?
                                            <span className="icon icon-double-check" /> :
                                            <span className="icon icon-check" />
                                        }
                                    </>
                                }
                            </> :
                            null
                        }
                    </span>
                </div>
            </div>
        </div>
    );
}


export { Chat }
