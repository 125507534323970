import React from 'react';
import moment from 'moment';
import './VerificationRequestOverview.scss';
import { Link } from 'react-router-dom';


function VerificationRequestOverview(props) {
    const verificationRequest = props.verificationRequest;

    const path = `/user-verifications/${verificationRequest.id}`

    const profilePic = verificationRequest.owner.picture ? 
                       verificationRequest.owner.picture.src : ""

    return (
        <Link className="verification-request-overview row p-0 m-0" to={path} id={props.id}>
            <div className="verification-request-img col-3 p-0 m-0 text-right">
                <div className="profile-pic">
                    <img src={profilePic} alt="" />
                </div>
            </div>
            <div className="verification-request-info col-9 p-0 m-0">
                <div className="name">
                    <span>{verificationRequest.full_name}</span>
                        {verificationRequest.owner.is_verified ?
                            <span className="icon icon-verified" /> : null
                        }
                </div>
                <div className="doc-type">
                    {verificationRequest.document?
                        verificationRequest.document.type: "No submitted document"
                    }
                </div>
                <div className="date">
                    Submitted in {moment(verificationRequest.created_at).format("D MMM Y")}
                </div>
            </div>
            <div className="chev-icon">
                <span className="icon icon-next" />
            </div>
        </Link>
    );
}


export { VerificationRequestOverview }
