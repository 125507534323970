import React from 'react';
import moment from 'moment'
import { queryCache } from 'react-query';
import { store } from '../store';
import { useLocalState } from '../hooks';
import { BASE_API_URL } from '..';
import {
    DataFetcher, BouncePageLoader, renderPageError, NotFoundError
} from '.';
import './TourRequestDetails.scss';
import { Spinner } from 'react-bootstrap';
import { getPropertyRoute, thousandsSeparator } from '../utils';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';


function TourRequestDetails(props) {
    const tour = props.tour;
    const history = useHistory();
    const [user,] = store.useState("user");
    const [loading, , updateLoading] = useLocalState({
        accept: false, decline: false
    })

    let tourStatus = null;
    if (tour.status === "pending" && !tour.is_confirmed) {
        tourStatus = "toBeConfirmed"
    }
    else if (tour.status === "pending" && tour.is_confirmed) {
        tourStatus = "toBePaid"
    }
    else if (tour.status === "paid" && tour.is_confirmed) {
        tourStatus = "paid"
    }
    else if (tour.status === "canceled" && tour.is_confirmed) {
        tourStatus = "agentCanceled"
    }
    else if (tour.status === "canceled" && !tour.is_confirmed) {
        tourStatus = "clientCanceled"
    }

    const accept = (event) => {
        event.preventDefault();
        updateLoading(loading => {
            loading.accept = true
        });

        const formdata = {
            "tour": tour.id
        }

        const acceptUrl = `${BASE_API_URL}/accept-tour/`
        const headers = {
            'Authorization': `Token ${user.auth_token}`,
            'Content-Type': 'application/json'
        }

        fetch(acceptUrl, { method: 'POST', body: JSON.stringify(formdata), headers: headers })
            .then(res => res.json().then(data => ({ statusCode: res.status, data })))
            .then(res => {
                if (res.statusCode === 200) {
                    queryCache.invalidateQueries(`tour-request/${tour.id}`);
                }
                else if (res.statusCode === 400) {
                    toast.error(res.data.detail)
                }
            })
            .catch(error => {
                toast.error("No network connection, please try again!.")
            })
            .finally(() => {
                updateLoading(loading => {
                    loading.accept = false
                });
            })
    }

    const decline = (event) => {
        event.preventDefault();
        updateLoading(loading => {
            loading.decline = true
        });

        const formdata = {
            "tour": tour.id,
            "reason": "--"
        }

        let acceptUrl = `${BASE_API_URL}/reject-tour/`
        let headers = {
            'Authorization': `Token ${user.auth_token}`,
            'Content-Type': 'application/json'
        }

        fetch(acceptUrl, { method: 'POST', body: JSON.stringify(formdata), headers: headers })
            .then(res => res.json().then(data => ({ statusCode: res.status, data })))
            .then(res => {
                if (res.statusCode === 200) {
                    queryCache.invalidateQueries(`tour-request/${tour.id}`);
                }
                else if (res.statusCode === 400) {
                    toast.error(res.data.detail)
                }
            })
            .catch(error => {
                toast.error("No network connection, please try again!.")
            })
            .finally(() => {
                updateLoading(loading => {
                    loading.decline = false
                });
            })
    }

    const openChat = (e) => {
        history.push(`/conversations/${tour.owner}`)
    }

    return (
        <div className="tour-request-details row p-0 m-0 pt-4">
            <div className="cost-breakdown col-12 col-lg-6 p-0 m-0 px-4 ">
                <div className="head">
                    Property
                </div>
                <div className="row p-0 m-0 related-property align-items-center justify-content-center">
                    <div className="location col-6 p-0 m-0">
                        <div><i className="icon icon-marker"></i></div> &nbsp;
                        <div>{tour.property.location.address}</div>
                    </div>
                    <div className="picture col-6 p-0 m-0 text-right">
                        <Link to={`/${getPropertyRoute(tour.property.type)}/${tour.property.id}/`}>
                            <img src={
                                tour.property.pictures ?
                                    tour.property.pictures[0].src : ""
                            } />
                        </Link>
                    </div>
                </div>
                <div className="row p-0 m-0 dates-info align-items-center">
                    <div className="col-6 p-0 m-0">Tour Date</div>
                    <div className="col-6 p-0 m-0 value text-right">
                        {moment(tour.date).format("DD MMM YYYY")}
                    </div>
                </div>
                <div className="row p-0 m-0 total-cost align-items-center">
                    <div className="col p-0 m-0 text-left">Tour Cost</div>
                    <div className="col p-0 m-0 text-right value">
                        {`${tour.currency} ${thousandsSeparator(tour.cost)}`}
                    </div>
                </div>
            </div>

            <div className="tour-status col-12 col-lg-6 p-0 m-0 px-4 mt-5 mt-lg-0 ">
                <div className="head">
                   Tour Status
                </div>
                <div className="details">
                    {tourStatus === "toBeConfirmed" ?
                        <>
                            This tour is waiting for your confirmation, 
                            please check availability before accepting it.
                        </>:null
                    }
                    {tourStatus === "toBePaid" ?
                        <>
                            You have already confirmed this tour, it's now waiting
                            for payments from the client, we'll let you know when
                            it's done.
                        </> : null
                    }
                    {tourStatus === "paid" ?
                        <>
                           This tour has already been paid, you can now meet with the
                           client to proceed with the process of showing a property
                           to your client.
                        </>:null
                    }
                    {tourStatus === "agentCanceled" ?
                        <>
                            You canceled this tour.
                        </>:null
                    }
                    {tourStatus === "clientCanceled" ?
                        <>
                            Your client canceled this tour.
                        </>:null
                    }
                </div>
            </div>

            {tourStatus === "clientCanceled" ? null :
                <div className="tour-actions row p-0 m-0">
                    {tourStatus === "toBeConfirmed" ?
                        <>
                            <div className="col text-center">
                                <div className="accept btn col-12 col-lg-6" onClick={accept} disabled={loading.accept}>
                                    {loading.accept ? <Spinner animation="border" size="sm" /> : 'Accept'}
                                </div>
                            </div>
                            <div className="col text-center">
                                <div className="decline btn col-12 col-lg-6" onClick={decline} disabled={loading.decline}>
                                    {loading.decline ? <Spinner animation="border" size="sm" /> : 'Decline'}
                                </div>
                            </div>
                        </> : null
                    }
                    {tourStatus === "toBePaid" ?
                        <div className="col-12 text-center">
                            <div className="cancel btn col-12 col-lg-6" onClick={decline} disabled={loading.decline}>
                                {loading.decline ? <Spinner animation="border" size="sm" /> : 'Cancel Tour'}
                            </div>
                        </div> : null
                    }
                    {tourStatus === "paid" ?
                        <>
                            <div className="col-12 text-center">
                                <div className="send-message btn col-12 col-lg-6" onClick={openChat}>
                                    {loading.decline ? <Spinner animation="border" size="sm" /> : 'Send Message To Client'}
                                </div>
                            </div>
                        </> : null
                    }
                    {tourStatus === "agentCanceled" ?
                        <>
                            <div className="col-12 text-center">
                                <div className="uncancel btn col-12 col-lg-6" onClick={accept} disabled={loading.accept}>
                                    {loading.accept ? <Spinner animation="border" size="sm" /> : 'Uncancel & Accept'}
                                </div>
                            </div>
                        </> : null
                    }
                </div>
            }
        </div>
    );
}

function TourRequestDetailsFetcher(props) {
    const [user,] = store.useState("user");

    const headers = {
        Authorization: `Token ${user.auth_token}`,
        "Content-Type": "application/json",
    }

    let fetchTour = () => {
        return fetch(`${BASE_API_URL}/tour-requests/${props.id}/`, { headers: headers })
            .then(res => res.json().then(data => ({ statusCode: res.status, data })))
    }

    return (
        <DataFetcher action={fetchTour} selection={`tour-request/${props.id}`}
            placeholder={<BouncePageLoader />} onError={renderPageError}>{response => {
                if (response.data.statusCode === 404) {
                    return <NotFoundError />
                }
                return <TourRequestDetails tour={response.data.data} />
            }}</DataFetcher>
    )
}


export { TourRequestDetailsFetcher as TourRequestDetails }
