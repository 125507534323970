import React from 'react';
import './ConversationOverview.scss';
import { Link } from 'react-router-dom';
import { store } from '../store';
import { formatTimeDistance } from '../utils';


function ConversationOverview(props) {
    const conversation = props.conversation;
    const [user, ] = store.useState("user");

    let path = `conversations/${conversation.other_user.id}/`;

    return (
        <Link className="conversation-overview row p-0 m-0" to={path}>
            <div className="col-2 col-md-1 p-0 m-0">
                <div className="receiver-profile-picture text-center">
                    {!conversation.other_user.picture ?
                        <span className="icon icon-user" /> :
                        <img src={conversation.other_user.picture.src} alt="" />
                    }
                </div>
            </div>
            <div className="col-8 col-md-9 p-0 m-0">
                <div className="receiver-name col-12 p-0 m-0 px-1">{conversation.other_user.full_name}</div>
                <div className="last-message-overview col-12 p-0 m-0 px-1">
                    {conversation.last_message.content}
                </div>
            </div>
            <div className="info col-2 p-0 m-0">
                <div className="timestamp col-12 p-0 m-0 pb-1 text-right">
                    {formatTimeDistance(conversation.last_message.timestamp)}
                </div>
                <div className="is-read col-12 p-0 m-0 text-right">
                    {user.id == conversation.last_message.sender ?
                        <>
                            {conversation.last_message.is_read ?
                                <span className="icon icon-double-check"/>:
                                <span className="icon icon-check"/>
                            }
                        </> :
                        <>
                            {conversation.unread_messages_count === 0 ?
                                null :
                                <span className="unread_messages_badge">
                                    {conversation.unread_messages_count}
                                </span>
                            }
                        </>
                    }
                </div>
            </div>
        </Link>
    );
}


export { ConversationOverview }
